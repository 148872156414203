import React from 'react';

import r_arbuz_kh from './img/1_r_arbuz_94.06_90.56.png';
import r_banan_kh from './img/2_r_banan_76.88_94.91.png';
import r_granat_kh from './img/3_r_granat_74.64_92.13.png';
import r_mango_kh from './img/4_r_mango_82.66_94.54.png';
import r_projektor_kh from './img/5_r_projektor_76.25_65.37.png';
import r_odejda_kh from './img/6_r_odejda_88.75_46.48.png';
import r_vedro_kh from './img/7_r_vedro_50.21_90.28.png';
import r_ybloko_kh from './img/8_r_ybloko_55.26_94_07.png';
import r_mozaika_kh from './img/9_r_mozaika_92.92_22.69.png';
import l_kisti1_kh from './img/10_l_kisti1_26.61_90.19.png';
import l_kiski2_kh from './img/11_l_kiski2_15.52_95.19.png';
import l_kraska_kh from './img/12_l_kraska_23.8_95.93.png';
import l_kisti3_kh from './img/13_l_kisti3_44.32_96.2.png';
import l_kisti4_kh from './img/14_l_kisti4_8.28_95.65.png';
import l_kraska2_kh from './img/15_l_kraska2_32.9_96.57.png';
import l_bolon_kh from './img/16_l_bolon_21.67_92.59.png';
import l_krasra3_kh from './img/17_l_krasra3_18.13_90.png';
import l_kraska4_kh from './img/18_l_kraska4_36.61_90.83.png';
import l_holst_kh from './img/19_l_holst_4.84_60.93.png';
import l_holst2_kh from './img/20_l_holst_0.42_46.85.png';
import pol_kh from './img/21_pol_0_84.54.png';
import r_stena_kh from './img/22_r_stena_84.22_0.png';
import l_stena_kh from './img/23_l_stena_0_0.png';
import lampa_kh from './img/24_lampa_51.56_2.04.png';
import potolok_kh from './img/25_potolok_2.71_0.png';

export default [
    <div className="animated-item r_arbuz_kh"><img src={r_arbuz_kh} alt="r_arbuz_kh"/></div>,
    <div className="animated-item r_banan_kh"><img src={r_banan_kh} alt="r_banan_kh"/></div>,
    <div className="animated-item r_granat_kh"><img src={r_granat_kh} alt="r_granat_kh"/></div>,
    <div className="animated-item r_mango_kh"><img src={r_mango_kh} alt="r_mango_kh"/></div>,
    <div className="animated-item r_projektor_kh"><img src={r_projektor_kh} alt="r_projektor_kh"/></div>,
    <div className="animated-item r_odejda_kh"><img src={r_odejda_kh} alt="r_odejda_kh"/></div>,
    <div className="animated-item r_vedro_kh"><img src={r_vedro_kh} alt="r_vedro_kh"/></div>,
    <div className="animated-item r_ybloko_kh"><img src={r_ybloko_kh} alt="r_ybloko_kh"/></div>,
    <div className="animated-item r_mozaika_kh"><img src={r_mozaika_kh} alt="r_mozaika_kh"/></div>,
    <div className="animated-item l_kisti1_kh"><img src={l_kisti1_kh} alt="l_kisti1_kh"/></div>,
    <div className="animated-item l_kiski2_kh"><img src={l_kiski2_kh} alt="l_kiski2_kh"/></div>,
    <div className="animated-item l_kraska_kh"><img src={l_kraska_kh} alt="l_kraska_kh"/></div>,
    <div className="animated-item l_kisti3_kh"><img src={l_kisti3_kh} alt="l_kisti3_kh"/></div>,
    <div className="animated-item l_kisti4_kh"><img src={l_kisti4_kh} alt="l_kisti4_kh"/></div>,
    <div className="animated-item l_kraska2_kh"><img src={l_kraska2_kh} alt="l_kraska2_kh"/></div>,
    <div className="animated-item l_bolon_kh"><img src={l_bolon_kh} alt="l_bolon_kh"/></div>,
    <div className="animated-item l_krasra3_kh"><img src={l_krasra3_kh} alt="l_krasra3_kh"/></div>,
    <div className="animated-item l_kraska4_kh"><img src={l_kraska4_kh} alt="l_kraska4_kh"/></div>,
    <div className="animated-item l_holst_kh"><img src={l_holst_kh} alt="l_holst_kh"/></div>,
    <div className="animated-item l_holst2_kh"><img src={l_holst2_kh} alt="l_holst2_kh"/></div>,
    <div className="animated-item pol_kh"><img src={pol_kh} alt="pol_kh"/></div>,
    <div className="animated-item r_stena_kh"><img src={r_stena_kh} alt="r_stena_kh"/></div>,
    <div className="animated-item l_stena_kh"><img src={l_stena_kh} alt="l_stena_kh"/></div>,
    <div className="animated-item lampa_kh"><img src={lampa_kh} alt="lampa_kh"/></div>,
    <div className="animated-item potolok_kh"><img src={potolok_kh} alt="potolok_kh"/></div>,
    <div className="animated-item bg_kh"></div>,   
]