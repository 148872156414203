import React from 'react';

import clickU from './img/1_clickU_46,25_1,3.png';
import menuU from './img/2_menuU_86,35_3,7.png';
import k1U from './img/3_k1U_1,35_45,19.png';
import k2U from './img/4_k2U_82,03_45,28.png';

export default [
    <div className="animated-item clickU"><img src={clickU} alt="clickU"/></div>,
    <div className="animated-item menuU"><img src={menuU} alt="menuU"/></div>,
    <div className="animated-item k1U"><img src={k1U} alt="k1U"/></div>,
    <div className="animated-item k2U"><img src={k2U} alt="k2U"/></div>,
    <div className="animated-item bg"></div>, 
]