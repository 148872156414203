import React from 'react';

import clickE from './img/1_clickE_46,25_1,3.png';
import menuE from './img/2_menuE_86,35_3,7.png';
import k1E from './img/3_k1E_1,35_43,52.png';
import k2E from './img/4_k2E_82,14_43,52.png';

export default [
    <div className="animated-item clickE"><img src={clickE} alt="clickE"/></div>,
    <div className="animated-item menuE"><img src={menuE} alt="menuE"/></div>,
    <div className="animated-item k1E"><img src={k1E} alt="k1E"/></div>,
    <div className="animated-item k2E"><img src={k2E} alt="k2E"/></div>,
    <div className="animated-item bg"></div>, 
]