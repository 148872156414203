import React from 'react';

import r_grelki_rep from './img/1_r_grelki_81.15_94.26.png';
import r_skotch_rep from './img/2_r_skotch_73.59_93.61.png';
import r_shariki_rep from './img/3_r_shariki_84.95_18.98.png';
import l_rupor_rep from './img/4_l_rupor_22.24_93.61.png';
import l_ocki_rep from './img/5_l_ocki_29.79_93.33.png';
import l_respirator_rep from './img/6_l_respirator_39.17_93.61.png';
import l_baraban_rep from './img/7_l_baraban_9.01_92.59.png';
import l_kamera_rep from './img/8_l_kamera_48.13_92.59.png';
import l_konus_rep from './img/9_l_konus_20.26_81.76.png';
import l_plakati_rep from './img/10_l_plakati_0_67.5.png';
import potolok_rep from './img/11_potolok_7.34_0.png';
import l_stena_rep from './img/12_l_stena_0_0.png';
import r_stena_rep from './img/13_stena_86.15_0.png';
import pol_rep from './img/14_pol_0_84.54.png';

export default [
    <div className="animated-item r_grelki_rep"><img src={r_grelki_rep} alt="r_grelki_rep"/></div>,
    <div className="animated-item r_skotch_rep"><img src={r_skotch_rep} alt="r_skotch_rep"/></div>,
    <div className="animated-item r_shariki_rep"><img src={r_shariki_rep} alt="r_shariki_rep"/></div>,
    <div className="animated-item l_rupor_rep"><img src={l_rupor_rep} alt="l_rupor_rep"/></div>,
    <div className="animated-item l_ocki_rep"><img src={l_ocki_rep} alt="l_ocki_rep"/></div>,
    <div className="animated-item l_respirator_rep"><img src={l_respirator_rep} alt="l_respirator_rep"/></div>,
    <div className="animated-item l_baraban_rep"><img src={l_baraban_rep} alt="l_baraban_rep"/></div>,
    <div className="animated-item l_kamera_rep"><img src={l_kamera_rep} alt="l_kamera_rep"/></div>,
    <div className="animated-item l_konus_rep"><img src={l_konus_rep} alt="l_konus_rep"/></div>,
    <div className="animated-item l_plakati_rep"><img src={l_plakati_rep} alt="l_plakati_rep"/></div>,
    <div className="animated-item potolok_rep"><img src={potolok_rep} alt="potolok_rep"/></div>,
    <div className="animated-item l_stena_rep"><img src={l_stena_rep} alt="l_stena_rep"/></div>,
    <div className="animated-item r_stena_rep"><img src={r_stena_rep} alt="r_stena_rep"/></div>,
    <div className="animated-item pol_rep"><img src={pol_rep} alt="pol_rep"/></div>,
    <div className="animated-item bg_rep"></div>,

];