import React from 'react';

import r_cam_kuis from './img/1_r_cam_8.7_55.png';
import r_hadphons_kuis from './img/2_r_hadphons_92.81_2.96.png';
import r_monitor_kuis from './img/3_r_monitor_51.35_92.22.png';
import r_komp_kuis from './img/4_r_komp_one_78.65_63.24.png';
import r_two_monitor_kuis from './img/5_r_two_monitor_89.58_53.98.png';
import r_kolonka_kuis from './img/6_r_kolonka_88.44_38.52.png';
import r_lampa_kuis from './img/7_r_lampa_84.22_1.11.png';
import l_note_kuis from './img/8_l_note_26.56_89.17.png';
import l_provoda_kuis from './img/9_l_provoda_0_0.png';
import l_kolonka_two_kuis from './img/10_l_kolonka_two_0.26_1.94.png';
import l_server_kuis from './img/11_l_server_0_11.67.png';
import l_lampa_kuis from './img/12_l_lampa_17.45_1.57.png';
import lampa_kuis from './img/13_lampa_44.06_1.48.png';
import potolok_kuis from './img/14_potolok_0_0.png';
import pol_kuis from './img/15_pol_0_84.54.png';
import l_stena_kuis from './img/16_l_stena_86.15_0.png';
import r_stena_kuis from './img/17_r_stena_0_0.png';

export default [
    <div className="animated-item r_cam_kuis"><img src={r_cam_kuis} alt="r_cam_kuis"/></div>,
    <div className="animated-item r_hadphons_kuis"><img src={r_hadphons_kuis} alt="r_hadphons_kuis"/></div>,
    <div className="animated-item r_monitor_kuis"><img src={r_monitor_kuis} alt="r_monitor_kuis"/></div>,
    <div className="animated-item r_komp_kuis"><img src={r_komp_kuis} alt="r_komp_kuis"/></div>,
    <div className="animated-item r_two_monitor_kuis"><img src={r_two_monitor_kuis} alt="r_two_monitor_kuis"/></div>,
    <div className="animated-item r_kolonka_kuis"><img src={r_kolonka_kuis} alt="r_kolonka_kuis"/></div>,
    <div className="animated-item r_lampa_kuis"><img src={r_lampa_kuis} alt="r_lampa_kuis"/></div>,
    <div className="animated-item l_note_kuis"><img src={l_note_kuis} alt="l_note_kuis"/></div>,
    <div className="animated-item l_provoda_kuis"><img src={l_provoda_kuis} alt="l_provoda_kuis"/></div>,
    <div className="animated-item l_kolonka_two_kuis"><img src={l_kolonka_two_kuis} alt="l_kolonka_two_kuis"/></div>,
    <div className="animated-item l_server_kuis"><img src={l_server_kuis} alt="l_server_kuis"/></div>,
    <div className="animated-item l_lampa_kuis"><img src={l_lampa_kuis} alt="l_lampa_kuis"/></div>,
    <div className="animated-item lampa_kuis"><img src={lampa_kuis} alt="lampa_kuis"/></div>,
    <div className="animated-item potolok_kuis"><img src={potolok_kuis} alt="potolok_kuis"/></div>,
    <div className="animated-item pol_kuis"><img src={pol_kuis} alt="pol_kuis"/></div>,
    <div className="animated-item l_stena_kuis"><img src={l_stena_kuis} alt="l_stena_kuis"/></div>,
    <div className="animated-item r_stena_kuis"><img src={r_stena_kuis} alt="r_stena_kuis"/></div>,
    <div className="animated-item bg_kuis"></div>, 
]