import React from 'react';

import r_vino_trans from './img/1_r_vino_89.22_89.44.png';
import r_bokal_trans from './img/2_r_bokal_74.79_87.22.png';
import r_stol_trans from './img/3_r_stol.71.3_62.78.png';
import r_kubok_trans from './img/4_r_kubok_69.32_91.76.png';
import r_svecha_trans from './img/5_r_svecha_77.76_83.7.png';
import r_dver_trans from './img/6_r_dver_90.26_27.96.png';
import r_kover_trans from './img/7_r_kover_65.42_89.72.png';
import r_lamp_trans from './img/8_r_lamp_53.7_1.39.png';
import l_pavitra_trans from './img/9_l_pavitra_18.39_96.3.png';
import l_kisti_trans from './img/10_l_kisti_10.63_79.72.png';
import l_stul_trans from './img/11_l_stul_8_07_80.65.png';
import l_holst_trans from './img/12_l_holst_0.99_64.35.png';
import l_stopka_trans from './img/13_l_stopka_6.67_85.28.png';
import l_krujka_trans from './img/14_l_krujka_21.3_92.59.png';
import l_krasra_trans from './img/15_l_krasra_28.02_85.47.png';
import l_lamp_trans from './img/16_l_lamp_25.89_1.2.png';
import pol_trans from './img/17_pol_0_86.2.png';
import l_wall_trans from './img/18_l_wall_0_0.png';
import r_wall_trans from './img/19_r_wall_79.43_0.png';
import potolok_trans from './img/20_potolok_12.45_0.png';

export default [
    <div className="animated-item r_vino_trans"><img src={r_vino_trans} alt="r_vino_trans"/></div>,
    <div className="animated-item r_bokal_trans"><img src={r_bokal_trans} alt="r_bokal_trans"/></div>,
    <div className="animated-item r_stol_trans"><img src={r_stol_trans} alt="r_stol_trans"/></div>,
    <div className="animated-item r_kubok_trans"><img src={r_kubok_trans} alt="r_kubok_trans"/></div>,
    <div className="animated-item r_svecha_trans"><img src={r_svecha_trans} alt="r_svecha_trans"/></div>,
    <div className="animated-item r_dver_trans"><img src={r_dver_trans} alt="r_dver_trans"/></div>,
    <div className="animated-item r_kover_trans"><img src={r_kover_trans} alt="r_kover_trans"/></div>,
    <div className="animated-item r_lamp_trans"><img src={r_lamp_trans} alt="r_lamp_trans"/></div>,
    <div className="animated-item l_pavitra_trans"><img src={l_pavitra_trans} alt="l_pavitra_trans"/></div>,
    <div className="animated-item l_kisti_trans"><img src={l_kisti_trans} alt="l_kisti_trans"/></div>,
    <div className="animated-item l_stul_trans"><img src={l_stul_trans} alt="l_stul_trans"/></div>,
    <div className="animated-item l_holst_trans"><img src={l_holst_trans} alt="l_holst_trans"/></div>,
    <div className="animated-item l_stopka_trans"><img src={l_stopka_trans} alt="l_stopka_trans"/></div>,
    <div className="animated-item l_krujka_trans"><img src={l_krujka_trans} alt="l_krujka_trans"/></div>,
    <div className="animated-item l_krasra_trans"><img src={l_krasra_trans} alt="l_krasra_trans"/></div>,
    <div className="animated-item l_lamp_trans"><img src={l_lamp_trans} alt="l_lamp_trans"/></div>,
    <div className="animated-item pol_trans"><img src={pol_trans} alt="pol_trans"/></div>,
    <div className="animated-item l_wall_trans"><img src={l_wall_trans} alt="l_wall_trans"/></div>,
    <div className="animated-item r_wall_trans"><img src={r_wall_trans} alt="r_wall_trans"/></div>,
    <div className="animated-item potolok_trans"><img src={potolok_trans} alt="potolok_trans"/></div>,
    <div className="animated-item bg_trans"></div>,
    
]