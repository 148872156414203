/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

const Control = ({style, direction, makeVisible, click}) =>
  <div
    className={`control control-${direction}`}
    // tabIndex="0"
    style={style}
    onClick={() => {
		makeVisible(" visible");
		click(direction);
    }}
  ></div>

export default Control;
