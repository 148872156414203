import React from 'react';

import r_baoln_ral from './img/1_r_baoln_83.65_91.57.png';
import r_pshikalka_ral from './img/2_r_pshikalka_61.2_90.56.png';
import r_samovar_ral from './img/3_r_samovar_88.7_84.17.png';
import r_lampa_ral from './img/4_r_lampa_pol_88.7_61.11.png';
import r_holst_ral from './img/5_r_holst_64.32_92.31.png';
import r_sudochek_ral from './img/6_r_sudochek_49.74_91.94.png';
import r_stol_ral from './img/7_r_stol_86.51_67.78.png';
import l_kisti_ral from './img/8_l_kisti_26.93_90.46.png';
import l_valik_ral from './img/9_l_valik_33.18_93.61.png';
import l_kraski_ral from './img/10_l_kraski_28.49_85.83.png';
import l_holsti_ral from './img/11_l_holsti_14.84_93.52.png';
import l_korobka_ral from './img/12_l_korobka_18.85_81.67.png';
import l_holsti1_ral from './img/13_l_holsti1_1.41_47.5.png';
import l_holsti2_ral from './img/14_l_holsti2_6.61_62.69.png';
import kucha_holstov_ral from './img/15_kucha_holstov_10.78_84.44.png';
import lampa_ral from './img/16_lampa_31.93_2.31.png';
import r_stena_ral from './img/17_r_stena82.97_0.png';
import l_stena_ral from './img/18_l_stena_0_0.png';
import pol_ral from './img/19_pol_0_84.54.png';
import potolok_ral from './img/20_potolok_2.71_0.png';

export default [
    <div className="animated-item r_baoln_ral"><img src={r_baoln_ral} alt="r_baoln_ral"/></div>,
    <div className="animated-item r_pshikalka_ral"><img src={r_pshikalka_ral} alt="r_pshikalka_ral"/></div>,
    <div className="animated-item r_samovar_ral"><img src={r_samovar_ral} alt="r_samovar_ral"/></div>,
    <div className="animated-item r_lampa_ral"><img src={r_lampa_ral} alt="r_lampa_ral"/></div>,
    <div className="animated-item r_holst_ral"><img src={r_holst_ral} alt="r_holst_ral"/></div>,
    <div className="animated-item r_sudochek_ral"><img src={r_sudochek_ral} alt="r_sudochek_ral"/></div>,
    <div className="animated-item r_stol_ral"><img src={r_stol_ral} alt="r_stol_ral"/></div>,
    <div className="animated-item l_kisti_ral"><img src={l_kisti_ral} alt="l_kisti_ral"/></div>,
    <div className="animated-item l_valik_ral"><img src={l_valik_ral} alt="l_valik_ral"/></div>,
    <div className="animated-item l_kraski_ral"><img src={l_kraski_ral} alt="l_kraski_ral"/></div>,
    <div className="animated-item l_holsti_ral"><img src={l_holsti_ral} alt="l_holsti_ral"/></div>,
    <div className="animated-item l_korobka_ral"><img src={l_korobka_ral} alt="l_korobka_ral"/></div>,
    <div className="animated-item l_holsti1_ral"><img src={l_holsti1_ral} alt="l_holsti1_ral"/></div>,
    <div className="animated-item l_holsti2_ral"><img src={l_holsti2_ral} alt="l_holsti2_ral"/></div>,
    <div className="animated-item kucha_holstov_ral"><img src={kucha_holstov_ral} alt="kucha_holstov_ral"/></div>,
    <div className="animated-item lampa_ral"><img src={lampa_ral} alt="lampa_ral"/></div>,
    <div className="animated-item r_stena_ral"><img src={r_stena_ral} alt="r_stena_ral"/></div>,
    <div className="animated-item l_stena_ral"><img src={l_stena_ral} alt="l_stena_ral"/></div>,
    <div className="animated-item pol_ral"><img src={pol_ral} alt="pol_ral"/></div>,
    <div className="animated-item potolok_ral"><img src={potolok_ral} alt="potolok_ral"/></div>,
    <div className="animated-item bg_ral"></div>,
];