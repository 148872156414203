import React from 'react';

import kadirova_start from './img/1_r_kadirova_60.89_71.85.png';
import start_start  from './img/2_r_start_54.17_58.15.png';
import rinok_start  from './img/3_r_rinok_42.71_40.83.png';
import stolb_start  from './img/4_l_stolb_22.5_34.26.png';
import bord_start  from './img/5_l_bord_50.94_11.76.png';
import bord2_start  from './img/6_l_bord_4.84_0.56.png';
import fripulia1_start  from './img/7_fripulia1_52.6_3.24.png';
import fripulia2_start  from './img/8_fripulia2_62.19_3.24.png';
import fripulia3_start  from './img/9_fripulia3_73.54_5.46.png';

export default [
    <div className="animated-item kadirova_start"><img src={kadirova_start} alt="kadirova_start"/></div>,
    <div className="animated-item start_start"><img src={start_start} alt="start_start"/></div>,
    <div className="animated-item rinok_start"><img src={rinok_start} alt="rinok_start"/></div>,
    <div className="animated-item stolb_start"><img src={stolb_start} alt="stolb_start"/></div>,
    <div className="animated-item bord_start"><img src={bord_start} alt="bord_start"/></div>,
    <div className="animated-item bord2_start"><img src={bord2_start} alt="bord2_start"/></div>,
    <div className="animated-item fripulia1_start"><img src={fripulia1_start} alt="fripulia1_start"/></div>,
    <div className="animated-item fripulia2_start"><img src={fripulia2_start} alt="fripulia2_start"/></div>,
    <div className="animated-item fripulia3_start"><img src={fripulia3_start} alt="fripulia3_start"/></div>,
    <div className="animated-item bg_start"></div>, 
]