import React from 'react';

import r_pivo2l_kop from './img/1_r_pivo2l_68.91_86.85.png';
import r_shtora_kop from './img/2_r_shtora_98.28_0.png';
import r_stupeni_kop from './img/3_r_stupeni_86.88_87.41.png';
import r_pivo_kop from './img/4_r_pivo_84.22_88.61.png';
import r_stakanchik_kop from './img/5_r_stakanchik_73.7_93.24.png';
import r_stulia_kop from './img/6_r_stulia_70.16_76.11.png';
import l_vodka_kop from './img/7_l_vodka_14.69_89.17.png';
import l_podushka_kop from './img/8_l_podushka_0_94.07.png';
import l_stol_kop from './img/9_l_stol_0_68.52.png';
import l_vino_kop from './img/10_l_vino_35.05_87.87.png';
import l_nojnici_kop from './img/11_l_nojnici_38.7_93.8.png';
import l_klubok_kop from './img/12_l_klubok_31.25_91.48.png';
import l_tkan_kop from './img/13_l_tkan_8.91_86.48.png';
import l_tkan2_kop from './img/14_l_tkan2_8.9171.48.png';
import r_lampa_kop from './img/15_r_lampa_85.68_1.85.png';
import lampa_kop from './img/16_lampa_53.85_1.85.png';
import r_stena_kop from './img/17_r_stena_83.7_0.png';
import l_stena_kop from './img/18_l_stena_0_0.png';
import potolok_kop from './img/19_potolok_2.71_0.png';
import pol_kop from './img/20_pol_0_84.54.png';

export default [
    <div className="animated-item r_pivo2l_kop"><img src={r_pivo2l_kop} alt="r_pivo2l_kop"/></div>,
    <div className="animated-item r_shtora_kop"><img src={r_shtora_kop} alt="r_shtora_kop"/></div>,
    <div className="animated-item r_stupeni_kop"><img src={r_stupeni_kop} alt="r_stupeni_kop"/></div>,
    <div className="animated-item r_pivo_kop"><img src={r_pivo_kop} alt="r_pivo_kop"/></div>,
    <div className="animated-item r_stakanchik_kop"><img src={r_stakanchik_kop} alt="r_stakanchik_kop"/></div>,
    <div className="animated-item r_stulia_kop"><img src={r_stulia_kop} alt="r_stulia_kop"/></div>,
    <div className="animated-item l_vodka_kop"><img src={l_vodka_kop} alt="l_vodka_kop"/></div>,
    <div className="animated-item l_podushka_kop"><img src={l_podushka_kop} alt="l_podushka_kop"/></div>,
    <div className="animated-item l_stol_kop"><img src={l_stol_kop} alt="l_stol_kop"/></div>,
    <div className="animated-item l_vino_kop"><img src={l_vino_kop} alt="l_vino_kop"/></div>,
    <div className="animated-item l_nojnici_kop"><img src={l_nojnici_kop} alt="l_nojnici_kop"/></div>,
    <div className="animated-item l_klubok_kop"><img src={l_klubok_kop} alt="l_klubok_kop"/></div>,
    <div className="animated-item l_tkan_kop"><img src={l_tkan_kop} alt="l_tkan_kop"/></div>,
    <div className="animated-item l_tkan2_kop"><img src={l_tkan2_kop} alt="l_tkan2_kop"/></div>,
    <div className="animated-item r_lampa_kop"><img src={r_lampa_kop} alt="r_lampa_kop"/></div>,
    <div className="animated-item lampa_kop"><img src={lampa_kop} alt="lampa_kop"/></div>,
    <div className="animated-item r_stena_kop"><img src={r_stena_kop} alt="r_stena_kop"/></div>,
    <div className="animated-item l_stena_kop"><img src={l_stena_kop} alt="l_stena_kop"/></div>,
    <div className="animated-item potolok_kop"><img src={potolok_kop} alt="potolok_kop"/></div>,
    <div className="animated-item pol_kop"><img src={pol_kop} alt="pol_kop"/></div>,
    <div className="animated-item bg_kop"></div>,
    
];