import React from 'react';

import r_gorshok_boy from  './img/1_r_gorshok_85.89_76.48.png';
import r_bondura_boy from  './img/2_r_bondura_65.52_89.07.png';
import r_vaza_boy from  './img/3_r_vaza_43.96_90.09.png';
import r_meshok_boy from  './img/4_r_meshok_90.63_78.52.png';
import r_vili_boy from  './img/5_r_vili_90.47_51.76.png';
import l_oskolok_boy from  './img/6_l_oskolok_38.28_96.76.png';
import l_tarelka1_boy from  './img/7_l_tarelka1_32.76_94.81.png';
import l_tarelka2_boy from  './img/8_l_tarelka2_36.82_92.69.png';
import l_gorshok_boy from  './img/9_l_gorshok_9.38_90.09.png';
import l_ybloko_boy from  './img/10_l_ybloko_21.93_96.02.png';
import l_tufli_boy from  './img/11_l_tufli_25.36_92.69.png';
import l_ctul_boy from  './img/12_l_ctul_15.99_84.35.png';
import l_stol_boy from  './img/13_l_stol_2.71_69.07.png';
import lampa_boy from  './img/14_lampa_47.71_7.87.png';
import r_lampa_boy from  './img/15_r_lampa_80.52_7.87.png';
import l_lampa_boy from  './img/16_l_lampa_16.98_7.87.png';
import r_potolok_boy from  './img/17_potolok_3.96_0.png';
import pol_boy from  './img/18_pol_3.28_84.54.png';
import r_stena_boy from  './img/19_r_stena_82.81_0.png';
import l_stena_boy from  './img/20_l_stena_0.68_0.png';

export default [
    <div className="animated-item r_gorshok_boy"><img src={r_gorshok_boy} alt="r_gorshok_boy"/></div>,
    <div className="animated-item r_bondura_boy"><img src={r_bondura_boy} alt="r_bondura_boy"/></div>,
    <div className="animated-item r_vaza_boy"><img src={r_vaza_boy} alt="r_vaza_boy"/></div>,
    <div className="animated-item r_meshok_boy"><img src={r_meshok_boy} alt="r_meshok_boy"/></div>,
    <div className="animated-item r_vili_boy"><img src={r_vili_boy} alt="r_vili_boy"/></div>,
    <div className="animated-item l_oskolok_boy"><img src={l_oskolok_boy} alt="l_oskolok_boy"/></div>,
    <div className="animated-item l_tarelka1_boy"><img src={l_tarelka1_boy} alt="l_tarelka1_boy"/></div>,
    <div className="animated-item l_tarelka2_boy "><img src={l_tarelka2_boy}  alt="l_tarelka2_boy "/></div>,
    <div className="animated-item l_gorshok_boy"><img src={l_gorshok_boy} alt="l_gorshok_boy"/></div>,
    <div className="animated-item l_ybloko_boy"><img src={l_ybloko_boy} alt="l_ybloko_boy"/></div>,
    <div className="animated-item l_tufli_boy"><img src={l_tufli_boy} alt="l_tufli_boy"/></div>,
    <div className="animated-item l_ctul_boy"><img src={l_ctul_boy} alt="l_ctul_boy"/></div>,
    <div className="animated-item l_stol_boy"><img src={l_stol_boy} alt="l_stol_boy"/></div>,
    <div className="animated-item lampa_boy"><img src={lampa_boy} alt="lampa_boy"/></div>,
    <div className="animated-item r_lampa_boy"><img src={r_lampa_boy} alt="r_lampa_boy"/></div>,
    <div className="animated-item l_lampa_boy"><img src={l_lampa_boy} alt="l_lampa_boy"/></div>,
    <div className="animated-item r_potolok_boy"><img src={r_potolok_boy} alt="r_potolok_boy"/></div>,
    <div className="animated-item pol_boy"><img src={pol_boy} alt="pol_boy"/></div>,
    <div className="animated-item r_stena_boy"><img src={r_stena_boy} alt="r_stena_boy"/></div>,
    <div className="animated-item l_stena_boy"><img src={l_stena_boy} alt="l_stena_boy"/></div>,
    <div className="animated-item bg_boy"></div>,
];