import React from "react"

import start from "./img/start.svg"

const Start = ({ startTest, imgs }) => (
  <div className="start" onClick={startTest}>
    {imgs}
  </div>
)

export default Start
