import React from 'react'

import shar_big_fri from './img/1_shar_big_83.13_66.57.png';
import gorshok_fri from './img/2_gorshok_94.43_89.91.png';
import detali_fri from './img/3_detali_72.92_84.44.png';
import detali_two_fri from './img/4_detali_two_0_69.26.png';
import kolaj_fri from './img/5_kolaj_10.47_82.96.png';
import pol_fri from './img/6_pol_0_83.8.png';
import l_stena_fri from './img/7_l_stena_0_0.png';
import r_stena_fri from './img/8_r_stena_84.27_0.png';
import potolok_fri from './img/9_potolok_12.6_0.png';


export default [ 
	<div className="animated-item shar_big_fri"><img src={shar_big_fri} alt="shar_big_fri"/></div>,
	<div className="animated-item gorshok_fri"><img src={gorshok_fri} alt="gorshok_fri"/></div>,
	<div className="animated-item detali_fri"><img src={detali_fri} alt="detali_fri"/></div>,
	<div className="animated-item detali_two_fri"><img src={detali_two_fri} alt="detali_two_fri"/></div>,
	<div className="animated-item kolaj_fri"><img src={kolaj_fri} alt="kolaj_fri"/></div>,
	<div className="animated-item pol_fri"><img src={pol_fri} alt="pol_fri"/></div>,
	<div className="animated-item l_stena_fri"><img src={l_stena_fri} alt="l_stena_fri"/></div>,
	<div className="animated-item r_stena_fri"><img src={r_stena_fri} alt="r_stena_fri"/></div>,
	<div className="animated-item potolok_fri"><img src={potolok_fri} alt="potolok_fri"/></div>,
	<div className="animated-item bg_fri"></div>,
  ]