/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import OutsideClickHandler from "react-outside-click-handler";

const ControlScreen = ({screenVisibility, setScreenVisibility, screenClick, currentScreen, direction, ref, id}) => 
    <div
      className={`screen screen-${direction}` + screenVisibility}
	  onClick={() => {
		  const right = direction === 'right'
		  screenClick(id, right)}
		}
	  ref={ref}
    >
      <OutsideClickHandler
		onOutsideClick={() => {
			if(!screenVisibility){ return }
				setScreenVisibility(" fade")
			}}
		>
		{currentScreen}
      </OutsideClickHandler>
    </div>


export default ControlScreen;
