import React from 'react';

import r_spiral_sav from './img/1_r_spiral_63.02_81.57.png';
import r_spas_sav from './img/2_r_spas_78.85_90.28.png';
import r_grib_sav from './img/3_r_grib_86.15_78.15.png';
import r_krilo_sav from './img/4_r_krilo_78.39_69.72.png';
import r_holst_sav from './img/5_r_holst_92.71_63.8.png';
import r_rim_sav from './img/6_r_rim_92.19_30.46.png';
import r_paporotnik_sav from './img/7_r_paporotnik_83.13_71.57.png';
import r_paporotnik2_sav from './img/8_r_paporotnik2_89.95_55.46.png';
import r_lapa_sav from './img/9_r_lapa_75.63_0.png';
import lampa2_sav from './img/10_r_lampa2_80.94_0.png';
import l_golova_sav from './img/11_l_golova_22.4_82.78.png';
import l_muhomor_sav from './img/12_l_muhomor_5.94_88.43.png';
import l_holst_sav from './img/13_l_holst_0_55.74.png';
import l_kover_sav from './img/14_l_kover_0_82.96.png';
import l_krilo_sav from './img/15_l_krilo_5.47_69.72.png';
import l_paporotnik_sav from './img/16_l_paporotnik_9.9_70.28.png';
import l_lamp_sav from './img/17_l_lamp_32.97_0.png';
import l_lamp2_sav from './img/18_l_lamp_11.88_0.png';
import l_lamp3_sav from './img/19_l_lamp_27.19_0.png';
import l_lamp4_sav from './img/20_l_lamp_19.32_0.png';
import l_lamp5_sav from './img/21_l_lamp_23.23_0.png';
import pol_sav from './img/22_pol_0_84_17.png';
import r_ugol_sav from './img/23_r_ugol_80.94_0.png';
import l_ugol_sav from './img/24_l_ugol_0_0.png';
import potolok_sav from './img/25_potolok_14.01_0.png';


export default [
    <div className="animated-item r_spiral_sav"><img src={r_spiral_sav} alt="r_spiral_sav"/></div>,
    <div className="animated-item r_spas_sav"><img src={r_spas_sav} alt="r_spas_sav"/></div>,
    <div className="animated-item r_grib_sav"><img src={r_grib_sav} alt="r_grib_sav"/></div>,
    <div className="animated-item r_krilo_sav"><img src={r_krilo_sav} alt="r_krilo_sav"/></div>,
    <div className="animated-item r_holst_sav"><img src={r_holst_sav} alt="r_holst_sav"/></div>,
    <div className="animated-item r_rim_sav"><img src={r_rim_sav} alt="r_rim_sav"/></div>,
    <div className="animated-item r_paporotnik_sav"><img src={r_paporotnik_sav} alt="r_paporotnik_sav"/></div>,
    <div className="animated-item r_paporotnik2_sav"><img src={r_paporotnik2_sav} alt="r_paporotnik2_sav"/></div>,
    <div className="animated-item r_lapa_sav"><img src={r_lapa_sav} alt="r_lapa_sav"/></div>,
    <div className="animated-item lampa2_sav"><img src={lampa2_sav} alt="lampa2_sav"/></div>,
    <div className="animated-item l_golova_sav"><img src={l_golova_sav} alt="l_golova_sav"/></div>,
    <div className="animated-item l_muhomor_sav"><img src={l_muhomor_sav} alt="l_muhomor_sav"/></div>,
    <div className="animated-item l_holst_sav"><img src={l_holst_sav} alt="l_holst_sav"/></div>,
    <div className="animated-item l_kover_sav"><img src={l_kover_sav} alt="l_kover_sav"/></div>,
    <div className="animated-item l_krilo_sav"><img src={l_krilo_sav} alt="l_krilo_sav"/></div>,
    <div className="animated-item l_paporotnik_sav"><img src={l_paporotnik_sav} alt="l_paporotnik_sav"/></div>,
    <div className="animated-item l_lamp_sav"><img src={l_lamp_sav} alt="l_lamp_sav"/></div>,
    <div className="animated-item l_lamp2_sav"><img src={l_lamp2_sav} alt="l_lamp2_sav"/></div>,
    <div className="animated-item l_lamp3_sav"><img src={l_lamp3_sav} alt="l_lamp3_sav"/></div>,
    <div className="animated-item l_lamp4_sav"><img src={l_lamp4_sav} alt="l_lamp4_sav"/></div>,
    <div className="animated-item l_lamp5_sav"><img src={l_lamp5_sav} alt="l_lamp5_sav"/></div>,
    <div className="animated-item pol_sav"><img src={pol_sav} alt="pol_sav"/></div>,
    <div className="animated-item r_ugol_sav"><img src={r_ugol_sav} alt="r_ugol_sav"/></div>,
    <div className="animated-item l_ugol_sav"><img src={l_ugol_sav} alt="l_ugol_sav"/></div>,
    <div className="animated-item potolok_sav"><img src={potolok_sav} alt="potolok_sav"/></div>,
    <div className="animated-item bg_sav"></div>,
]