import React from 'react';

import l_kisti_kah from './img/1_l_kisti_20.57_89.17.png';
import r_dokumenti_kah from './img/2_r_dokumenti_82.08_93.61.png';
import l_ventilytor_kah from './img/3_l_ventilytor_11.15_60.46.png';
import r_kartina_kah from './img/4_r_kartina_75.1_77.5.png';
import r_molbert_kah from './img/5_r_molbert.png';
import l_instrumetti_kah from './img/6_l_instrumetti_22.5_87.96.png';
import l_molbert_kah from './img/7_l_molbert_3.13_54.07.png';
import sobaka_kah from './img/8_sobaka_46.41_1.3.png';
import r_okno_kah from './img/9_r_okno_76.35_1.57.png';
import l_okno_kah from './img/10_l_okno_14.79_1.57.png';
import l_foto_kah from './img/11_l_foto_26.72_94.07.png';
import r_chashka_kah from './img/12_r_chashka_70.42_94.07.png';
import r_korobka_kah from './img/13_r_korobka_60.42_92.31.png';
import r_ctoyka_kah from './img/14_r_ctoyka_87.86_53.15.png';
import r_vetka_kah from './img/15_r_vetka_80.52_68.89.png';
import pol_kah from './img/16_pol_0_92.22.png';
import l_stena_kah from './img/17_l_stena_0_9.72.png';
import r_stena_kah from './img/18_r_stena_86.67_9.81.png';
import potolok_kah from './img/19_potolok_2.14_9.72.png';

export default [
    <div className="animated-item l_kisti_kah"><img src={l_kisti_kah} alt="l_kisti_kah"/></div>,
    <div className="animated-item r_dokumenti_kah"><img src={r_dokumenti_kah} alt="r_dokumenti_kah"/></div>,
    <div className="animated-item l_ventilytor_kah"><img src={l_ventilytor_kah} alt="l_ventilytor_kah"/></div>,
    <div className="animated-item r_kartina_kah"><img src={r_kartina_kah} alt="r_kartina_kah"/></div>,
    <div className="animated-item r_molbert_kah"><img src={r_molbert_kah} alt="r_molbert_kah"/></div>,
    <div className="animated-item l_instrumetti_kah"><img src={l_instrumetti_kah} alt="l_instrumetti_kah"/></div>,
    <div className="animated-item l_molbert_kah"><img src={l_molbert_kah} alt="l_molbert_kah"/></div>,
    <div className="animated-item sobaka_kah"><img src={sobaka_kah} alt="sobaka_kah"/></div>,
    <div className="animated-item r_okno_kah"><img src={r_okno_kah} alt="r_okno_kah"/></div>,
    <div className="animated-item l_okno_kah"><img src={l_okno_kah} alt="l_okno_kah"/></div>,
    <div className="animated-item l_foto_kah"><img src={l_foto_kah} alt="l_foto_kah"/></div>,
    <div className="animated-item r_chashka_kah"><img src={r_chashka_kah} alt="r_chashka_kah"/></div>,
    <div className="animated-item r_korobka_kah"><img src={r_korobka_kah} alt="r_korobka_kah"/></div>,
    <div className="animated-item r_ctoyka_kah"><img src={r_ctoyka_kah} alt="r_ctoyka_kah"/></div>,
    <div className="animated-item r_vetka_kah"><img src={r_vetka_kah} alt="r_vetka_kah"/></div>,
    <div className="animated-item pol_kah"><img src={pol_kah} alt="pol_kah"/></div>,
    <div className="animated-item l_stena_kah"><img src={l_stena_kah} alt="l_stena_kah"/></div>,
    <div className="animated-item r_stena_kah"><img src={r_stena_kah} alt="r_stena_kah"/></div>,
    <div className="animated-item potolok_kah"><img src={potolok_kah} alt="potolok_kah"/></div>,
    <div className="animated-item bg_kah"></div>,
];