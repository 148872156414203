import React from 'react';

import r_golova_lis from './img/1_r_golova_79.84_0.png';
import r_palochka_lis from './img/2_r_palochka_77.45_92.69.png';
import r_ochki_lis from './img/3_r_ochki_83.49_94.44.png';
import l_vaza1_lis from './img/4_l_vaza1_27.81_91.76.png';
import l_shlaem_lis from './img/5_l_shlaem_18.33_85.png';
import r_sobaka_lis from './img/6_r_sobaka_77.45_75.56.png';
import r_kniga_lis from './img/7_r_kniga_70.89_93.7.png';
import r_kost1_lis from './img/8_r_kost1_60.68_92.78.png';
import l_vaza2_lis from './img/9_l_vaza2_26.82_83.15.png';
import l_sit_lis from './img/10_l_sit_12.29_82.69.png';
import l_kartini_lis from './img/11_l_kartini_6.51_68.15.png';
import l_kolona_lis from './img/12_l_kolona_0.31_7.87.png';
import r_kost2_lis from './img/13_r_kost2_51.72_92.78.png';
import kliki_lis from './img/14_kliki_65.73_89.35.png';
import l_kust_lis from './img/15_l_kust_0_56.76.png';
import potolok_lis from './img/16_potolok_2.71_0.png';
import r_stena_lis from './img/17_r_stena_82.45_0.png';
import pol_lis from './img/18_pol_0_84.54.png';
import l_stena_lis from './img/19_l_stena_0_0.png';

export default [
    <div className="animated-item r_golova_lis"><img src={r_golova_lis} alt="r_golova_lis"/></div>,
    <div className="animated-item r_palochka_lis"><img src={r_palochka_lis} alt="r_palochka_lis"/></div>,
    <div className="animated-item r_ochki_lis"><img src={r_ochki_lis} alt="r_ochki_lis"/></div>,
    <div className="animated-item l_vaza1_lis"><img src={l_vaza1_lis} alt="l_vaza1_lis"/></div>,
    <div className="animated-item l_shlaem_lis"><img src={l_shlaem_lis} alt="l_shlaem_lis"/></div>,
    <div className="animated-item r_sobaka_lis"><img src={r_sobaka_lis} alt="r_sobaka_lis"/></div>,
    <div className="animated-item r_kniga_lis"><img src={r_kniga_lis} alt="r_kniga_lis"/></div>,
    <div className="animated-item r_kost1_lis"><img src={r_kost1_lis} alt="r_kost1_lis"/></div>,
    <div className="animated-item l_vaza2_lis"><img src={l_vaza2_lis} alt="l_vaza2_lis"/></div>,
    <div className="animated-item l_sit_lis"><img src={l_sit_lis} alt="l_sit_lis"/></div>,
    <div className="animated-item l_kartini_lis"><img src={l_kartini_lis} alt="l_kartini_lis"/></div>,
    <div className="animated-item l_kolona_lis"><img src={l_kolona_lis} alt="l_kolona_lis"/></div>,
    <div className="animated-item r_kost2_lis"><img src={r_kost2_lis} alt="r_kost2_lis"/></div>,
    <div className="animated-item kliki_lis"><img src={kliki_lis} alt="kliki_lis"/></div>,
    <div className="animated-item l_kust_lis"><img src={l_kust_lis} alt="l_kust_lis"/></div>,
    <div className="animated-item potolok_lis"><img src={potolok_lis} alt="potolok_lis"/></div>,
    <div className="animated-item r_stena_lis"><img src={r_stena_lis} alt="r_stena_lis"/></div>,
    <div className="animated-item pol_lis"><img src={pol_lis} alt="pol_lis"/></div>,
    <div className="animated-item l_stena_lis"><img src={l_stena_lis} alt="l_stena_lis"/></div>,
    <div className="animated-item bg_lis"></div>, 
]