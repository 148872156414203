import React from 'react';

import r_cashka_proc from './img/1_r_cashka_65.68_94.72.png';
import r_ceburashka_proc from './img/2_r_ceburashka_83.49_79.44.png';
import r_korobka_proc from './img/3_r_korobka_88.28_88.8.png';
import r_holst_proc from './img/4_r_holst_90.21_68.06.png';
import r_palitpa_proc from './img/5_r_palitpa_86.25_78.89.png';
import r_dokument_proc from './img/6_r_dokument_71.81_93.06.png';
import disco_proc from './img/7_disco_68.7_88.61.png';
import r_shlem_proc from './img/8_r_shlem_92.81_47.22.png';
import r_lobzik_proc from './img/9_r_lobzik_52.19_92.5.png';
import r_lampa_proc from './img/10_r_lampa_43.44_4.54.png';
import l_stul_proc from './img/11_l_stul_4.32_80.65.png';
import l_ochki_proc from './img/12_l_ochki_32.5_94.26.png';
import l_bust_proc from './img/13_l_bust_10.83_80.56.png';
import l_holst_proc from './img/14_l_holst_two_14.38_92.78.png';
import l_shirma_proc from './img/15_l_shirma_0.52_44.81.png';
import l_kuch_holst_proc from './img/16_l_kuch_holst_9.9_85.46.png';
import l_lampa_proc from './img/17_l_lampa_34.22_4.35.png';
import pol_proc from './img/18_pol_0_84.54.png';
import r_stena_proc from './img/19_r_stena_84.79_0.png';
import l_stena_proc from './img/20_l_stena_0_0.png';
import potolok_proc from './img/21_potolok_2.71_0.png';

export default [
    <div className="animated-item r_cashka_proc"><img src={r_cashka_proc} alt="r_cashka_proc"/></div>,
    <div className="animated-item r_ceburashka_proc"><img src={r_ceburashka_proc} alt="r_ceburashka_proc"/></div>,
    <div className="animated-item r_korobka_proc"><img src={r_korobka_proc} alt="r_korobka_proc"/></div>,
    <div className="animated-item r_holst_proc"><img src={r_holst_proc} alt="r_holst_proc"/></div>,
    <div className="animated-item r_palitpa_proc"><img src={r_palitpa_proc} alt="r_palitpa_proc"/></div>,
    <div className="animated-item r_dokument_proc"><img src={r_dokument_proc} alt="r_dokument_proc"/></div>,
    <div className="animated-item disco_proc"><img src={disco_proc} alt="disco_proc"/></div>,
    <div className="animated-item r_shlem_proc"><img src={r_shlem_proc} alt="r_shlem_proc"/></div>,
    <div className="animated-item r_lobzik_proc"><img src={r_lobzik_proc} alt="r_lobzik_proc"/></div>,
    <div className="animated-item r_lampa_proc"><img src={r_lampa_proc} alt="r_lampa_proc"/></div>,
    <div className="animated-item l_stul_proc"><img src={l_stul_proc} alt="l_stul_proc"/></div>,
    <div className="animated-item l_ochki_proc"><img src={l_ochki_proc} alt="l_ochki_proc"/></div>,
    <div className="animated-item l_bust_proc"><img src={l_bust_proc} alt="l_bust_proc"/></div>,
    <div className="animated-item l_holst_proc"><img src={l_holst_proc} alt="l_holst_proc"/></div>,
    <div className="animated-item l_shirma_proc"><img src={l_shirma_proc} alt="l_shirma_proc"/></div>,
    <div className="animated-item l_kuch_holst_proc"><img src={l_kuch_holst_proc} alt="l_kuch_holst_proc"/></div>,
    <div className="animated-item l_lampa_proc"><img src={l_lampa_proc} alt="l_lampa_proc"/></div>,
    <div className="animated-item pol_proc"><img src={pol_proc} alt="pol_proc"/></div>,
    <div className="animated-item r_stena_proc"><img src={r_stena_proc} alt="r_stena_proc"/></div>,
    <div className="animated-item l_stena_proc"><img src={l_stena_proc} alt="l_stena_proc"/></div>,
    <div className="animated-item potolok_proc"><img src={potolok_proc} alt="potolok_proc"/></div>,
    <div className="animated-item bg_proc"></div>,  
]