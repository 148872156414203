import React from 'react';

import r_sculptura_exar from './img/1_r_sculptura_89.69_40.19.png';
import r_vedro_exar from './img/2_r_vedro_79.01_89.17.png';
import r_sculptura2_exar from './img/3_r_sculptura2_84.69_43.43.png';
import l_nojnici_exar from './img/4_l_nojnici_38.49_95.19.png';
import r_sculpturi_exar from './img/4_r_sculpturi_82.19_61.94.png';
import l_klubok_exar from './img/5_l_klubok_36.93_92.96.png';
import l_plate_exar from './img/6_l_plate_0.1_49.81.png';
import l_katushka_exar from './img/7_l_katushka_10.94_73.06.png';
import l_podushka_exar from './img/8_l_podushka_44.69_91.57.png';
import l_katushka2_exar from './img/9_l_katushka2_15.52_88.8.png';
import r_lamp_exar from './img/10_r_lamp_73.49_0.png';
import l_lustra1_exar from './img/11_l_lustra1_13.39_1.57.png';
import l_lustra_exar from './img/12_l_lustra_2_34_2.41.png';
import potolok_exar from './img/13_potolok_2.71_0.png';
import pol_exar from './img/14_pol_0_84.54.png';
import r_stena_exar from './img/15_r_stena_83.75_0.png';
import l_stena_exar from './img/16_l_stena_0_0.png';

export default [
    <div className="animated-item r_sculptura_exar"><img src={r_sculptura_exar} alt="r_sculptura_exar"/></div>,
    <div className="animated-item r_vedro_exar"><img src={r_vedro_exar} alt="r_vedro_exar"/></div>,
    <div className="animated-item r_sculptura2_exar"><img src={r_sculptura2_exar} alt="r_sculptura2_exar"/></div>,
    <div className="animated-item l_nojnici_exar"><img src={l_nojnici_exar} alt="l_nojnici_exar"/></div>,
    <div className="animated-item r_sculpturi_exar"><img src={r_sculpturi_exar} alt="r_sculpturi_exar"/></div>,
    <div className="animated-item l_klubok_exar"><img src={l_klubok_exar} alt="l_klubok_exar"/></div>,
    <div className="animated-item l_plate_exar"><img src={l_plate_exar} alt="l_plate_exar"/></div>,
    <div className="animated-item l_katushka_exar"><img src={l_katushka_exar} alt="l_katushka_exar"/></div>,
    <div className="animated-item l_podushka_exar"><img src={l_podushka_exar} alt="l_podushka_exar"/></div>,
    <div className="animated-item l_katushka2_exar"><img src={l_katushka2_exar} alt="l_katushka2_exar"/></div>,
    <div className="animated-item r_lamp_exar"><img src={r_lamp_exar} alt="r_lamp_exar"/></div>,
    <div className="animated-item l_lustra1_exar"><img src={l_lustra1_exar} alt="l_lustra1_exar"/></div>,
    <div className="animated-item l_lustra_exar"><img src={l_lustra_exar} alt="l_lustra_exar"/></div>,
    <div className="animated-item potolok_exar"><img src={potolok_exar} alt="potolok_exar"/></div>,
    <div className="animated-item pol_exar"><img src={pol_exar} alt="pol_exar"/></div>,
    <div className="animated-item r_stena_exar"><img src={r_stena_exar} alt="r_stena_exar"/></div>,
    <div className="animated-item l_stena_exar"><img src={l_stena_exar} alt="l_stena_exar"/></div>,
    <div className="animated-item bg_exar"></div>,
];