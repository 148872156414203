/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"

import { mobileAndTabletCheck } from "./deviceChecker"

const Card = ({ front, back, text, id }) => {
  const [clicked, setClicked] = useState(false)
  const [frontSize, setFrontSize] = useState({ opacity: 0 })
  const [backSize, setBackSize] = useState({ opacity: 0 })
  const [size, setSize] = useState({ opacity: 0 })

  const resize = (nh, nw) => {
    const vertical = nh > nw

    const proportion = vertical ? nw / nh : nh / nw

    const p = id === "boychuk" ? 0.6 : 0.7

    console.log(p)

    return vertical
      ? {
          height: document.body.clientHeight * p + 40,
          width: document.body.clientHeight * p * proportion + 40,
        }
      : {
          height: document.body.clientHeight * proportion + 40,
          width: document.body.clientHeight + 40,
        }
  }

  useEffect(() => {
    document
      .querySelector(`.flip-card-front img`)
      .addEventListener("load", () => {
        if (frontSize.height) {
          return
        }

        const querySelectorResult = document.querySelector(
          ".flip-card-front img"
        )
        if (querySelectorResult) {
          const { naturalHeight, naturalWidth } = querySelectorResult
          const { clientWidth, clientHeight } = document.body

          const sizes =
            clientWidth - 200 <= naturalWidth ||
            clientHeight - 100 <= naturalHeight
              ? {
                  height: naturalHeight * 0.8 + 80,
                  width: naturalWidth * 0.8 + 80,
                }
              : { height: naturalHeight + 80, width: naturalWidth + 80 }

          const s = mobileAndTabletCheck()
            ? resize(naturalHeight, naturalWidth)
            : sizes

          setFrontSize(s)
          setSize(s)
        }
      })
  }, [frontSize.height, resize])

  useEffect(() => {
    //if (!document.querySelector(".flip-card-back img")) {
    //  return
    //}

    document
      .querySelector(`.flip-card-back img`)
      .addEventListener("load", () => {
        if (!document.querySelector(".flip-card-back img")) {
          return
        }
        const { naturalHeight, naturalWidth } = document.querySelector(
          ".flip-card-back img"
        )

        const s = (h, w) =>
          mobileAndTabletCheck()
            ? resize(h, w)
            : { height: h + 80, width: w + 80 }

        if (id === "boychuck") {
          setBackSize(
            mobileAndTabletCheck()
              ? resize(naturalHeight * 0.7 + 120, naturalWidth * 0.7 + 80)
              : {
                  height: naturalHeight * 0.7 + 120,
                  width: naturalWidth * 0.7 + 80,
                }
          )

          return
        }

        setBackSize(
          mobileAndTabletCheck()
            ? resize(naturalHeight * 0.7 + 80, naturalWidth * 0.7 + 80)
            : {
                height: naturalHeight * 0.7 + 80,
                width: naturalWidth * 0.7 + 80,
              }
        )
      })
  }, [resize])

  const onCardClick = () => {
    if (id === "3364") {
      return
    }
    setClicked(!clicked)

    const nextSize = !clicked ? backSize : frontSize

    setSize(nextSize)
  }

  const backText =
    id !== "3364-e" &&
    id !== "3364" &&
    id !== "context" &&
    id !== "rules" &&
    id !== "ninties" &&
    id !== "moscow-ninties" &&
    !id.includes("0520")

  return (
    <>
      <div
        className={clicked ? "flip-card clicked" : "flip-card"}
        onClick={onCardClick}
        style={size}
      >
        <div className="flip-card-inner">
          <div className={clicked ? "flip-card-front hide" : "flip-card-front"}>
            {front}
            {!backText && text && (
              <div style={{ cursor: "pointer" }} className="black-screen">
                {text}
              </div>
            )}
          </div>
          <div className={!clicked ? "flip-card-back hide" : "flip-card-back"}>
            {back}
            {backText && text && (
              <div style={{ cursor: "pointer" }} className="black-screen">
                {text}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Card
