import React from 'react';

import r_copog_ss from './img/1_r_copog_77.34_90.png';
import l_dokument_ss from './img/2_l_dokument_27.03_92.13.png';
import r_podvodi_ss from './img/3_r_podvodi.png';
import l_traktor_ss from './img/4_l_traktor_1.09_67.78.png';
import r_ruini1_ss from './img/5_r_ruini1_77.4_67.96.png';
import r_ruini2_ss from './img/6_r_ruini2_89.38_32.13.png';
import zemly_ss from './img/7_zemly_0_88.52.png';
import r_derevo1_ss from './img/8_r_derevo1_93.02_61.3.png';
import r_derevo2_ss from './img/9_r_derevo2_80.99_64.54.png';
import r_derevo3_ss from './img/10_r_derevo3_83.65_53.15.png';
import l_fonar_ss from './img/11_l_fonar_15.16_60.93.png';
import r_derejabl_ss from './img/12_r_derejabl_81.04_11.02.png';
import l_derjprom_ss from './img/13_l_derjprom_0_9.63.png';

export default [
    <div className="animated-item r_copog_ss"><img src={r_copog_ss} alt="r_copog_ss"/></div>,
    <div className="animated-item l_dokument_ss"><img src={l_dokument_ss} alt="l_dokument_ss"/></div>,
    <div className="animated-item r_podvodi_ss"><img src={r_podvodi_ss} alt="r_podvodi_ss"/></div>,
    <div className="animated-item l_traktor_ss"><img src={l_traktor_ss} alt="l_traktor_ss"/></div>,
    <div className="animated-item r_ruini1_ss"><img src={r_ruini1_ss} alt="r_ruini1_ss"/></div>,
    <div className="animated-item r_ruini2_ss"><img src={r_ruini2_ss} alt="r_ruini2_ss"/></div>,
    <div className="animated-item zemly_ss"><img src={zemly_ss} alt="zemly_ss"/></div>,
    <div className="animated-item r_derevo1_ss"><img src={r_derevo1_ss} alt="r_derevo1_ss"/></div>,
    <div className="animated-item r_derevo2_ss"><img src={r_derevo2_ss} alt="r_derevo2_ss"/></div>,
    <div className="animated-item r_derevo3_ss"><img src={r_derevo3_ss} alt="r_derevo3_ss"/></div>,
    <div className="animated-item l_fonar_ss"><img src={l_fonar_ss} alt="l_fonar_ss"/></div>,
    <div className="animated-item r_derejabl_ss"><img src={r_derejabl_ss} alt="r_derejabl_ss"/></div>,
    <div className="animated-item l_derjprom_ss"><img src={l_derjprom_ss} alt="l_derjprom_ss"/></div>,
    <div className="animated-item bg_ss"></div>,  
];