import React from 'react';

import l_shina_hp from './img/1_l_shina_1.09_93.98.png';
import r_lanos_hp from './img/2_r_lanos_79.06_81.2.png';
import r_stol_hp from './img/3_r_stol_47.29_76.39.png';
import r_fripulia_hp from './img/4_r_fripulia_82.5_32.5.png';
import l_bochka_hp from './img/5_l_bochka_13.7_85.83.png';
import paporotnik_hp from './img/6_paporotnik_92.5_47.96.png';
import r_kadirova_hp from './img/7_r_kadirova91.15_77.22.png';
import l_ruini_hp from './img/8_l_ruini_18.65_80.09.png';
import l_konus_hp from './img/9_l_konus_9.58_31.76.png';
import l_konus2_hp from './img/10_l_konus2_15.83_37.22.png';
import l_dom_hp from './img/11_l_dom_0_41.39.png';
import r_stalinka_hp from './img/12_r_stalinka_73.7_0.83.png';
import l_topol_hp from './img/13_l_topol_18.65_49.72.png';
import l_topol2_hp from './img/14_l_topol2_0.31_24.07.png';
import l_maf_hp from './img/15_l_maf_0_10.65.png';
import l_par_hp from './img/16_l_par_0_7.5.png';
import r_doma_hp from './img/17_r_doma_72.24_69.72.png';
import r_lenti_hp from './img/18_r_lenti_80.16_0.png';
import r_shari_hp from './img/19_r_shari_79.17_14.63.png';
import zemli_hp from './img/20_zemli_0_85.56.png';

export default [
    <div className="animated-item l_shina_hp"><img src={l_shina_hp} alt="l_shina_hp"/></div>,
    <div className="animated-item r_lanos_hp"><img src={r_lanos_hp} alt="r_lanos_hp"/></div>,
    <div className="animated-item r_stol_hp"><img src={r_stol_hp} alt="r_stol_hp"/></div>,
    <div className="animated-item r_fripulia_hp"><img src={r_fripulia_hp} alt="r_fripulia_hp"/></div>,
    <div className="animated-item l_bochka_hp"><img src={l_bochka_hp} alt="l_bochka_hp"/></div>,
    <div className="animated-item paporotnik_hp"><img src={paporotnik_hp} alt="paporotnik_hp"/></div>,
    <div className="animated-item r_kadirova_hp"><img src={r_kadirova_hp} alt="r_kadirova_hp"/></div>,
    <div className="animated-item l_ruini_hp"><img src={l_ruini_hp} alt="l_ruini_hp"/></div>,
    <div className="animated-item l_konus_hp"><img src={l_konus_hp} alt="l_konus_hp"/></div>,
    <div className="animated-item l_konus2_hp"><img src={l_konus2_hp} alt="l_konus2_hp"/></div>,
    <div className="animated-item l_dom_hp"><img src={l_dom_hp} alt="l_dom_hp"/></div>,
    <div className="animated-item r_stalinka_hp"><img src={r_stalinka_hp} alt="r_stalinka_hp"/></div>,
    <div className="animated-item l_topol_hp"><img src={l_topol_hp} alt="l_topol_hp"/></div>,
    <div className="animated-item l_topol2_hp"><img src={l_topol2_hp} alt="l_topol2_hp"/></div>,
    <div className="animated-item l_maf_hp"><img src={l_maf_hp} alt="l_maf_hp"/></div>,
    <div className="animated-item l_par_hp"><img src={l_par_hp} alt="l_par_hp"/></div>,
    <div className="animated-item r_doma_hp"><img src={r_doma_hp} alt="r_doma_hp"/></div>,
    <div className="animated-item r_lenti_hp"><img src={r_lenti_hp} alt="r_lenti_hp"/></div>,
    <div className="animated-item r_shari_hp"><img src={r_shari_hp} alt="r_shari_hp"/></div>,
    <div className="animated-item zemli_hp"><img src={zemli_hp} alt="zemli_hp"/></div>,
    <div className="animated-item bg_hp"></div>,
];