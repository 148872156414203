import React from 'react';

import l_otboynik_se from './img/1_l_otboynik_19.27_93.8.png';
import r_kirpichi_se from './img/2_r_kirpichi_55.68_89.63.png';
import r_traktor_se from './img/3_r_traktor_75.68_76.3.png';
import l_traleybus_se from './img/4_l_traleybus_0.31_56.39.png';
import r_pecok_se from './img/5_r_pecok_63.85_86.39.png';
import l_provoda_se from './img/6_l_provoda_0_5.28.png';
import l_derevy1_se from './img/7_l_derevy1_9.74_71.02.png';
import l_derevy2_se from './img/8_l_derevy2_0_69.44.png';
import zemly_se from './img/9_zemly_0_90.56.png';
import hrusevka_se from './img/10_r_hrusevka_83.75_55.65.png';
import r_kran_se from './img/11_r_kran_81.35_26.2.png';
import r_hrusevka_se from './img/12_r_hrusevka_71.56_69.44.png';
import l_dom_se from './img/13_l_dom_14.79_76.2.png';
import l_pron_se from './img/14_l_pron_dom_0_39.63.png';
import l_kotli_se from './img/15_l_kotli_23.7_64.72.png';
import l_truba1_se from './img/16_l_truba1_0_19.91.png';
import l_truba2_se from './img/17_l_truba2_0_34.26.png';
import truba3_se from './img/18_l_truba3_18.8_62.96.png';
import l_truba4_se from './img/19_l_truba4_8.33_58.52.png';

export default [
    <div className="animated-item l_otboynik_se"><img src={l_otboynik_se} alt="l_otboynik_se"/></div>,
    <div className="animated-item r_kirpichi_se"><img src={r_kirpichi_se} alt="r_kirpichi_se"/></div>,
    <div className="animated-item r_traktor_se"><img src={r_traktor_se} alt="r_traktor_se"/></div>,
    <div className="animated-item l_traleybus_se"><img src={l_traleybus_se} alt="l_traleybus_se"/></div>,
    <div className="animated-item r_pecok_se"><img src={r_pecok_se} alt="r_pecok_se"/></div>,
    <div className="animated-item l_provoda_se"><img src={l_provoda_se} alt="l_provoda_se"/></div>,
    <div className="animated-item l_derevy1_se"><img src={l_derevy1_se} alt="l_derevy1_se"/></div>,
    <div className="animated-item l_derevy2_se"><img src={l_derevy2_se} alt="l_derevy2_se"/></div>,
    <div className="animated-item zemly_se"><img src={zemly_se} alt="zemly_se"/></div>,
    <div className="animated-item hrusevka_se"><img src={hrusevka_se} alt="hrusevka_se"/></div>,
    <div className="animated-item r_kran_se"><img src={r_kran_se} alt="_r_kran_se"/></div>,
    <div className="animated-item r_hrusevka_se"><img src={r_hrusevka_se} alt="r_hrusevka_se"/></div>,
    <div className="animated-item l_dom_se"><img src={l_dom_se} alt="l_dom_se"/></div>,
    <div className="animated-item l_pron_se"><img src={l_pron_se} alt="l_pron_se"/></div>,
    <div className="animated-item l_kotli_se"><img src={l_kotli_se} alt="l_kotli_se"/></div>,
    <div className="animated-item l_truba1_se"><img src={l_truba1_se} alt="l_truba1_se"/></div>,
    <div className="animated-item l_truba2_se"><img src={l_truba2_se} alt="l_truba2_se"/></div>,
    <div className="animated-item truba3_se"><img src={truba3_se} alt="truba3_se"/></div>,
    <div className="animated-item l_truba4_se"><img src={l_truba4_se} alt="l_truba4_se"/></div>,
    <div className="animated-item bg_se"></div>,

];