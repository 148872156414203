/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";

import test from "./test";
import text from "./text";
import aboutText from "./about-text";
import "./App.css";
import Slide from "./Slide";
import Control from "./Control";
import ControlScreen from "./ControlScreen";
import About from "./About";
import { mobileAndTabletCheck } from "./deviceChecker";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import menuUa1 from "./img/menu-ua-1.svg";
import menuUa2 from "./img/menu-ua-2.svg";
import menuEn1 from "./img/menu-en-1.svg";
import menuEn2 from "./img/menu-en-2.svg";

import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";

const App = () => {
  // const sliderRef = useRef(null);
  // const [slidesList, setSlidesList] = useState([test[0].id, test[0].options[0]])
  const [visibleScreen, setVisibleScreen] = useState(["initial", "initial"]);
  const [lang, setLang] = useState("UA");
  const [currentSlideId, setCurrentSlideId] = useState("start");
  const [isProperOrientation, setIsProperOrientation] = useState(
    !mobileAndTabletCheck() || window.orientation === 90
  );
  const [leftControlStyle, setLeftControlStyle] = useState({
    left: '-100px',
    borderRadius: '50%'
  });
  const [rightControlStyle, setRightControlStyle] = useState({
    right: '-100px',
    borderRadius: '50%'
  });
  const [burgerMenuStyle, setBurgerMenuStyle] = useState({});

  const [slideDirection, setSlideDirection] = useState(["right", "right"]);

  const [rightScreenVisibility, setRightScreenVisibility] = useState("");
  const [leftScreenVisibility, setLeftScreenVisibility] = useState("");

  const items = lang === "UA" ? test.ua : test.en;
  const texts = lang === "UA" ? text.ua : text.en;
  const abouts = lang === "UA" ? aboutText.ua : aboutText.en;
  const aboutpic = lang === "UA" ? [menuUa1, menuUa2] : [menuEn1, menuEn2];

  let location = useLocation();
  let history = useHistory();

  const leftScreenRef = useRef(null);

  useEffect(() => {
    const id = location.pathname.replace("/", "");
    setCurrentSlideId(id);

    window.addEventListener(
      "orientationchange",
      () => {
        setIsProperOrientation(
          !mobileAndTabletCheck() || window.orientation === 90
        );
      },
      false
    );
  }, []);

  const currentItems = items.find(({ id }) => id === currentSlideId);

  const displayControls = currentItems ? currentItems.displayControls : false;

  const currentScreens = currentItems ? currentItems.screens : ["", ""];

  const styleControls = (event) => {
    if (currentScreens && !currentScreens[0] || mobileAndTabletCheck()) {
      return;
    }

    const cursorPosition = event.clientX;
    const screenWidth = document.body.clientWidth;

    const cursorPositionInPercents = parseInt(
      cursorPosition / (screenWidth / 100)
    );

    const toggler = document.getElementById("pic-toggle");

    if (toggler) {
      toggler.style.transform = `scale(${cursorPositionInPercents / 150 + 1})`;
    }

    if (cursorPositionInPercents <= 50) {
      setRightControlStyle({
        right: '-100px',
        borderRadius: '50%'
      });
      if (cursorPositionInPercents <= 10) {
        setLeftControlStyle({
          left: -100,
          borderRadius: "100%",
        });
        return;
      }

      setLeftControlStyle({
        left: -120 + (30 - cursorPositionInPercents),
        borderRadius: `${(50 - cursorPositionInPercents) * 2}%`,
      });
    } else {
      setLeftControlStyle({
        left: '-100px',
        borderRadius: '50%'
      });
      if (cursorPositionInPercents >= 90) {
        setRightControlStyle({
          left: "calc(99% - 50px)",
          borderRadius: "100%",
        });

        return;
      }

      setRightControlStyle({
        left: `calc(100% - ${50 - (90 - cursorPositionInPercents)}px)`,
        borderRadius: `${(50 - (100 - cursorPositionInPercents)) * 2}%`,
      });
      setBurgerMenuStyle({
        left: `calc(100% - ${50 - (90 - cursorPositionInPercents * 1.5)}px)`,
      });
    }
  };

  const controlClick = (position) => {
    setVisibleScreen([position, visibleScreen[0]]);
  }

  const screenClick = (id, right) => {
    //if mobile setRightScreenVisibility(" fade")

    if(currentSlideId === 'final' && !right){
      setLeftScreenVisibility("");
      document.getElementById("menu").classList.add("visible")
      return;
    }

    const nextId = items.map((item) => item.id).indexOf(currentSlideId);

    if (nextId === items.length) {
      history.push("/start");
      return;
    }

    const from = currentScreens.indexOf(id) === 1 ? 'right' : 'left'

    const d = right ? "right" : "left";

    setSlideDirection([d, from]);


    setTimeout(() => {
      setLeftScreenVisibility("");
      setRightScreenVisibility("");
      setCurrentSlideId(id);
    //if (
    //  rightScreenVisibility !== " visible" &&
    //  leftScreenVisibility !== " visible"
    //) {
    //  console.log('ritun')
    //  return;
    //}
    history.push(`/${id}`);
    }, 100)
  };

  useEffect(() => {
    if (rightScreenVisibility.includes("visible")) {
      // eslint-disable-next-line
      document
        .querySelector("#slider-wrapper > div")
        ?.classList.add("resize-left");
      return;
    }
    // eslint-disable-next-line
    document
      .querySelector("#slider-wrapper > div")
      ?.classList.remove("resize-left");
  }, [rightScreenVisibility]);

  useEffect(() => {
    if (leftScreenVisibility.includes("visible")) {
      // eslint-disable-next-line
      document
        .querySelector("#slider-wrapper > div")
        ?.classList.add("resize-right");
      return;
    }
    // eslint-disable-next-line
    document
      .querySelector("#slider-wrapper > div")
      ?.classList.remove("resize-right");
  }, [leftScreenVisibility]);

  const outClick = () => {
    document.getElementById("menu").classList.remove("visible");
  };

  const display = !(
    location.pathname.includes("start") ||
    location.pathname.includes("lang") 
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return true ? (
    items && (
      <div className="app">
        {display && (
          <About
            style={burgerMenuStyle}
            data={abouts}
            outClick={outClick}
            start={aboutpic[0]}
            finish={aboutpic[1]}
            lang={lang}
          />
        )}
        <div id="slider-wrapper" onMouseMove={styleControls}>
          <TransitionGroup
            childFactory={(child) =>
              React.cloneElement(child, {
                slideDirection: slideDirection.join("-"),
              })
            }
          >
            <CSSTransition
              key={location.pathname}
              classNames={slideDirection.join("-")}
              timeout={1000}
            >
              <Switch location={location}>
                {items.map((item) => (
                  <Route path={`/${item.id}`} key={item.id}>
                    <Slide
                      key={item.id}
                      id={item.id}
                      items={items}
                      texts={texts}
                      nextSlide={() => {
                        history.push(`/${item.options[0]}`);
                        setCurrentSlideId(item.options[0]);
                      }}
                      applyLanguage={(l) => setLang(l)}
                      lang={lang}
                      styleControls={styleControls}
                      displayInfo={
                        !rightScreenVisibility.includes("visible") &&
                        !leftScreenVisibility.includes("visible")
                      }
                    />
                  </Route>
                ))}
                <Redirect to="/start" />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>

        {displayControls && (
          <>
            {currentScreens[1] && (
              <>
                <Control
                  direction={"left"}
                  style={leftControlStyle}
                  makeVisible={setLeftScreenVisibility}
                  click={controlClick}
                />
                <ControlScreen
                  setScreenVisibility={setLeftScreenVisibility}
                  screenVisibility={leftScreenVisibility}
                  currentScreen={currentScreens[0]}
                  screenClick={screenClick}
                  direction={"left"}
                  ref={leftScreenRef}
                  id={currentItems.options[0]}
                />
              </>
            )}
            {currentScreens[0] && (
              <>
                <Control
                  direction={"right"}
                  style={rightControlStyle}
                  makeVisible={setRightScreenVisibility}
                  click={controlClick}
                />
                <ControlScreen
                  setScreenVisibility={setRightScreenVisibility}
                  screenVisibility={rightScreenVisibility}
                  currentScreen={currentScreens[1] || currentScreens[0]}
                  screenClick={screenClick}
                  direction={"right"}
                  id={currentItems.options[1] || currentItems.options[0]}
                />
              </>
            )}
          </>
        )}
      </div>
    )
  ) : (
    <div>Please, turn your screen to landscape position</div>
  );
};

export default App;
