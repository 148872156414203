import React from "react";

import TextWTitle from "./TextWTitle";

import texts from "./text";

import animatedSocrealism from "./animations/socrealism";
import animatedIntro from "./animations/introu";
import animatedIntroEn from "./animations/intro";
import animatedConceptualism from "./animations/conceptualism";
import animatedFripulia from "./animations/fripulia";
import animatedSixties from "./animations/sixties";
import animatedTransavantgrade from "./animations/transavantgrade";
import animatedStart from "./animations/start";
import animatedBoychuk from "./animations/boychuk";
import animatedTwentiesThirties from "./animations/twenties-thirties";
import animatedThirtiesSixties from "./animations/thirties-sixties";
import animatedSixtiesEighties from "./animations/sixties-eighties";
import animatedKadyrova from "./animations/kadyrova";
import animatedKoptev from "./animations/koptev";
import animatedKuzmich from "./animations/kuzmich";
import animatedMakov from "./animations/kakhidze";
import animatedProtsenko from "./animations/protsenko";
import animatedRalko from "./animations/ralko";
import animatedExter from "./animations/exter";
import animatedRep from "./animations/rep";
import animatedZeroTwenties from "./animations/zero-twenties";
import animatedEightySeventhTwentyFourth from "./animations/eighty-seventh-twenty-fourth";
import animatedGnilitskaya from "./animations/gnilitskaya";
import animatedLisovenko from "./animations/lisovenko";
import animatedKarabinovich from "./animations/karabinovich";
import animatedHallParadise from "./animations/hall-paradise";
import animatedSavadov from "./animations/savadov";
import animatedFinal from "./animations/final";

//PICS SCREENS
import introFront from "./img/screens/intro/1.svg";
import introBack from "./img/screens/intro/2.svg";
import introFrontEn from "./img/screens/intro/1-en.svg";
import introBackEn from "./img/screens/intro/2-en.svg";

import whatUa from "./img/screens/whatua.svg";
import whatEn from "./img/screens/whaten.svg";

import ermilovFront from "./img/screens/ermilov/1.svg";
import ermilovBack from "./img/screens/ermilov/2.svg";
import ag from "./img/screens/ermilov/2-en.svg";

import boychuckFront from "./img/screens/boychuck/1.png";
import boychuckBack from "./img/screens/boychuck/2.svg";
import boychuckBackEn from "./img/screens/boychuck/2-en.svg";

import killFront from "./img/screens/kill/1.svg";
import killFrontEn from "./img/screens/kill/1-en.svg";

import hudfondFront from "./img/screens/hudfond/1.svg";
import hudfondBack from "./img/screens/hudfond/2.svg";
import hudfondFrontEn from "./img/screens/hudfond/1-en.svg";
import hudfondBackEn from "./img/screens/hudfond/2-en.svg";

import sixtiesFront from "./img/screens/sixties/1.svg";
import sixtiesBack from "./img/screens/sixties/2.svg";
import sixtiesBackEn from "./img/screens/sixties/2-en.svg";

import socrealismFront from "./img/screens/socrealism/1.svg";
import socrealismBack from "./img/screens/socrealism/2.svg";
import socrealismBackEn from "./img/screens/socrealism/2-en.svg";

import rulesFront from "./img/screens/rules/1.svg";
import rulesBack from "./img/screens/rules/2.svg";
import rulesFrontEn from "./img/screens/rules/1-en.svg";
import rulesBackEn from "./img/screens/rules/2-en.svg";

import conceptualismFront from "./img/screens/conceptualism/1.svg";
import conceptualismBack from "./img/screens/conceptualism/2.svg";
import conceptualismBackEn from "./img/screens/conceptualism/2-en.svg";

import fripuliaFront from "./img/screens/fripulia/1.svg";
import fripuliaBack from "./img/screens/fripulia/2.svg";
import fripuliaBackEn from "./img/screens/fripulia/2-en.svg";

import c8604Front from "./img/screens/8604/1.svg";
import c8604Back from "./img/screens/8604/2.svg";
import c8604FrontEn from "./img/screens/8604/1-en.svg";
import c8604BackEn from "./img/screens/8604/2-en.svg";

import transavantgradeFront from "./img/screens/transavantgrade/1.svg";
import transavantgradeBack from "./img/screens/transavantgrade/2.svg";
import transavantgradeBackEn from "./img/screens/transavantgrade/2-en.svg";

import transavantgradeFront2 from "./img/screens/transavantgrade/11.svg";
import transavantgradeBack2 from "./img/screens/transavantgrade/22.svg";
import transavantgradeBackEn2 from "./img/screens/transavantgrade/22-en.svg";

import savadovFront from "./img/screens/savadov/1.svg";
import savadovBack from "./img/screens/savadov/2.svg";
import savadovBackEn from "./img/screens/savadov/2-en.svg";

import futureFront from "./img/screens/future/1.svg";
import futureBack from "./img/screens/future/2.svg";
import futureBackEn from "./img/screens/future/2-en.svg";

import ralkoFront from "./img/screens/ralko/1.svg";
import ralkoBack from "./img/screens/ralko/2.svg";
import ralkoBackEn from "./img/screens/ralko/2-en.svg";

import ralkoFront2 from "./img/screens/ralko/11.svg";
import ralkoBack2 from "./img/screens/ralko/22.svg";
import ralkoBackEn2 from "./img/screens/ralko/22-en.svg";

import c0520Front from "./img/screens/0520/1.svg";
import c0520Back from "./img/screens/0520/2.svg";
import c0520FrontEn from "./img/screens/0520/1-en.svg";
import c0520BackEn from "./img/screens/0520/2-en.svg";

import kadyrovaFront from "./img/screens/kadyrova/1.svg";
import kadyrovaFront2 from "./img/screens/kadyrova/11.png";
import kadyrovaBack from "./img/screens/kadyrova/2.svg";
import kadyrovaBack2 from "./img/screens/kadyrova/22.svg";
import kadyrovaBackEn2 from "./img/screens/kadyrova/22-en.svg";
import kadyrovaBackEn from "./img/screens/kadyrova/2-en.svg";

import karabynovychFront from "./img/screens/karabynovych/1.png";
import karabynovychBack from "./img/screens/karabynovych/2.svg";
import karabynovychFront2 from "./img/screens/karabynovych/11.svg";
import karabynovychBack2 from "./img/screens/karabynovych/22.svg";

import karabynovychBackEn from "./img/screens/karabynovych/2-en.svg";
import karabynovychBackEn2 from "./img/screens/karabynovych/22-en.svg";

import kuzmichFront from "./img/screens/kuzmich/1.svg";
import kuzmichBack from "./img/screens/kuzmich/2.svg";
import kuzmichBackEn from "./img/screens/kuzmich/2-en.svg";

import kuzmichFront2 from "./img/screens/kuzmich/11.svg";
import kuzmichBack2 from "./img/screens/kuzmich/22.svg";
import kuzmichBackEn2 from "./img/screens/kuzmich/22-en.svg";

import lisovenkoFront from "./img/screens/lisovenko/1.svg";
import lisovenkoBack from "./img/screens/lisovenko/2.svg";
import lisovenkoBackEn from "./img/screens/lisovenko/2-en.svg";

import repFront from "./img/screens/rep/1.svg";
import repBack from "./img/screens/rep/2.svg";
import repBackEn from "./img/screens/rep/2-en.svg";

import repFront2 from "./img/screens/rep/11.svg";
import repBack2 from "./img/screens/rep/22.svg";
import repBackEn2 from "./img/screens/rep/22-en.svg";

import final from "./img/screens/final/final.svg";
import finalFrontEn from "./img/screens/final/finalen.svg";
import yak from "./img/screens/final/yak.png";
import yaken from "./img/screens/final/yaken.png";

import gnilitskayaFront from "./img/screens/gnilitskaya/1.svg";
import gnilitskayaBack from "./img/screens/gnilitskaya/2.svg";
import gnilitskayaBackEn from "./img/screens/gnilitskaya/2-en.svg";

import koptevFront from "./img/screens/koptev/1.svg";
import koptevBack from "./img/screens/koptev/2.svg";
import koptevBackEn from "./img/screens/koptev/2-en.svg";

//OPTION SCREENS
import introScreen from "./img/screens/intro/screen.png";

import lcontextScreen from "./img/screens/context/lscreen.png";
import rcontextScreen from "./img/screens/context/rscreen.png";

import rboychuckScreen from "./img/screens/boychuck/screen.png";

import rermilovScreen from "./img/screens/ermilov/screen.png";

import lkillScreen from "./img/screens/kill/Пекло.png";
import rkillScreen from "./img/screens/kill/рай.svg";

import rSoc from "./img/screens/soc-r.png";

import rE3364 from "./img/screens/3364-e-l.png";
import lE3364 from "./img/screens/3364-e-r.png";

import r60 from "./img/screens/60-r.png";

import rRules from "./img/screens/rules-r.png";

import rFripulia from "./img/screens/fripulia-r.png";

import rConcept from "./img/screens/concept-r.png";

import r6585 from "./img/screens/6585-r.png";
import l6585 from "./img/screens/6585-l.png";

import r8604 from "./img/screens/8604-r.png";
import l8604 from "./img/screens/8604-l.png";

import rf8604 from "./img/screens/8604-f-r.png";
import lf8604 from "./img/screens/8604-f-l.png";

import rTrans from "./img/screens/trans-r.png";

import rSavadov from "./img/screens/savadov-r.png";

import rRalko from "./img/screens/ralko-r.png";

import success from "./img/screens/success.png";

import rl0520 from "./img/screens/0520-r-l.png";
import rr0520 from "./img/screens/0520-r-r.png";

import al0520 from "./img/screens/0520-a-l.png";
import ar0520 from "./img/screens/0520-a-r.png";

import sl0520 from "./img/screens/0520-s-l.png";
import sr0520 from "./img/screens/0520-r.png";

import tl0520 from "./img/screens/0520-l.png";
import tr0520 from "./img/screens/0520-r.png";

import lFinal from "./img/screens/final-l.png";
import rFinal from "./img/screens/final-r.png";

import context2032 from "./img/screens/2032.svg";
import context2032en from "./img/screens/2032en.svg";

/* ----------------- */
import introScreenEn from "./img/screens/intro/screen-en.png";

import lcontextScreenEn from "./img/screens/context/lscreen-en.png";
import rcontextScreenEn from "./img/screens/context/rscreen-en.png";

import rboychuckScreenEn from "./img/screens/boychuck/screen-en.png";

import rermilovScreenEn from "./img/screens/ermilov/screen-en.png";

import lkillScreenEn from "./img/screens/kill/Пекло-en.png";
import rkillScreenEn from "./img/screens/kill/рай-en.svg";

import rSocEn from "./img/screens/soc-r-en.png";

import rE3364En from "./img/screens/3364-e-l-en.png";
import lE3364En from "./img/screens/3364-e-r-en.png";

import r60En from "./img/screens/60-r-en.png";

import rRulesEn from "./img/screens/rules-r-en.png";

import rFripuliaEn from "./img/screens/fripulia-r-en.png";

import rConceptEn from "./img/screens/concept-r-en.png";

import r6585En from "./img/screens/6585-r-en.png";
import l6585En from "./img/screens/6585-l-en.png";

import r8604En from "./img/screens/8604-r-en.png";
import l8604En from "./img/screens/8604-l-en.png";

import rf8604En from "./img/screens/8604-f-r-en.png";
import lf8604En from "./img/screens/8604-f-l-en.png";

import rTransEn from "./img/screens/trans-r-en.png";

import rSavadovEn from "./img/screens/savadov-r-en.png";

import rRalkoEn from "./img/screens/ralko-r-en.png";

import successEn from "./img/screens/success-en.png";

import rl0520En from "./img/screens/0520-r-l-en.png";
import rr0520En from "./img/screens/0520-r-r-en.png";

import al0520En from "./img/screens/0520-a-l-en.png";
import ar0520En from "./img/screens/0520-a-r-en.png";

import sl0520En from "./img/screens/0520-s-l-en.png";
import sr0520En from "./img/screens/0520-r-en.png";

import tl0520En from "./img/screens/0520-l-en.png";
import tr0520En from "./img/screens/0520-r-en.png";

import lFinalEn from "./img/screens/final-l-en.png";
import rFinalEn from "./img/screens/final-r-en.png";

const getTextById = (id, lang) => texts[lang].find((item) => item.id === id);

const ua = [
  {
    displayControls: false,
    id: "start",
    options: ["lang"],
    animate: animatedStart,
  },

  {
    displayControls: false,
    id: "lang",
    options: ["intro"],
    animate: [],
  },

  {
    displayControls: true,
    id: "intro",
    options: ["context"],
    animate: animatedIntro,
    screens: [<img src={introScreen} style={{}} />, <img src={introScreen} style={{}} />],
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={introFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={introBack} />
          </div>
        ),
      },
    ],
  },

  {
    displayControls: true,
    id: "context",
    options: ["boychuck", "ermilov"],
    animate: animatedTwentiesThirties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={context2032} />
            {/* <TextWTitle text={getTextById("context", "ua").text} /> */}
          </div>
        ),
        back: (
          <div className="black-screen">
            <img src={whatUa} />
            {/* <TextWTitle text={getTextById("context", "ua").text} /> */}
          </div>
        ),
      },
    ],
    screens: [
      <img src={lcontextScreen} style={{}} />,
      <img src={rcontextScreen} style={{}} />,
    ],
  },

  {
    displayControls: true,
    id: "ermilov",
    screens: [<img src={rermilovScreen} style={{}} />],
    options: ["3364-e"],
    animate: animatedExter,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={ermilovFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={ermilovBack} />
          </div>
        ),
      },
    ],
  },

  {
    displayControls: true,
    id: "boychuck",
    options: ["kill"],
    animate: animatedBoychuk,
    screens: [<img src={rboychuckScreen} style={{}} />],
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={boychuckFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={boychuckBack} />
          </div>
        ),
      },
    ],
  },

  {
    displayControls: true,
    id: "kill",
    options: ["socrealism-2"],
    animate: animatedHallParadise,
    pics: [
      {
        front: (
          <div className="black-screen" id="kill">
            <img src={killFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={killFront} />
          </div>
        ),
      },
    ],
    screens: [
      <img src={lkillScreen} style={{}} />,
      <img src={rkillScreen} style={{}} />,
    ],
  },

  {
    displayControls: true,
    id: "socrealism-2",
    options: ["3364"],
    animate: animatedSocrealism,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={socrealismFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={socrealismBack} />
          </div>
        ),
      },
    ],
    screens: [<img src={rSoc} style={{}} />],
  },

  {
    displayControls: true,
    id: "3364",
    options: ["rules"],
    animate: animatedThirtiesSixties,
    pics: [
      {
        front: (
          <div className="black-screen rules">
            <img src={hudfondFront} />
          </div>
        ),
        back: (
          <div className="black-screen rules">
            <img src={hudfondFront} />
          </div>
        ),
      },
    ],
    screens: [<img src={rRules} style={{}} />],
  },

  {
    displayControls: true,
    id: "3364-e",
    options: ["socrealism", "sixties"],
    animate: animatedThirtiesSixties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={hudfondFront} />
            {/* <TextWTitle text={getTextById("3364-e", "ua").text} /> */}
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={hudfondBack} />
          </div>
        ),
      },
    ],
    screens: [<img src={lE3364} style={{}} />, <img src={rE3364} style={{}} />],
  },

  {
    displayControls: true,
    id: "sixties",
    options: ["rules"],
    animate: animatedSixties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={sixtiesFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={sixtiesBack} />
          </div>
        ),
      },
    ],
    screens: [<img src={r60} style={{}} />],
  },

  {
    displayControls: true,
    id: "socrealism",
    options: ["rules"],
    animate: animatedSocrealism,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={socrealismFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={socrealismBack} />
          </div>
        ),
      },
    ],
    screens: [<img src={rSoc} style={{}} />],
  },

  {
    displayControls: true,
    id: "rules",
    options: ["conceptualism", "fripulia"],
    animate: animatedSixtiesEighties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={rulesFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={rulesBack} />
          </div>
        ),
      },
    ],
    screens: [<img src={l6585} style={{}} />, <img src={r6585} style={{}} />],
  },

  {
    displayControls: true,
    id: "fripulia",
    options: ["ninties"],
    animate: animatedFripulia,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={fripuliaFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={fripuliaBack} />
          </div>
        ),
      },
    ],
    screens: [<img src={rFripulia} style={{}} />],
  },

  {
    displayControls: true,
    id: "conceptualism",
    options: ["moscow-ninties"],
    animate: animatedConceptualism,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={conceptualismFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={conceptualismBack} />
          </div>
        ),
      },
    ],
    screens: [<img src={rConcept} style={{}} />],
  },

  {
    displayControls: true,
    id: "moscow-ninties",
    options: ["transavantgrade", "savadov"],
    animate: animatedEightySeventhTwentyFourth,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={c8604Front} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={c8604Back} />
          </div>
        ),
      },
    ],
    screens: [<img src={l8604} style={{}} />, <img src={r8604} style={{}} />],
  },

  {
    displayControls: true,
    id: "ninties",
    options: ["future", "ralko"],
    animate: animatedEightySeventhTwentyFourth,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={c8604Front} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={c8604Back} />
          </div>
        ),
      },
    ],
    screens: [<img src={lf8604} style={{}} />, <img src={rf8604} style={{}} />],
  },

  {
    displayControls: true,
    id: "transavantgrade",
    options: ["t0520"],
    animate: animatedTransavantgrade,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={transavantgradeFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={transavantgradeBack} />
          </div>
        ),
      },
      {
        front: (
          <div className="black-screen">
            <img src={transavantgradeFront2} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={transavantgradeBack2} />
          </div>
        ),
      },
    ],
    screens: [<img src={rTrans} style={{}} />],
  },

  {
    displayControls: true,
    id: "savadov",
    options: ["s0520"],
    animate: animatedSavadov,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={savadovFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={savadovBack} />
          </div>
        ),
      },
    ],
    screens: [<img src={rSavadov} style={{}} />],
  },

  {
    displayControls: true,
    id: "future",
    options: ["a0520"],
    animate: animatedMakov,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={futureFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={futureBack} />
          </div>
        ),
      },
    ],
    screens: [<img src={rRalko} style={{}} />],
  },

  {
    displayControls: true,
    id: "ralko",
    options: ["r0520"],
    animate: animatedRalko,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={ralkoFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror rules">
            <img src={ralkoBack} />
          </div>
        ),
      },
      {
        front: (
          <div className="black-screen">
            <img src={ralkoFront2} />
          </div>
        ),
        back: (
          <div className="black-screen mirror rules">
            <img src={ralkoBack2} />
          </div>
        ),
      },
    ],
    screens: [<img src={rRalko} style={{}} />],
  },

  {
    displayControls: true,
    id: "s0520",
    options: ["rep", "koptev"],
    animate: animatedZeroTwenties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={c0520Front} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={c0520Back} />
          </div>
        ),
      },
    ],
    screens: [<img src={sl0520} style={{}} />, <img src={sr0520} style={{}} />],
  },

  {
    displayControls: true,
    id: "r0520",
    options: ["karabynovych", "lisovenko"],
    animate: animatedZeroTwenties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={c0520Front} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={c0520Back} />
          </div>
        ),
      },
    ],
    screens: [<img src={rl0520} style={{}} />, <img src={rr0520} style={{}} />],
  },

  {
    displayControls: true,
    id: "a0520",
    options: ["gnilitskaya", "kuzmich"],
    animate: animatedZeroTwenties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={c0520Front} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={c0520Back} />
          </div>
        ),
      },
    ],
    screens: [<img src={al0520} style={{}} />, <img src={ar0520} style={{}} />],
  },

  {
    displayControls: true,
    id: "t0520",
    options: ["kadyrova", "koptev"],
    animate: animatedZeroTwenties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={c0520Front} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={c0520Back} />
          </div>
        ),
      },
    ],
    screens: [<img src={tl0520} style={{}} />, <img src={tr0520} style={{}} />],
  },

  {
    displayControls: true,
    id: "kadyrova",
    options: ["final"],
    animate: animatedKadyrova,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={kadyrovaFront2} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={kadyrovaBack2} />
          </div>
        ),
      },
      {
        front: (
          <div className="black-screen">
            <img src={kadyrovaFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={kadyrovaBack} />
          </div>
        ),
      },
    ],
    screens: [<img src={success} style={{}} />],
  },

  {
    displayControls: true,
    id: "rep",
    options: ["final"],
    animate: animatedRep,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={repFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={repBack} />
          </div>
        ),
      },
      {
        front: (
          <div className="black-screen">
            <img src={repFront2} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={repBack2} />
          </div>
        ),
      },
    ],
    screens: [<img src={success} style={{}} />],
  },
  {
    displayControls: true,
    id: "koptev",
    options: ["final"],
    animate: animatedKoptev,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={koptevFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={koptevBack} />
          </div>
        ),
      },
    ],
    screens: [<img src={success} style={{}} />],
  },

  {
    displayControls: true,
    id: "gnilitskaya",
    options: ["final"],
    animate: animatedGnilitskaya,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={gnilitskayaFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={gnilitskayaBack} />
          </div>
        ),
      },
    ],
    screens: [<img src={success} style={{}} />],
  },
  {
    displayControls: true,
    id: "kuzmich",
    options: ["final"],
    animate: animatedKuzmich,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={kuzmichFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={kuzmichBack} />
          </div>
        ),
      },
      {
        front: (
          <div className="black-screen">
            <img src={kuzmichFront2} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={kuzmichBack2} />
          </div>
        ),
      },
    ],
    screens: [<img src={success} style={{}} />],
  },

  {
    displayControls: true,
    id: "karabynovych",
    options: ["final"],
    animate: animatedKarabinovich,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={karabynovychFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={karabynovychBack} />
          </div>
        ),
      },
      {
        front: (
          <div className="black-screen">
            <img src={karabynovychFront2} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={karabynovychBack2} />
          </div>
        ),
      },
    ],
    screens: [<img src={success} style={{}} />],
  },
  {
    displayControls: true,
    id: "lisovenko",
    options: ["final"],
    animate: animatedLisovenko,
    pics: [
      {
        front: (
          <div className="black-screen" id="lisovenko">
            <img src={lisovenkoFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={lisovenkoBack} />
          </div>
        ),
      },
    ],
    screens: [<img src={success} style={{}} />],
  },
  {
    displayControls: true,
    id: "final",
    options: ["start", "start"],
    animate: animatedFinal,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={final} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={yak} />
          </div>
        ),
      },
    ],
    screens: [<img src={lFinal} style={{}} />, <img src={rFinal} style={{}} />],
  },
];

/*-------------------------------------*/

const en = [
  {
    displayControls: false,
    id: "start",
    options: ["lang"],
    animate: animatedStart,
  },

  {
    displayControls: false,
    id: "lang",
    options: ["intro"],
    animate: [],
  },

  {
    displayControls: true,
    id: "intro",
    options: ["context"],
    animate: animatedIntroEn,
    screens: [<img src={introScreenEn} style={{}} />],
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={introFrontEn} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={introBackEn} />
          </div>
        ),
      },
    ],
  },

  {
    displayControls: true,
    id: "context",
    options: ["boychuck", "ermilov"],
    animate: animatedTwentiesThirties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={context2032en} />
            {/* <TextWTitle text={getTextById("context", "en").text} /> */}
          </div>
        ),
        back: (
          <div className="black-screen">
            <img src={whatEn} />
            {/* <TextWTitle text={getTextById("context", "en").text} /> */}
          </div>
        ),
      },
    ],
    screens: [
      <img src={lcontextScreenEn} style={{}} />,
      <img src={rcontextScreenEn} style={{}} />,
    ],
  },

  {
    displayControls: true,
    id: "ermilov",
    screens: [<img src={rermilovScreenEn} style={{}} />],
    options: ["3364-e"],
    animate: animatedExter,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={ermilovFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={ag} />
          </div>
        ),
      },
    ],
  },

  {
    displayControls: true,
    id: "boychuck",
    options: ["kill"],
    animate: animatedBoychuk,
    screens: [<img src={rboychuckScreenEn} style={{}} />],
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={boychuckFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={boychuckBackEn} />
          </div>
        ),
      },
    ],
  },

  {
    displayControls: true,
    id: "kill",
    options: ["socrealism-2"],
    animate: animatedHallParadise,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={killFrontEn} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={killFrontEn} />
          </div>
        ),
      },
    ],
    screens: [
      <img src={lkillScreenEn} style={{}} />,
      <img src={rkillScreenEn} style={{}} />,
    ],
  },

  {
    displayControls: true,
    id: "socrealism-2",
    options: ["3364"],
    animate: animatedSocrealism,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={socrealismFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={socrealismBackEn} />
          </div>
        ),
      },
    ],
    screens: [<img src={rSocEn} style={{}} />],
  },

  {
    displayControls: true,
    id: "3364",
    options: ["rules"],
    animate: animatedThirtiesSixties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={hudfondFrontEn} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={hudfondFrontEn} />
          </div>
        ),
      },
    ],
    screens: [<img src={rRulesEn} style={{}} />],
  },

  {
    displayControls: true,
    id: "3364-e",
    options: ["socrealism", "sixties"],
    animate: animatedThirtiesSixties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={hudfondFrontEn} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={hudfondBackEn} />
          </div>
        ),
      },
    ],
    screens: [
      <img src={lE3364En} style={{}} />,
      <img src={rE3364En} style={{}} />,
    ],
  },

  {
    displayControls: true,
    id: "sixties",
    options: ["rules"],
    animate: animatedSixties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={sixtiesFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={sixtiesBackEn} />
          </div>
        ),
      },
    ],
    screens: [<img src={r60En} style={{}} />],
  },

  {
    displayControls: true,
    id: "socrealism",
    options: ["rules"],
    animate: animatedSocrealism,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={socrealismFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={socrealismBackEn} />
          </div>
        ),
      },
    ],
    screens: [<img src={rSocEn} style={{}} />],
  },

  {
    displayControls: true,
    id: "rules",
    options: ["conceptualism", "fripulia"],
    animate: animatedSixtiesEighties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={rulesFrontEn} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={rulesBackEn} />
          </div>
        ),
      },
    ],
    screens: [
      <img src={l6585En} style={{}} />,
      <img src={r6585En} style={{}} />,
    ],
  },

  {
    displayControls: true,
    id: "fripulia",
    options: ["ninties"],
    animate: animatedFripulia,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={fripuliaFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={fripuliaBackEn} />
          </div>
        ),
      },
    ],
    screens: [<img src={rFripuliaEn} style={{}} />],
  },

  {
    displayControls: true,
    id: "conceptualism",
    options: ["moscow-ninties"],
    animate: animatedConceptualism,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={conceptualismFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={conceptualismBackEn} />
          </div>
        ),
      },
    ],
    screens: [<img src={rConceptEn} style={{}} />],
  },

  {
    displayControls: true,
    id: "moscow-ninties",
    options: ["transavantgrade", "savadov"],
    animate: animatedEightySeventhTwentyFourth,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={c8604FrontEn} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={c8604BackEn} />
          </div>
        ),
      },
    ],
    screens: [
      <img src={l8604En} style={{}} />,
      <img src={r8604En} style={{}} />,
    ],
  },

  {
    displayControls: true,
    id: "ninties",
    options: ["future", "ralko"],
    animate: animatedEightySeventhTwentyFourth,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={c8604FrontEn} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={c8604BackEn} />
          </div>
        ),
      },
    ],
    screens: [
      <img src={lf8604En} style={{}} />,
      <img src={rf8604En} style={{}} />,
    ],
  },

  {
    displayControls: true,
    id: "transavantgrade",
    options: ["t0520"],
    animate: animatedTransavantgrade,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={transavantgradeFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={transavantgradeBackEn} />
          </div>
        ),
      },
      {
        front: (
          <div className="black-screen">
            <img src={transavantgradeFront2} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={transavantgradeBackEn2} />
          </div>
        ),
      },
    ],
    screens: [<img src={rTransEn} style={{}} />],
  },

  {
    displayControls: true,
    id: "savadov",
    options: ["s0520"],
    animate: animatedSavadov,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={savadovFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={savadovBackEn} />
          </div>
        ),
      },
    ],
    screens: [<img src={rSavadovEn} style={{}} />],
  },

  {
    displayControls: true,
    id: "future",
    options: ["a0520"],
    animate: animatedMakov,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={futureFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={futureBackEn} />
          </div>
        ),
      },
    ],
    screens: [<img src={rRalkoEn} style={{}} />],
  },

  {
    displayControls: true,
    id: "ralko",
    options: ["r0520"],
    animate: animatedRalko,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={ralkoFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={ralkoBackEn} />
          </div>
        ),
      },
      {
        front: (
          <div className="black-screen">
            <img src={ralkoFront2} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={ralkoBackEn2} />
          </div>
        ),
      },
    ],
    screens: [<img src={rRalkoEn} style={{}} />],
  },

  {
    displayControls: true,
    id: "s0520",
    options: ["rep", "koptev"],
    animate: animatedZeroTwenties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={c0520FrontEn} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={c0520BackEn} />
          </div>
        ),
      },
    ],
    screens: [
      <img src={sl0520En} style={{}} />,
      <img src={sr0520En} style={{}} />,
    ],
  },

  {
    displayControls: true,
    id: "r0520",
    options: ["karabynovych", "lisovenko"],
    animate: animatedZeroTwenties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={c0520FrontEn} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={c0520BackEn} />
          </div>
        ),
      },
    ],
    screens: [
      <img src={rl0520En} style={{}} />,
      <img src={rr0520En} style={{}} />,
    ],
  },

  {
    displayControls: true,
    id: "a0520",
    options: ["gnilitskaya", "kuzmich"],
    animate: animatedZeroTwenties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={c0520FrontEn} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={c0520BackEn} />
          </div>
        ),
      },
    ],
    screens: [
      <img src={al0520En} style={{}} />,
      <img src={ar0520En} style={{}} />,
    ],
  },

  {
    displayControls: true,
    id: "t0520",
    options: ["kadyrova", "koptev"],
    animate: animatedZeroTwenties,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={c0520FrontEn} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={c0520BackEn} />
          </div>
        ),
      },
    ],
    screens: [
      <img src={tl0520En} style={{}} />,
      <img src={tr0520En} style={{}} />,
    ],
  },

  {
    displayControls: true,
    id: "kadyrova",
    options: ["final"],
    animate: animatedKadyrova,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={kadyrovaFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={kadyrovaBackEn} />
          </div>
        ),
      },
      {
        front: (
          <div className="black-screen">
            <img src={kadyrovaFront2} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={kadyrovaBackEn2} />
          </div>
        ),
      },
    ],
    screens: [<img src={successEn} style={{}} />],
  },

  {
    displayControls: true,
    id: "rep",
    options: ["final"],
    animate: animatedRep,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={repFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={repBackEn} />
          </div>
        ),
      },
      {
        front: (
          <div className="black-screen">
            <img src={repFront2} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={repBackEn2} />
          </div>
        ),
      },
    ],
    screens: [<img src={successEn} style={{}} />],
  },
  {
    displayControls: true,
    id: "koptev",
    options: ["final"],
    animate: animatedKoptev,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={koptevFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={koptevBackEn} />
          </div>
        ),
      },
    ],
    screens: [<img src={successEn} style={{}} />],
  },

  {
    displayControls: true,
    id: "gnilitskaya",
    options: ["final"],
    animate: animatedGnilitskaya,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={gnilitskayaFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={gnilitskayaBackEn} />
          </div>
        ),
      },
    ],
    screens: [<img src={successEn} style={{}} />],
  },
  {
    displayControls: true,
    id: "kuzmich",
    options: ["final"],
    animate: animatedKuzmich,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={kuzmichFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={kuzmichBackEn} />
          </div>
        ),
      },
      {
        front: (
          <div className="black-screen">
            <img src={kuzmichFront2} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={kuzmichBackEn2} />
          </div>
        ),
      },
    ],
    screens: [<img src={successEn} style={{}} />],
  },

  {
    displayControls: true,
    id: "karabynovych",
    options: ["final"],
    animate: animatedKarabinovich,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={karabynovychFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={karabynovychBackEn} />
          </div>
        ),
      },
      {
        front: (
          <div className="black-screen">
            <img src={karabynovychFront2} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={karabynovychBackEn2} />
          </div>
        ),
      },
    ],
    screens: [<img src={successEn} style={{}} />],
  },
  {
    displayControls: true,
    id: "lisovenko",
    options: ["final"],
    animate: animatedLisovenko,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={lisovenkoFront} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={lisovenkoBackEn} />
          </div>
        ),
      },
    ],
    screens: [<img src={successEn} style={{}} />],
  },
  {
    displayControls: true,
    id: "final",
    options: ["start", "start"],
    animate: animatedFinal,
    pics: [
      {
        front: (
          <div className="black-screen">
            <img src={finalFrontEn} />
          </div>
        ),
        back: (
          <div className="black-screen mirror">
            <img src={yaken} />
          </div>
        ),
      },
    ],
    screens: [
      <img src={lFinalEn} style={{}} />,
      <img src={rFinalEn} style={{}} />,
    ],
  },
];

export default { ua, en };
