import React from 'react';

import r_bust from './img/1_r_bust_89.38_50.19.png';
import r_list from './img/2_r_list_77.71_88.7.png';
import r_molbert from './img/3_r_molbert_82.08_57.69.png';
import r_colon from './img/4_r_colon_86.51_4.91.png';
import r_lamp from './img/5_r_lamp_60.21_0.png';
import r_lenta from './img/6_r_lenta_71.72_5.28.png';
import r_stul from './img/7_r_stul_72.66_85.19.png';
import l_stul from './img/8_l_stul_2.45_80.37.png';
import l_cvetok from './img/9_l_cvetok_0_54.63.png';
import l_vino from './img/10_l_vino_50.1_88.7.png';
import l_table from './img/11_l_table_lamp_26.09_85.46.png';
import l_phone from './img/12_l_phone_35.31_88.52.png';
import l_colona from './img/13_l_colona_0_4.91.png';
import l_lystra from './img/14_l_lystra_18.39_0.png';
import l_lenta from './img/15_l_lenta_12.29_0.png';
import pol from './img/16_pol_0_90.74.png';
import potolok from './img/17_potolok_7.66_0.png';
import r_ugol from './img/18_r_ugol_80.99_15.65.png';
import l_ugol from './img/19_l_ugol_0_5.46.png';


export default [
    <div className="animated-item socialistrealism r_bust"><img src={r_bust} alt="r_bust"/></div>,
    <div className="animated-item socialistrealism r_list"><img src={r_list} alt="r_list"/></div>,
    <div className="animated-item socialistrealism r_molbert"><img src={r_molbert} alt="r_molbert"/></div>,
    <div className="animated-item socialistrealism r_colon"><img src={r_colon} alt="r_colon"/></div>,
    <div className="animated-item socialistrealism r_lamp"><img src={r_lamp} alt="r_lamp"/></div>,
    <div className="animated-item socialistrealism r_lenta"><img src={r_lenta} alt="r_lenta"/></div>,
    <div className="animated-item socialistrealism r_stul"><img src={r_stul} alt="r_stul"/></div>,
    <div className="animated-item socialistrealism l_stul"><img src={l_stul} alt="l_stul"/></div>,
    <div className="animated-item socialistrealism l_cvetok"><img src={l_cvetok} alt="l_cvetok"/></div>,
    <div className="animated-item socialistrealism l_vino"><img src={l_vino} alt="l_vino"/></div>,
    <div className="animated-item socialistrealism l_table"><img src={l_table} alt="l_table"/></div>,
    <div className="animated-item socialistrealism l_phone "><img src={l_phone } alt="l_phone "/></div>,
    <div className="animated-item socialistrealism l_colona "><img src={l_colona } alt="l_colona"/></div>,
    <div className="animated-item socialistrealism l_lystra"><img src={l_lystra} alt="l_lystra"/></div>,
    <div className="animated-item socialistrealism l_lenta"><img src={l_lenta} alt="l_lenta"/></div>,
    <div className="animated-item socialistrealism pol"><img src={pol} alt="pol"/></div>,
    <div className="animated-item socialistrealism potolok"><img src={potolok} alt="potolok"/></div>,
    <div className="animated-item socialistrealism r_ugol "><img src={r_ugol} alt="r_ugol "/></div>,
    <div className="animated-item socialistrealism l_ugol "><img src={l_ugol} alt="l_ugol "/></div>,
    <div className="animated-item socialistrealism bg "></div>,
]