import React from 'react';

import r_bumagka_estf from './img/1_r_bumagka_1_57.6_96.78.png';
import r_bumagka2_estf from './img/2_r_bumagka_2_62.5_96.11.png';
import r_arbuzi_estf from './img/3_r_arbuzi_80_78.33.png';
import l_taxi_estf from './img/4_l_taxi_3.07_83.7.png';
import r_gazil_estf from './img/5_r_gazil_66.88_67.87.png';
import r_bar_estf from './img/6_r_bar_80.57_11.02.png';
import r_derevo_estf from './img/7_r_derevo_74.48_36.85.png';
import l_stolb_estf from './img/8_l_stolb_11.46_41.85.png';
import r_reklama_estf from './img/9_r_reklama_63.44_83.8.png';
import l_bildbord_estf from './img/10_l_bildbord_5.52_45.28.png';
import l_kust_estf from './img/11_l_kust_9.48_82.13.png';
import l_dom_estf from './img/12_l_dom_0_3.52.png';
import l_derevo_estf from './img/13_l_derevo_11.56_36.85.png';
import zemly_estf from './img/14_zemly_0_90.46.png';

export default [
    <div className="animated-item r_bumagka_estf"><img src={r_bumagka_estf} alt="r_bumagka_estf"/></div>,
    <div className="animated-item r_bumagka2_estf"><img src={r_bumagka2_estf} alt="r_bumagka2_estf"/></div>,
    <div className="animated-item r_arbuzi_estf"><img src={r_arbuzi_estf} alt="r_arbuzi_estf"/></div>,
    <div className="animated-item l_taxi_estf"><img src={l_taxi_estf} alt="l_taxi_estf"/></div>,
    <div className="animated-item r_gazil_estf"><img src={r_gazil_estf} alt="r_gazil_estf"/></div>,
    <div className="animated-item r_bar_estf"><img src={r_bar_estf} alt="r_bar_estf"/></div>,
    <div className="animated-item r_derevo_estf"><img src={r_derevo_estf} alt="r_derevo_estf"/></div>,
    <div className="animated-item l_stolb_estf"><img src={l_stolb_estf} alt="l_stolb_estf"/></div>,
    <div className="animated-item r_reklama_estf"><img src={r_reklama_estf} alt="r_reklama_estf"/></div>,
    <div className="animated-item l_bildbord_estf"><img src={l_bildbord_estf} alt="l_bildbord_estf"/></div>,
    <div className="animated-item l_kust_estf"><img src={l_kust_estf} alt="l_kust_estf"/></div>,
    <div className="animated-item l_dom_estf"><img src={l_dom_estf} alt="l_dom_estf"/></div>,
    <div className="animated-item l_derevo_estf"><img src={l_derevo_estf} alt="l_derevo_estf"/></div>,
    <div className="animated-item zemly_estf"><img src={zemly_estf} alt="zemly_estf"/></div>,
    <div className="animated-item bg_estf"></div>, 
]