import React from 'react';

import l_fonar_zero from './img/1_l_fonar_0.26_53.33.png';
import r_vetka1_zero from './img/2_r_vetka1_85.78_96.2.png';
import r_vetka2_zero from './img/3_r_vetka2_64.53_92.22.png';
import r_snaryd_zero from './img/4_r_snaryd_91.72_89.26.png';
import r_shina_zero from './img/5_r_shina_69.27_93.52.png';
import l_bochka_zero from './img/6_l_bochka_25.36_86.48.png';
import l_bordur_zero from './img/7_l_bordur_0_96.2.png';
import r_debalcevo_zero from './img/8_r_debalcevo_72.19_69.81.png';
import r_blok_zero from './img/9_r_blok_69.32_87.31.png';
import r_meshki_zero from './img/10_r_meshki_63.65_87.41.png';
import r_derevo_zero from './img/11_r_derevo_97.5_83.15.png';
import l_boklajka_zero from './img/12_l_boklajka_23.13_88.43.png';
import l_palatki_zero from './img/13_l_palatki_0_53.15.png';
import l_dom_zero from './img/14_l_dom_0_45.74.png';
import r_dom_zero from './img/15_r_dom_79.79_33.61.png';
import zemly_zero from './img/16_zemly_0_89.91.png';
import l_kusti_zero from './img/17_l_kusti_0_61.48.png';

export default [
    <div className="animated-item l_fonar_zero"><img src={l_fonar_zero} alt="l_fonar_zero"/></div>,
    <div className="animated-item r_vetka1_zero"><img src={r_vetka1_zero} alt="r_vetka1_zero"/></div>,
    <div className="animated-item r_vetka2_zero"><img src={r_vetka2_zero} alt="r_vetka2_zero"/></div>,
    <div className="animated-item r_snaryd_zero"><img src={r_snaryd_zero} alt="r_snaryd_zero"/></div>,
    <div className="animated-item r_shina_zero"><img src={r_shina_zero} alt="r_shina_zero"/></div>,
    <div className="animated-item l_bochka_zero"><img src={l_bochka_zero} alt="l_bochka_zero"/></div>,
    <div className="animated-item l_bordur_zero"><img src={l_bordur_zero} alt="l_bordur_zero"/></div>,
    <div className="animated-item r_debalcevo_zero"><img src={r_debalcevo_zero} alt="r_debalcevo_zero"/></div>,
    <div className="animated-item r_blok_zero"><img src={r_blok_zero} alt="r_blok_zero"/></div>,
    <div className="animated-item r_meshki_zero"><img src={r_meshki_zero} alt="r_meshki_zero"/></div>,
    <div className="animated-item r_derevo_zero"><img src={r_derevo_zero} alt="r_derevo_zero"/></div>,
    <div className="animated-item l_boklajka_zero"><img src={l_boklajka_zero} alt="l_boklajka_zero"/></div>,
    <div className="animated-item l_palatki_zero"><img src={l_palatki_zero} alt="l_palatki_zero"/></div>,
    <div className="animated-item l_dom_zero"><img src={l_dom_zero} alt="l_dom_zero"/></div>,
    <div className="animated-item r_dom_zero"><img src={r_dom_zero} alt="r_dom_zero"/></div>,
    <div className="animated-item zemly_zero"><img src={zemly_zero} alt="zemly_zero"/></div>,
    <div className="animated-item l_kusti_zero"><img src={l_kusti_zero} alt="l_kusti_zero"/></div>,
    <div className="animated-item bg_zero"></div>, 
]