import React from 'react';

import r_fotoaparat1_gnil from './img/1_r_fotoaparat1_82.71_59.07.png';
import r_foto1_gnil from './img/2_r_foto1_93.13_92.22.png';
import r_plyonka_gnil from './img/3_r_plyonka_81.3_95.09.png';
import l_svech1_gnil from './img/4_l_svech1_3.28_90.93.png';
import l_svech2_gnil from './img/5_l_svech2_27.29_91.3.png';
import l_svech_gnil from './img/6_l_svech_5.16_87.41.png';
import l_stol_gnil from './img/7_l_stol_16.61_71.76.png';
import l_kreslo_gnil from './img/8_l_kreslo_8.33_75.74.png';
import r_fotoapara_gnil from './img/9_r_fotoaparat2_65.99_92.31.png';
import r_foto2_gnil from './img/10_r_foto2_67.86_93.24.png';
import r_korobka_gnil from './img/11_r_korobka_50.78_92.13.png';
import r_rezervuar_gnil from './img/12_r_rezervuar_56.15_90.93.png';
import l_perchatki_gnil from './img/13_l_perchatki_27.29_90.19.png';
import r_svitok_gnil from './img/14_r_svitok_75.1_89.54.png';
import r_rabota_gnil from './img/15_r_rabota_89.69_37.31.png';
import l_kolona_gnil from './img/16_l_kolona_0.99_21.2.png';
import l_vetka_gnil from './img/17_l_vetka_5.31_60.19.png';
import l_stena_gnil from './img/18_l_stena_0_0.png';
import r_stena_gnil from './img/19_r_stena_85.42_0.png';
import potolok_gnil from './img/20_potolok_0.99_0.png';
import pol_gnil from './img/21_pol_0_91.67.png';

export default [
    <div className="animated-item r_fotoaparat1_gnil"><img src={r_fotoaparat1_gnil} alt="r_fotoaparat1_gnil"/></div>,
    <div className="animated-item r_foto1_gnil"><img src={r_foto1_gnil} alt="r_foto1_gnil"/></div>,
    <div className="animated-item r_plyonka_gnil"><img src={r_plyonka_gnil} alt="r_plyonka_gnil"/></div>,
    <div className="animated-item l_svech1_gnil"><img src={l_svech1_gnil} alt="l_svech1_gnil"/></div>,
    <div className="animated-item l_svech2_gnil"><img src={l_svech2_gnil} alt="l_svech2_gnil"/></div>,
    <div className="animated-item l_svech_gnil"><img src={l_svech_gnil} alt="l_svech_gnil"/></div>,
    <div className="animated-item l_stol_gnil"><img src={l_stol_gnil} alt="l_stol_gnil"/></div>,
    <div className="animated-item l_kreslo_gnil"><img src={l_kreslo_gnil} alt="l_kreslo_gnil"/></div>,
    <div className="animated-item r_fotoapara_gnil"><img src={r_fotoapara_gnil} alt="r_fotoapara_gnil"/></div>,
    <div className="animated-item r_foto2_gnil"><img src={r_foto2_gnil} alt="r_foto2_gnil"/></div>,
    <div className="animated-item r_korobka_gnil"><img src={r_korobka_gnil} alt="r_korobka_gnil"/></div>,
    <div className="animated-item r_rezervuar_gnil"><img src={r_rezervuar_gnil} alt="r_rezervuar_gnil"/></div>,
    <div className="animated-item l_perchatki_gnil"><img src={l_perchatki_gnil} alt="l_perchatki_gnil"/></div>,
    <div className="animated-item r_svitok_gnil"><img src={r_svitok_gnil} alt="r_svitok_gnil"/></div>,
    <div className="animated-item r_rabota_gnil"><img src={r_rabota_gnil} alt="r_rabota_gnil"/></div>,
    <div className="animated-item l_kolona_gnil"><img src={l_kolona_gnil} alt="l_kolona_gnil"/></div>,
    <div className="animated-item l_vetka_gnil"><img src={l_vetka_gnil} alt="l_vetka_gnil"/></div>,
    <div className="animated-item l_stena_gnil"><img src={l_stena_gnil} alt="l_stena_gnil"/></div>,
    <div className="animated-item r_stena_gnil"><img src={r_stena_gnil} alt="r_stena_gnil"/></div>,
    <div className="animated-item potolok_gnil"><img src={potolok_gnil} alt="potolok_gnil"/></div>,
    <div className="animated-item pol_gnil"><img src={pol_gnil} alt="pol_gnil"/></div>,
    <div className="animated-item bg_gnil"></div>, 
]