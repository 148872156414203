import React from 'react';

import r_stolb_ts from './img/1_r_stolb_92.03_46.85.png';
import r_bashny_ts from './img/2_r_bashny_71.61_85.46.png';
import l_furgon_ts from './img/3_l_furgon_16.04_75.28.png';
import r_gilza1_ts from './img/4_r_gilza1_65.99_95.74.png';
import r_gilza2_ts from './img/5_r_gilza2_76.56_94.72.png';
import r_magazin1_ts from './img/6_r_magazin1_52.34_92.04.png';
import r_magazim2_ts from './img/7_r_magazim2_61.3_95.56.png';
import r_tank_ts from './img/8_r_tank_78.18_78.89.png';
import r_snaryd_ts from './img/9_r_snaryd_55.63_93.33.png';
import l_derevo_ts from './img/10_l_derevo_0_71.94.png';
import r_ruini_ts from './img/11_r_ruini_60.05_77.78.png';
import r_snaryd2_ts from './img/12_r_snaryd2_55.63_88.98.png';
import r_dom_ts from './img/13_r_dom_83.49_37.22.png';
import l_stalinka_ts from './img/14_l_stalinka_0_1.76.png';
import zenly_ts from './img/15_zenly_0_88.52.png';


export default [
    <div className="animated-item r_stolb_ts"><img src={r_stolb_ts} alt="r_stolb_ts"/></div>,
   <div className="animated-item r_bashny_ts"><img src={r_bashny_ts} alt="r_bashny_ts"/></div>,
   <div className="animated-item l_furgon_ts"><img src={l_furgon_ts} alt="l_furgon_ts"/></div>,
   <div className="animated-item r_gilza1_ts"><img src={r_gilza1_ts} alt="r_gilza1_ts"/></div>,
   <div className="animated-item r_gilza2_ts"><img src={r_gilza2_ts} alt="r_gilza2_ts"/></div>,
   <div className="animated-item r_magazin1_ts"><img src={r_magazin1_ts} alt="r_magazin1_ts"/></div>,
   <div className="animated-item r_magazim2_ts"><img src={r_magazim2_ts} alt="r_magazim2_ts"/></div>,
   <div className="animated-item r_tank_ts"><img src={r_tank_ts} alt="r_tank_ts"/></div>,
   <div className="animated-item r_snaryd_ts"><img src={r_snaryd_ts} alt="r_snaryd_ts"/></div>,
   <div className="animated-item l_derevo_ts"><img src={l_derevo_ts} alt="l_derevo_ts"/></div>,
   <div className="animated-item r_ruini_ts"><img src={r_ruini_ts} alt="r_ruini_ts"/></div>,
   <div className="animated-item r_snaryd2_ts"><img src={r_snaryd2_ts} alt="r_snaryd2_ts"/></div>,
   <div className="animated-item r_dom_ts"><img src={r_dom_ts} alt="r_dom_ts"/></div>,
   <div className="animated-item l_stalinka_ts"><img src={l_stalinka_ts} alt="l_stalinka_ts"/></div>,
   <div className="animated-item zenly_ts"><img src={zenly_ts} alt="zenly_ts"/></div>,
   <div className="animated-item bg_ts"></div>,

];