import React from 'react';

import r_lampa_60 from './img/1_r_lampa_64.74_0.png';
import r_kreslo_60 from './img/2_r_kreslo_88.33_80.28.png';
import r_ctolik_06 from './img/3_r_ctolik_79.53_73.43.png';
import r_rastenia_60 from './img/4_r_rastenia_87.97_45.09.png';
import l_chashka_60 from './img/5_l_chashka_20.63_93.98.png';
import l_vinil_60 from './img/6_l_vinil_25.99_90.28.png';
import l_komod_60 from './img/7_l_komod_2.76_53.7.png';
import potolok_60 from './img/8_potolok_10.63_0.png';
import pol_60 from './img/9_pol_0_86.94.png';
import l_stena_60 from './img/10_l_stena_0_0.png';
import r_stena_60 from './img/11_r_stena_80.57_0.png';

export default [
    <div className="animated-item r_lampa_60"><img src={r_lampa_60} alt="r_lampa_60"/></div>,
    <div className="animated-item r_kreslo_60"><img src={r_kreslo_60} alt="r_kreslo_60"/></div>,
    <div className="animated-item r_ctolik_06"><img src={r_ctolik_06} alt="r_ctolik_06"/></div>,
    <div className="animated-item r_rastenia_60"><img src={r_rastenia_60} alt="r_rastenia_60"/></div>,
    <div className="animated-item l_chashka_60"><img src={l_chashka_60} alt="l_chashka_60"/></div>,
    <div className="animated-item l_vinil_60"><img src={l_vinil_60} alt="l_vinil_60"/></div>,
    <div className="animated-item l_komod_60"><img src={l_komod_60} alt="l_komod_60"/></div>,
    <div className="animated-item potolok_60 "><img src={potolok_60 } alt="potolok_60 "/></div>,
    <div className="animated-item pol_60"><img src={pol_60} alt="pol_60"/></div>,
    <div className="animated-item l_stena_60 "><img src={l_stena_60 } alt="l_stena_60"/></div>,
    <div className="animated-item r_stena_60"><img src={r_stena_60} alt="r_stena_60"/></div>,
    <div className="animated-item bg_60"></div>,
]