/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react"

import Start from "./Start"
import { mobileAndTabletCheck } from "./deviceChecker"

import arrow from "./img/arrow.png"
import Languages from "./Languages"
import Card from "./Card"

const Slide = ({ id, nextSlide, applyLanguage, items, texts, displayInfo }) => {
  const slideRef = useRef(null)
  const [side, setSide] = useState(false)
  const [isMobile, seIsMobile] = useState(false)
  const [slideStyles, setSlideStyles] = useState({})

  const [picsIdx, setPicsIdx] = useState(0)

  const getById = (id, prop) => items.find((item) => item.id === id)[prop]

  const getTextById = (id) => {
    const t = texts.find((item) => item.id === id)

    if (id === "kadyrova") {
      return picsIdx === 0
        ? texts.find((item) => item.id === "khomenko")["text"]
        : texts.find((item) => item.id === "kadyrova")["text"]
    }

    if (id === "rep") {
      return picsIdx === 0
        ? texts.find((item) => item.id === "rep")["text"]
        : texts.find((item) => item.id === "kadan")["text"]
    }

    if (id === "karabynovych") {
      return picsIdx === 0
        ? texts.find((item) => item.id === "karabynovych")["text"]
        : texts.find((item) => item.id === "r")["text"]
    }

    return t ? t["text"] : ""
  }

  const chooseLanguage = (lang) => {
    applyLanguage(lang)
    nextSlide()
  }

  const onScreenScroll = (e) => {
    if (!document.querySelector(".back-side")) {
      return
    }

    document.querySelector(".back-side").scrollTop = e.target.scrollTop
  }

  const positionateRight = (item) => {
    const width = document.querySelector("#root").clientWidth
    const leftVal = window
      .getComputedStyle(item)
      .getPropertyValue("left")
      .replace("px", "")

    if (leftVal && leftVal > width / 2) {
      const percents = (width - leftVal) / 100
      item.style.left = `unset`
      const rightPosition =
        (width / 100) * percents - 10 > 0 ? (width / 100) * percents - 10 : 0
      item.style.right = `${rightPosition}%`
    }
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      document.querySelectorAll(".animated-item").forEach((item) => {
        if (item.firstChild) {
          if (window.innerHeight > 1080) {
            item.style.height = `${
              (item.firstChild?.naturalHeight / 100) * 10.8
            }%`
            return
          }
        }
      })
    })

    const bg = 1080 / 100

    document.querySelectorAll(".animated-item").forEach((item) => {
      if (item.firstChild) {
        if (mobileAndTabletCheck()) {
          positionateRight(item)
        }

        item.firstChild.addEventListener("load", () => {
          //if(window.innerHeight > 1080){
          item.style.height = `${(item.firstChild?.naturalHeight / 100) * bg}%`
          //}

          item.firstChild.style.display = "block"
          item.firstChild.style.height = "100%"
        })
      }
    })
  }, [])

  //const scrollScreen = <div onScroll={(e) => onScreenScroll(e)} className='scroll-wrapper'>
  //                        <div
  //                          className="scrollScreen"
  //                          style={{ width: "100%", height: "1000%" }}
  //                        ></div>
  //                      </div>

  const getPics = (next, picsIdx) => (
    <img
      id="pic-toggle"
      src={next}
      style={{
        position: "absolute",
        right: "8%",
        top: "50%",
        height: "100px",
        zIndex: 100,
        cursor: "pointer",
      }}
      onClick={() => setPicsIdx(picsIdx === 0 ? 1 : 0)}
    />
  )

  return (
    <>
      {id === "start" && <Start startTest={nextSlide} imgs={getById("start", "animate").map((item) => item)}/>}
      {id === "lang" && <Languages chooseLanguage={chooseLanguage} />}
      {id !== "start" && id !== "lang" && (
        <div key={id} className="test-slide" ref={slideRef}>
          {/*{scrollScreen}*/}
          {getById(id, "animate").map((item) => item)}
          {displayInfo && (
            <Card
              front={getById(id, "pics")[picsIdx].front}
              back={getById(id, "pics")[picsIdx].back}
              text={getTextById(id)}
              id={id}
            />
          )}
          {getById(id, "pics").length > 1 && getPics(arrow, picsIdx)}
        </div>
      )}
    </>
  )
}

export default Slide
