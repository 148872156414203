import React from 'react';

import r_cashka_con from './img/1_r_cashka_78.18_90.46.png';
import r_malbeert_con from './img/2_r_malbeert_80.99_78.8.png';
import r_lampa_con from './img/3_r_lampa_70.73_0.png';
import l_stul_con from './img/4_l_stul_10.47_84.63.png';
import l_caynik_con from './img/5_l_caynik_4.53_72.69.png';
import l_plita_con from './img/6_l_plita_1.46_77.96.png';
import l_stakan_con from './img/7_l_stakan_19.43_90.19.png';
import lampa_con from './img/8_lampa_47.66_0.png';
import l_lampa_con from './img/9_l_lampa_23.85_0.png';
import pol_con from './img/10_pol_0_91.67.png';
import r_woll_con from './img/11_r_woll_80.21_0.png';
import l_woll_con from './img/12_l_woll_0_0.png';
import potolok_con from './img/13_potolok_5.36_0.png';

export default [
    <div className="animated-item r_cashka_con"><img src={r_cashka_con} alt="r_cashka_con"/></div>,
    <div className="animated-item r_malbeert_con"><img src={r_malbeert_con} alt="r_malbeert_con"/></div>,
    <div className="animated-item r_lampa_con"><img src={r_lampa_con} alt="r_lampa_con"/></div>,
    <div className="animated-item l_stul_con"><img src={l_stul_con} alt="l_stul_con"/></div>,
    <div className="animated-item l_caynik_con"><img src={l_caynik_con} alt="l_caynik_con"/></div>,
    <div className="animated-item l_plita_con"><img src={l_plita_con} alt="l_plita_con"/></div>,
    <div className="animated-item l_stakan_con"><img src={l_stakan_con} alt="l_stakan_con"/></div>,
    <div className="animated-item lampa_con"><img src={lampa_con} alt="lampa_con"/></div>,
    <div className="animated-item l_lampa_con"><img src={l_lampa_con} alt="l_lampa_con"/></div>,
    <div className="animated-item pol_con"><img src={pol_con} alt="pol_con"/></div>,
    <div className="animated-item r_woll_con"><img src={r_woll_con} alt="r_woll_con"/></div>,
    <div className="animated-item l_woll_con"><img src={l_woll_con} alt="l_woll_con"/></div>,
    <div className="animated-item potolok_con"><img src={potolok_con} alt="potolok_con"/></div>,
    <div className="animated-item bg_con"></div>,
];