import React from "react"

import OutsideClickHandler from "react-outside-click-handler"

import burger from "./img/burger.svg"

import about from "./img/about.png"
import artists from "./img/artists.png"
import concept from "./img/concept.png"
import logos from "./img/logos.png"

import enabout from "./img/enabout.png"
import enartists from "./img/enartists.png"
import enconcept from "./img/enconcept.png"

import "./App.css"

const uaabout = (
  <>
    <img src={concept} style={{ width: "100%" }} />
    <div>
      <div>
        Історія мистецтва України, як і мистецтва інших країн, пов'язана з
        історією формування держави. Але формування українського мистецтва також
        пов'язане з геополітикою та має разючі регіональні особливості. Досі
        точаться дискусії щодо того, який рік можна вважати початком сучасного
        мистецтва в Україні. Зіткнувшись з неможливістю лінійного сприйняття
        історії українського мистецтва, авторки гри Fantastic Adventure through
        Art History of Ukraine пропонують гравцям самим створити альтернативну
        історію українського мистецтва шляхом складання її з реальних художніх
        явищ та окремих практик, що виходять з історичних обставин, в яких
        розвивалося мистецтво на території України з 1920 до 2020 рр.
      </div>
      <div>
        Кураторська добірка художників, представлених у грі, базується на
        тематичному та історичному принципах. Вибрані українські художники за
        останні 100 років реагували на суспільні процеси або конструювали
        альтернативні, фантастичні, спекулятивні реальності, щоб цих процесів
        уникнути.
      </div>
      <div>
        Історію мистецтва важко категоризувати та неможливо описати вичерпно,
        тому в цій грі бракує багатьох художників, які вплинули на розвиток
        українського мистецтва не менш тих, які представлені. Такий формат
        виставки-гри покликаний сформувати спекулятивну мистецтвознавчу
        реальність: середовища для альтернативного сприйняття та дослідження
        історії українського мистецтва.
      </div>
    </div>
    <img src={artists} style={{ width: "100%" }} />
    <div>
      <div>
        <a target="_blank" href="http://styleinsider.com.ua/2016/08/uvidet-bojchuka-i-ne-sumet-zabyt/">Михайло Бойчук</a> (1882, Романівка, Теребовлянський повіт,
        Королівство Галичини та Володимирії — 1937, Київ) — український художник
        і педагог, засновник національної школи монументального живопису України
        XX століття. Представник Розстріляного відродження. Лідер групи
        «бойчукістів». Навчався у Львові, Відні, Мюнхені, закінчив Краківську
        академію мистецтв. В 1907-1910 р. відвідував академію Поля Рансона у
        Парижі. У 1909 заснував у Парижі майстерню неовізантійського мистецтва,
        яка стала початком його школи. Був одним з професорів-засновників
        Української державної Академії мистецтв (1917). Зазнав політичних
        переслідувань: у 1936 р. арештований і розстріляний разом із учнями у
        Києві. Більшість творів, ансамблі монументального живопису зокрема, були
        знищені радянською владою.
      </div>
      <div>
        <a target="_blank" href="https://amnesia.in.ua/avant-garde-ukraine">Олександра Екстер</a> (1882, Білосток, Польща — 1949, Фонтене-о-Роз,
        Франція) — українська художниця, сценографка, дизайнерка, лідерка
        українського авангарду, одна з основоположниця стилю арт-деко. Вчилася в
        Київському художньому училищі, пізніше — в академії Гран Шомьєр у Карло
        Дельваля в Парижі (1908). Брала участь у виставках футуристів та була
        членкинею художніх груп «Спілка молоді», «Бубновий валет» (1910).
        Заснувала київську школу кубофутуристичної та конструктивістської
        сценографії. У 1915 році увійшла до групи художників-авангардистів
        «Супремус» К. Малевича. У 1924-1925 роках брала участь в оформленні
        Радянського павільйону на XIV Венеційській Бієнале
      </div>
      <div>
        <a target="_blank" href="https://uk.wikipedia.org/wiki/%D0%93%D1%80%D0%B8%D0%B3%D0%BE%D1%80%27%D1%94%D0%B2_%D0%A1%D0%B5%D1%80%D0%B3%D1%96%D0%B9_%D0%9E%D0%BB%D0%B5%D0%BA%D1%81%D1%96%D0%B9%D0%BE%D0%B2%D0%B8%D1%87">Сергій Григор’єв</a> (1910, Луганськ — 1988, Київ) — український
        радянський живописець-соцреаліст, плакатист, графік та педагог. З 1926
        року відвідував ВХУТЕМАС у Москві; у 1928–1932 роках навчався в
        Київському художньому інституті. З 1934 року — доцент кафедри рисунку
        Київського художнього інституту. Учасник німецько-радянської війні. З
        1950 року — керівник майстерні жанрового живопису Київського художнього
        інституту. Народний художник СРСР, лауреат двох Сталінських премій
        другого ступеня. У 1951–1955 роках — ректор Київського художнього
        інституту.
      </div>
      <div>
        <a target="_blank" href="https://uk.wikipedia.org/wiki/%D0%93%D0%BE%D1%80%D1%81%D1%8C%D0%BA%D0%B0_%D0%90%D0%BB%D0%BB%D0%B0_%D0%9E%D0%BB%D0%B5%D0%BA%D1%81%D0%B0%D0%BD%D0%B4%D1%80%D1%96%D0%B2%D0%BD%D0%B0">Алла Горська</a> (1929, Ялта — 1970, Васильків) — українська
        художниця-шістдесятниця, дисидентка, відома діячка правозахисного руху
        1960-х років в Україні. Разом із чоловіком Віктором Зарецьким та
        художниками Г. Синицею, Г. Марченком, Б. Плаксієм, Г. Зубченко створили
        низку монументальних робіт у Донецьку, Києві, Краснодоні (зокрема у
        1968-1970 рр. панно у меморіальному комплексі «Молода гвардія»). За
        участь в акціях протесту в 1965-1968 роках Горська була виключена зі
        Спілки художників і зазнала переслідувань з боку радянських органів
        безпеки. Трагічно загинула 28 листопада 1970 року за нез'ясованих
        обставин у місті Василькові.
      </div>
      <div>
        <a target="_blank" href="https://birdinflight.com/ru/pochemu_eto_shedevr/20170720-fripulya.html">Федір Тетянич або Фрипулья</a> (1942, с. Княжичі Київської обл. —
        2007, Київ) — український художник, монументаліст, живописець, графік,
        скульптор, перформер. Після закінчення художнього інституту працював на
        Київському комбінаті монументально-декоративного мистецтва. Був одним із
        піонерів перформансу та гепенінгу на території радянського союзу,
        яскравим представником андеграунду. Широко використовував як матеріал
        для робіт виробничі ті споживацькі відходи, що було частиною його
        філософсько-естетичної системи вдосконалення простору через повторне
        використання вживаних речей.
      </div>
      <div>
        <a target="_blank" href="savadov.com">Арсен Савадов</a> (нар. 1962, Київ) — український художник. У 1988 р.
        закінчив Київський державний художній інститут, факультет
        монументального живопису. Учасник “Першого Українського проекту” на 49-й
        Венеційській бієнале сучасного мистецтва (2001). У 1982-1996 роках
        працював у колаборації з Георгієм Сенченком. На виставці «Молодість
        країни» (Москва, 1987) показав створену у співпраці з Георгієм Сенченком
        роботу «Печаль Клеопатри», що стала передвісником української Нової
        хвилі. Входив до кола художників «Паризької комуни».
      </div>
      <div>
        <a target="_blank" href="http://pinchukartcentre.org/files/2020/parcommune.pdf">Дмитро Кавсан</a> (нар. 1964, Київ) — український художник. Закінчив
        майстерню монументального живопису Київського державного художнього
        інституту у 1990 році. Входив до кола художників «Паризької комуни».
        1992-1994 — був стипендіатом IFA (Німеччина) і KulturKontakt (Австрія),
        учасник Міжнародного фестивалю сучасного мистецтва в Авіньйоні (1991).
      </div>
      <div>
        <a target="_blank" href="http://www.korydor.in.ua/ua/glossary/doslidnytska-platforma-transavangard.html">Олег Тістол</a> (нар. 1960, смт Врадіївка, Миколаївська обл.) —
        український художник. Закінчив Львівський державний інститут
        декоративно-прикладного мистецтва. Разом із Костянтином (Вінні) Реуновим
        проголосив програму «Вольова грань національного постеклектизму» (1987).
        Мешкав та працював у сквотах «Фурманний провулок» та «Трьохпрудний
        провулок» у Москві (1989–1992).Співпрацював з Миколою Маценком у
        колективі Нацпром (1992-2005). Учасник 22-ї Бієнале у Сан-Пауло (1994).
        Учасник “Першого Українського проекту” на 49-й Венеційській бієнале
        сучасного мистецтва (2001).
      </div>
      <div>
        <a target="_blank" href="http://pinchukartcentre.org/files/2020/womenartists_ua.pdf">Влада Ралко</a> (нар. 1969, Київ) — українська художниця. Закінчила
        Українську академію мистецтв та архітектури, відділення станкового
        живопису. Членкиня Національної спілки художників України (з 1994).
        Володарка премії Всеукраїнської трієнале живопису (2001) та стипендії
        CCN Graz (2007). Лауреатка Премії Women In Arts (2019).
      </div>
      <div>
        <a target="_blank" href="https://www.lesiakhomenko.com/">Леся Хоменко</a> (нар. 1980, Київ) — українська художниця. Закінчила
        Національну академію образотворчого мистецтва та архітектури України.
        Співзасновниця та учасниця групи Р.Е.П., кураторського об’єднання
        «Худрада». Номінантка премії PinchukArtCentre в (2009, 2011, 2013 рр.).
        Номінантка премії ім. Казимира Малевича (2012, 2016 рр.). З 2015 року
        викладає курс сучасного мистецтва в Kyiv Media Art Academy.
      </div>
      <div>
        <a target="_blank" href="https://www.kadyrova.com/">Жанна Кадирова</a> (нар. 1980, м. Бровари Київської обл.) —
        українська художниця, скульпторка. Учасниця групи Р.Е.П. і музичного
        перформанс-гурту «Penoplast». Володарка Премії імені Казимира Малевича,
        Премії Сергія Курьохіна, Головної премії PinchukArtCentre (2013). Роботи
        художниці були представлені на 55-й, 56-й та 58-й Венеційській бієнале,
        у Palais de Tokyo у Парижі та Московській бієнале (2013), на арт-ярмарці
        Pulse, Маямі (2019).
      </div>
      <div>
        <a target="_blank" href="https://birdinflight.com/ru/portret/20180312-mikhail-koptev-interview.html">Михайло Коптєв</a> (Луганськ) — український художник та
        треш-дизайнер, засновник та художній керівник квір-театру еротичної
        провокаційною моди «Орхідея», створеного у 1993 р. в Луганську. Театр
        проводив гастролі в Іспанії та Австрії. Про Михайла Коптєва і «Орхідею»
        писали VICE, Dazed & Confused Magazine, Dry Magazine, The Guardian і The
        Calvert Journal. Через війну переїхав до Київської області.
      </div>
      <div>
        <a target="_blank" href="http://nikitakadan.com/reprep/peprep/">Група Р.Е.П (Революційний Експериментальний Простір)</a> — українська
        художня група, заснована у 2004 р. під час Помаранчевої революції в
        Україні. З 2006 р. в групу входять шість митців: Леся Хоменко, Нікіта
        Кадан, Жанна Кадирова, Ксенія Гнилицька, Лада Наконечна та Володимир
        Кузнєцов. В 2008 р. групою було створено об’єднання «Худрада» —
        кураторський та активістський колектив, заснований на принципі
        міждисциплінарної взаємодії.
      </div>
      <div>
        <a target="_blank" href="nikitakadan.com">Нікіта Кадан</a> (нар.1982, Київ) — український художник та куратор.
        Закінчив Національну академію мистецтв та архітектури (2007 р.). Член
        групи художників Р.Е.П. та кураторського об'єднання Худрада. Володар
        Першої премії PinchukArtCentre Prize (2011) та Спеціальної премії Future
        Generation Art Prize (2014).
      </div>
      <div>
        <a target="_blank" href="http://pinchukartcentre.org/files/2020/womenartists_ua.pdf">Ксенія Гнилицька</a> (нар.1984, Київ) — українська художниця.
        Навчалась в Національній академії образотворчого мистецтва і архітектури
        на факультеті живопису (2003 – 2009). Співзасовниця і учасниця груп
        Р.Е.П. і «Худрада». Номінантка Премії PinchukArtCentre 2020.
      </div>
      <div>
        <a target="_blank" href="https://zaborona.com/ru/hudozhnitsa-s-meniayshcheysia-travmoy/">Дарія Кузьмич</a> (нар.1991, Київ) — українська художниця. Закінчила
        факультет живопису Національної академії образотворчого мистецтва та
        архітектури. Стипендіатка магістерської програми Берлінського
        університету мистецтв.
      </div>
      <div>
        <a target="_blank" href="http://www.korydor.in.ua/ua/opinions/ivan-svetlichnyj-projects.html">Іван Світличний</a> (нар.1988, Харків) — український художник,
        співзасновник і куратор віртуального експозиційного середовища –
        Shukhliada. Закінчив Харківську Державну академію дизайну та мистецтв за
        спеціальністю станковий живопис та монументальна скульптура. Засновник
        та куратор мистецької організації 01011101, куратор виставок творчого
        центру ТЕЦ у Харкові. Учасник українського павільйону Венеційської
        бієнале (2017), чотири рази номінований на премію PinchukArtCentre,
        лауреат Премії Малевича (2018).
      </div>
      <div>
        <a target="_blank" href="https://supportyourart.com/columns/rrmethod">Даніїл Ревковський і Андрій Рачинський</a> — творчий дует із Харкова,
        нар. в 1993 р. і в 1990 р. (відповідно). Закінчили Харківську державну
        академію дизайну та мистецтв. У 2010 році створили відомий паблік
        Вконтакті «Пам'ять», з якого розпочалася їх спільна художня практика та
        дослідження теми колективної пам'яті на пострадянському просторі.
        Номінанти Премії PinchukArtCentre 2018, володарі Призу громадськості
        Премії PinchukArtCentre 2020.
      </div>
      <div>
        <a target="_blank" href="karabinovych.com">Ніколай Карабінович</a> (нар. 1988, Одеса) — український художник,
        куратор. Живе і працює в Генті (Бельгія) та Києві. Закінчив Одеський
        національний університет імені І.І. Мечникова, факультет філософії. Був
        учасником IV Одеської бієнале (2015). Двічі лауреат Першої Спеціальної
        премії PinchukArtCentre (2018, 2020). З 2019 навчається в Higher
        Institute for Fine Arts (HISK) в Генті.
      </div>
      <div>
        <a target="_blank" href="https://artslooker.com/artist-kateryna-lisovenko-on-victims-violence-and-art-education/">Катерина Лісовенко</a> (нар.1989, Київ) — українська художниця.
        Закінчила Національну Академію Образотворчого мистецтва і Архітектури,
        факультет монументального живопису. Випускниця курсу "Сучасне мистецтво"
        в Kyiv Academy of Media Arts. У своїй практиці звертається до теми
        насильства, домінування, зокрема ідеологічного.
      </div>
    </div>
    <img src={about} style={{ width: "100%" }} />
    <div className="about">
      <div>Кураторки — Ксенія Малих, Валерія Шиллер.</div>
      <div>Історичні довідки — Костянтин Дорошенко.</div>
      <div>Науковий консультант — Оксана Баршинова.</div>
      <div>Координаторка — Катерина Лазаревич.</div>
      <div>Ілюстратор — Даніїл Ревковський.</div>
      <div>Дизайн і розробка сайту — Photinus studio.</div>
      <div>Переклад на англійську — Дмитро Остроушко.</div>
      <div>Редактура англійських текстів — Клеменс Пул.</div>
      <div>Літературна редактура — Світлана Лібет.</div>
      <div>
        Менеджер проекту від Українського Інституту — Олександр Виноградов.
      </div>
      <div>
        Комунікації — агенція BILESUHE: керівник проєкту — Аліса Фурса;
        веб-дизайнер та моушн-дизайнер — Артем Карпенко;
      </div>
      <div>PPC-менеджер — Дмитро Киселевич.</div>
    </div>
    <div className="project">
      Проєкт реалізується у рамках програми Ukraine Everywhere Українського
      інституту
    </div>
    <img src={logos} style={{ width: "100%" }} />
  </>
)

const enbout = (
  <>
    <img src={enconcept} style={{ width: "100%" }} />
    <div>
      <div>
        <div>
          The history of Ukrainian art, just as that of other countries, is
          connected with the political and social history of the state. However,
          the development of Ukrainian art is also connected with international
          politics, albeit with a striking regional specificity. Considering
          that there is no precise consensus about where to start a history of
          Ukrainian contemporary art, or indeed even about a linear art
          historical perception, the authors of the game Fantastic Adventure
          through the Art History of Ukraine invite the players themselves to
          create an alternative art history. Players can compose these histories
          using real artistic phenomena and notable practices, stemming from the
          historical circumstances that distinguish the development of
          contemporary art in the territory of Ukraine between 1920 and 2020.
        </div>
        <div>
          The Ukrainian artists featured in the game were selected by the
          curators based on thematic and historical principles. The selection
          includes both artists who reacted explicitly to the social processes
          of the last 100 years, and those who sought to construct alternative,
          fantastic, and speculative realities in order to avoid those
          processes.
        </div>
        <div>
          Art history is difficult to characterize and impossible to describe in
          an exhaustive way, and as a result, there are many artists missing
          from Fantastic Adventure through the Art History of Ukraine who have
          impacted the development of Ukrainian contemporary art no less than
          those who feature here. However, it is not the intention of the
          curators to propose that this game is comprehensive.
        </div>
        <div>
          The game-exhibition format is intended to develop a speculative art
          historical reality: an environment for an alternative perception and
          exploration of the history of Ukrainian art.{" "}
        </div>
      </div>
      <img src={enartists} style={{ width: "100%" }} />
      <div>
        <div>
          <a target="_blank" href="https://en.wikipedia.org/wiki/Mykhailo_Boychuk">Mykhailo Boychuk</a> (1882, Romanivka, Terebovlya County, Kingdom
          of Galicia and Volodymyria — 1937, Kyiv) is a Ukrainian artist and
          teacher, founder of the National School of Monumental Painting of
          Ukraine of the XX century. Representative of the Executed Renaissance.
          Leader of the Boychukist group. He studied in Lviv, Vienna, Munich,
          graduated from the Krakow Academy of Arts. In 1907-1910 he attended
          the Paul Ranson Academy in Paris. In 1909 he founded a workshop of
          neo-Byzantine art in Paris, which became the beginning of his school.
          He was one of the founding professors of the Ukrainian State Academy
          of Arts (1917). Boychuk was persecuted politically: in 1936 he was
          arrested and shot along with his students in Kyiv. Most of his works,
          ensembles of monumental painting in particular, were destroyed by the
          Soviet authorities.
        </div>
        <div>
          <a target="_blank" href="http://avantgarde.org.ua/en.php">Oleksandra Ekster / Alexandra Exter</a> (1882, Białystok, Poland —
          1949, Fontenay-au-Rose, France) is a Ukrainian artist, scenographer,
          designer, leader of the Ukrainian avant-garde, one of the founders of
          the Art Deco style. She studied at the Kyiv School of Art, and later
          at the Grand Schomier Academy under Carlo Delval in Paris (1908). She
          took part in futurist exhibitions and was a member of the art groups
          "Youth Union", "Jack of Diamonds" (1910). She founded the Kyiv school
          of cubofuturistic and constructivist scenography. In 1915 she joined
          K. Malevich's group of avant-garde artists "Supremus". In 1924-1925
          she took part in the design of the Soviet pavilion at the XIV Venice
          Biennale.
        </div>
        <div>
          <a target="_blank" href="http://theschoolofkyiv.org/participants/991/serhiy-hryhoriev">Serhiy Hryhoriev</a> (1910, Luhansk — 1988, Kyiv) is a Ukrainian
          Soviet painter-socialist, poster artist, graphic artist and teacher.
          From 1926 he visited VKHUTEMAS in Moscow; in 1928–1932 he studied at
          the Kyiv Art Institute. Since 1934 he has worked as an Associate
          Professor of Drawing, Kyiv Art Institute. A participant in the
          German-Soviet war. Since 1950 he has been the head of the genre
          painting workshop of the Kyiv Art Institute. People's Artist of the
          USSR, winner of two Stalin Prizes of the second degree. In 1951–1955
          he was the rector of the Kyiv Art Institute.
        </div>
        <div>
          <a target="_blank" href="http://archive-uu.com/en/profiles/horska-alla">Alla Horska</a> (1929, Yalta — 1970, Vasylkiv) — Ukrainian artist
          of the sixties, dissident, well-known activist of the human rights
          movement of the 1960s in Ukraine. Together with her husband Viktor
          Zaretsky and artists G. Sinitsa, G. Marchenko, B. Plaksiy, and G.
          Zubchenko, they created a number of monumental works in Donetsk, Kyiv,
          and Krasnodon. For participating in the protests in 1965-1968, Horska
          was expelled from the Union of Artists and persecuted by the Soviet
          security services. She died tragically on November 28, 1970 under
          unknown circumstances in the town of Vasylkiv.
        </div>
        <div>
          <a target="_blank" href="http://archive-uu.com/en/profiles/tetyanych-fedir">Fedir Tetyanych or Fripulya</a>(1942, Knyazhychi village, Kyiv
          region — 2007, Kyiv) — Ukrainian artist, monumentalist, painter,
          graphic artist, sculptor, performer. He was one of the pioneers of
          performance and happenings in the Soviet Union, a prominent
          representative of the underground. He widely used as a material for
          industrial work those consumer wastes that were part of his
          philosophical and aesthetic system of improving space through the
          reuse of second-hand things.
        </div>
        <div>
          <a target="_blank" href="www.savadov.com">Arsen Savadov</a>(born 1962, Kyiv) is a Ukrainian artist. In 1988
          he graduated from the Kyiv State Art Institute, Faculty of Monumental
          Painting. Participant in the "First Ukrainian Project" at the 49th
          Venice Biennale of Contemporary Art (2001). In 1982-1996 he worked in
          collaboration with Georgy Senchenko. At the exhibition "Youth of the
          Country" (Moscow, 1987) he showed the work "Sadness of Cleopatra"
          created in collaboration with Georgy Senchenko, which became a
          harbinger of the Ukrainian New Wave. He was a member of the Paris
          Commune.
        </div>
        <div>
          <a target="_blank" href="http://pinchukartcentre.org/files/2020/parcommune_en.pdf">Dmytro Kavsan</a>(born 1964, Kyiv) is a Ukrainian artist. He
          graduated from the studio of monumental painting of the Kyiv State Art
          Institute in 1990. He was a member of the Paris Commune. 1992-1994 -
          was a fellow of IFA (Germany) and KulturKontakt (Austria), a
          participant in the International Festival of Contemporary Art in
          Avignon (1991).
        </div>
        <div>
          <a target="_blank" href="https://abramovych.art/eng/artists/oleg_tistol">Oleg Tistol</a>(born 1960, Vradiyivka village, Mykolaiv region) is
          a Ukrainian artist. He graduated from the Lviv National Academy of
          Arts. Together with Kostyantyn (Winnie) Reunov proclaimed the program
          "Volitional facet of national post-eclecticism" (1987). Tistol lived
          and worked in the squats "Furmanny Lane" and "Tryokhprudny Lane" in
          Moscow (1989-1992). He collaborated with Mykola Matsenko in the
          Natsprom (1992-2005). Participant of the 22nd Biennale in Sao Paulo
          (1994). Participant in the "First Ukrainian Project" at the 49th
          Venice Biennale of Contemporary Art (2001).
        </div>
        <div>
          <a target="_blank" href="https://artslooker.com/en/vlada-ralko-it-is-important-for-me-to-turn-the-context-into-a-background/">Vlada Ralko</a> (born 1969, Kyiv) is a Ukrainian artist. She
          graduated from the Ukrainian Academy of Arts and Architecture,
          department of Easel Painting. Member of the National Union of Artists
          of Ukraine (since 1994). Winner of the Ukrainian Triennial of Painting
          (2001) and the CCN Graz Scholarship (2007). Winner of the Women In
          Arts Award (2019).
        </div>
        <div>
          <a target="_blank" href="https://www.lesiakhomenko.com">Lesia Khomenko</a> (born 1980, Kyiv) is a Ukrainian artist. She
          graduated from the National Academy of Fine Arts and Architecture.
          Co-founder and member of the group R.E.P., curatorial association
          "Khudrada". Nominee of the PinchukArtCentre Prize (2009, 2011, 2013).
          Nominee of the Kazimir Malevich Prize (2012, 2016). Since 2015
          Khomenko has been teaching a course of contemporary art at the Kyiv
          Academy of Media Arts.
        </div>
        <div>
          <a target="_blank" href="https://www.kadyrova.com">Zhanna Kadyrova</a> (born 1980, Brovary, Kyiv Region) is a
          Ukrainian artist and sculptress. Member of the group R.E.P. and the
          musical performance group Penoplast. Winner of the Kazimir Malevich
          Prize, the Sergey Kuryokhin Prize, the PinchukArtCentre Prize (2013).
          The artist's works were presented at the 55th, 56th and 58th Venice
          Biennale, at the Palais de Tokyo in Paris and the Moscow Biennale
          (2013), at the Pulse Art Fair in Miami (2019).
        </div>
        <div>
          <a target="_blank" href="https://www.dazeddigital.com/fashion/article/29243/1/the-fashion-madman-you-ve-never-heard-of">Mykhailo Koptev</a> (Luhansk) is a Ukrainian artist and thrash
          designer, founder and artistic director of the queer theater of erotic
          provocative fashion "Orchid", created in 1993 in Luhansk. The theater
          has toured in Spain and Austria. VICE, Dazed & Confused Magazine, Dry
          Magazine, The Guardian and The Calvert Journal wrote about Mykhailo
          Koptev. Because of the war he moved to the Kyiv region.
        </div>
        <div>
          <a target="_blank" href="https://www.thegreenbox.net/en/artists/revolutionary-experimental-space">R.E.P. Group (Revolutionary Experimental Space)</a> is a Ukrainian
          art group founded in 2004 during the Orange Revolution in Ukraine.
          Since 2006, the group includes six artists: Lesia Khomenko, Nikita
          Kadan, Zhanna Kadyrova, Ksenia Hnylytska, Lada Nakonechna and
          Volodymyr Kuznetsov. In 2008, the group formed the "Khudrada"
          Association, a curatorial and activist group based on
          interdisciplinary cooperation.
        </div>
        <div>
          <a target="_blank" href="http://nikitakadan.com/">Nikita Kadan</a> (born 1982, Kyiv) is a Ukrainian artist and
          curator. He graduated from National Academy of Fine Art (Kyiv) where
          he studied on department of monumental painting under professor Mykola
          Storozhenko. from the National Academy of Arts and Architecture
          (2007). Member of the art group R.E.P. and the curatorial association
          "Hudrada". Winner of the First PinchukArtCentre Prize (2011), the
          Special Future Generation Art Prize (2014), Kazimir Malevich Prize
          (2016).
        </div>
        <div>
          <a target="_blank" href="http://pinchukartcentre.org/files/2020/womenartists_en.pdf">Ksenia Hnylytska</a> (born 1984, Kyiv) is a Ukrainian artist. She
          graduated of the National Academy of Fine Arts and Architecture, the
          faculty of Painting. Co-founder and member of the groups R.E.P. and
          "Hudrada". Nominee of the PinchukArtCentre Prize 2020.
        </div>
        <div>
          <a target="_blank" href="https://dariiakuzmych.com/">Daria Kuzmych</a> (born 1991, Kyiv) is a Ukrainian artist. She
          graduated from the Faculty of Painting of the National Academy of Fine
          Arts and Architecture. Fellow of the master's program at the Berlin
          University of the Arts.
        </div>
        <div>
          <a target="_blank" href="http://cargocollective.com/mediartists/Ivan-Svitlychnyi">Ivan Svitlychny</a> (born 1988, Kharkiv) is a Ukrainian artist,
          co-founder and curator of a virtual exposition environment —
          Shukhliada. He graduated from the Kharkiv State Academy of Design and
          Fine Arts, the faculty of easel painting and monumental sculpture.
          Founder and curator of the art organization 01011101, curator of the
          creative center of TETS (ТЕЦ) in Kharkiv. Participant of the Ukrainian
          pavilion of the Venice Biennale (2017), four times nominee of the
          PinchukArtCentre Prize, winner of the Malevich Prize (2018).
        </div>
        <div>
          <a target="_blank" href="https://www.e-flux.com/video/337224/andriy-rachinskiy-nbsp-and-nbsp-daniil-revkovskiy-nbsp-labor-safety-in-the-region-of-dnipropetrovsk/">Daniil Revkovskiy and Andriy Rachinskiy</a>are an artistic duo
          from Kharkiv, b. in 1993 and in 1990 (respectively). They graduated
          from the Kharkiv State Academy of Design and Fine Arts. In 2010, they
          created the well-known blog "Memory", which began their joint artistic
          practice and research on the topic of collective memory in the
          post-Soviet space. Nominees for the PinchukArtCentre Prize 2018,
          winners of the PinchukArtCentre Prize 2020 Public Award.
        </div>
        <div>
          <a target="_blank" href="http://karabinovych.com/">Nikolay Karabinovych</a>(born 1988, Odesa) is a Ukrainian artist
          and curator. Lives and works in Ghent (Belgium) and Kyiv. He graduated
          from Odesa Mechnikov National University, Faculty of Philosophy.
          Karabinovych was a participant of the IV Odessa Biennale (2015). Twice
          winner of the First Special Award of PinchukArtCentre Prize (2018,
          2020). Since 2019 he has been studying at the Higher Institute for
          Fine Arts (HISK) in Ghent.
        </div>
        <div>
          <a target="_blank" href="https://artslooker.com/en/artist-kateryna-lisovenko-on-victims-violence-and-arts-education/">Kateryna Lysovenko</a> (born 1989, Kyiv) is a Ukrainian artist. She
          graduated from the National Academy of Fine Arts and Architecture,
          Faculty of Monumental Painting; the course "Contemporary Art" at the
          Kyiv Academy of Media Arts. In her practice she addresses the topic of
          violence and ideological domination.
        </div>
      </div>
    </div>
    <img src={enabout} style={{ width: "100%" }} />
    <div className="about">
      <div>Curators — Ksenia Malykh, Valeria Schiller.</div>
      <div>Historical background — Kostiantyn Doroshenko.</div>
      <div>Scientific consultant — Oksana Barshinova.</div>
      <div>Coordinator — Katerina Lazarevich.</div>
      <div>Illustrator — Daniil Revkovskiy.</div>
      <div>Website design and development — Photinus studio.</div>
      <div>Translated into English by Dmytro Ostroushko.</div>
      <div>
        Edited by Clemens Poole. Literary editing (UKR) — Svitlana Libet.
      </div>
      <div>
        Project manager on behalf of the Ukrainian Institute — Oleksandr
        Vynogradov.
      </div>
      <div>
        Communications — BILESUHE Agency: project manager Alice Fursa; web
        designer and motion designer — Artem Karpenko;
      </div>
      <div> PPC manager — Dmytro Kyselevych.</div>
    </div>

    <div className="project">
      Commissioned by the Ukrainian Institute for the Ukraine Everywhere
      programme.
    </div>

    <img src={logos} style={{ width: "100%" }} />
  </>
)

const About = ({ data, outClick, start, finish, style, lang}) => {
  return (
    <>
      <OutsideClickHandler onOutsideClick={outClick}>
        <div
          style={{
            position: "absolute",
            top: "0",
            //height: "97%",
            overflow: "scroll",
            zIndex: 1000,
            //padding: "35px",
            //right: "400px",
          }}
          className="screen screen-right"
          id="menu"
        >
          {lang === "UA" ? uaabout : enbout }
        </div>
      </OutsideClickHandler>
      <img
        src={burger}
        className="burgerMenu"
        style={{ left: style.left, cursor: "pointer" }}
        onClick={() => document.getElementById("menu").classList.add("visible")}
      />
    </>
  )
}

export default About
