import React from "react";

const a = {
  ua: [
    {
      text: `Михайло Бойчук (1882, Романівка, Теребовлянський повіт, Королівство Галичини та Володимирії –— 1937, Київ) — український художник і педагог, засновник національної школи монументального живопису України XX століття. Представник Розстріляного відродження. Лідер групи «бойчукістів». Навчався у Львові, Відні, Мюнхені, закінчив Краківську академію мистецтв. В 1907-1910 р. відвідував академію Поля Рансона у Парижі. У 1909 заснував у Парижі майстерню неовізантійського мистецтва, яка стала початком його школи. Був одним з професорів-засновників Української державної Академії мистецтв (1917). Зазнав політичних переслідувань: у 1936 р. арештований і розстріляний разом із учнями у Києві. Більшість творів, ансамблі монументального живопису зокрема, були знищені радянською владою.`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="http://styleinsider.com.ua/2016/08/uvidet-bojchuka-i-ne-sumet-zabyt/"
        >
          Носко Е. Увидеть Бойчука и не суметь забыть [Електронний ресурс] /
          Екатерина Носко // STYLEINSIDER. – 2016.
        </a>
      ),
    },
    {
      text: `Олександра Екстер (1882, Білосток, Польща — 1949, Фонтене-о-Роз, Франція) — українська художниця, сценографка, дизайнерка, лідерка українського авангарду, одна з основоположниця стилю арт-деко. Вчилася в Київському художньому училищі, пізніше — в академії Гран Шомьєр у Карло Дельваля в Парижі (1908). Брала участь у виставках футуристів та була членкинею художніх груп «Спілка молоді», «Бубновий валет» (1910). Заснувала київську школу кубофутуристичної та конструктивістської сценографії. У 1915 році увійшла до групи художників-авангардистів «Супремус» К. Малевича. У 1924-1925 роках брала участь в оформленні Радянського павільйону на XIV Венеційській Бієнале.`,
      link: (
        <a target="_blank" tabindex="-1"  href="https://amnesia.in.ua/avant-garde-ukraine">
          Малеонюк К. Дикуни і декаденти. Авангардні виставки в дореволюційній
          Україні [Електронний ресурс] / Костянтин Малеонюк // Амнезія. – 2019.
        </a>
      ),
    },
    {
      text: `Сергій Григор’єв (1910, Луганськ — 1988, Київ) — український радянський живописець-соцреаліст, плакатист, графік та педагог. З 1926 року відвідував ВХУТЕМАС у Москві; у 1928–1932 роках навчався в Київському художньому інституті. З 1934 року — доцент кафедри рисунку Київського художнього інституту. Учасник німецько-радянської війні. З 1950 року — керівник майстерні жанрового живопису Київського художнього інституту. Народний художник СРСР, лауреат двох Сталінських премій другого ступеня. У 1951–1955 роках — ректор Київського художнього інституту.
			Алла Горська (1929, Ялта — 1970, Васильків) — українська художниця-шістдесятниця, дисидентка, відома діячка правозахисного руху 1960-х років в Україні. Разом із чоловіком Віктором Зарецьким та художниками Г. Синицею, Г. Марченком, Б. Плаксієм, Г. Зубченко створили низку монументальних робіт у Донецьку, Києві, Краснодоні (зокрема у 1968-1970 рр. панно у меморіальному комплексі «Молода гвардія»). За участь в акціях протесту в 1965-1968 роках Горська була виключена зі Спілки художників і зазнала переслідувань з боку радянських органів безпеки. Трагічно загинула 28 листопада 1970 року за нез'ясованих обставин у місті Василькові.
			Балашова О. Искусство украинских шестидесятников / О. Балашова, Л. Герман., 2017. – 384 с.
			Федір Тетянич або Фрипулья (1942, с. Княжичі Київської обл. — 2007, Київ) — український художник, монументаліст, живописець, графік, скульптор, перформер. Після закінчення художнього інституту працював на Київському комбінаті монументально-декоративного мистецтва. Був одним із піонерів перформансу та гепенінгу на території радянського союзу, яскравим представником андеграунду. Широко використовував як матеріал для робіт виробничі ті споживацькі відходи, що було частиною його філософсько-естетичної системи вдосконалення простору через повторне використання вживаних речей.`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://birdinflight.com/ru/pochemu_eto_shedevr/20170720-fripulya.html"
        >
          Жмурко Т. На партсобрание в костюме из фольги: Жизнь-перформанс Федора
          Тетяныча [Електронний ресурс] / Таня Жмурко // Bird in Flight. – 2017.{" "}
        </a>
      ),
    },
    {
      text: `Леонід Войцехов (1955, Одеса — 2018, Одеса) — український художник-концептуаліст, акціоніст, графік, автор перфомансів, інсталяцій. Лідер творчої групи «Апт-Арт» (з 1982). Мешкав та працював у сквотах «Фурманний провулок» та «Трьохпрудний провулок» у Москві.`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://archive.pinchukartcentre.org/reviews/leonid-vojtsehov-rezyume-doslidnik-tet"
        >
          Леонід Войцехов. Резюме. Дослідниця – Тетяна Жмурко{" "}
        </a>
      ),
    },
    {
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="http://msio.com.ua/ru/component/content/article/395"
        >
          Зал 8. Концептуализм в Одессе.
        </a>
      ),
    },
    {
      text: `Арсен Савадов (нар. 1962, Київ) — український художник. У 1988 р. закінчив Київський державний художній інститут, факультет монументального живопису. Учасник “Першого Українського проекту” на 49-й Венеційській бієнале сучасного мистецтва (2001). У 1982-1996 роках працював у колаборації з Георгієм Сенченком. На виставці «Молодість країни» (Москва, 1987) показав створену у співпраці з Георгієм Сенченком роботу «Печаль Клеопатри», що стала передвісником української Нової хвилі. Входив до кола художників «Паризької комуни».`,
      link: (
        <a target="_blank" tabindex="-1"  href="http://savadov.com/">
          savadov.com
        </a>
      ),
    },
    {
      text: `Дмитро Кавсан (нар. 1964, Київ) — український художник. Закінчив майстерню монументального живопису Київського державного художнього інституту у 1990 році. Входив до кола художників «Паризької комуни». 1992-1994 — був стипендіатом IFA (Німеччина) і KulturKontakt (Австрія), учасник Міжнародного фестивалю сучасного мистецтва в Авіньйоні (1991).`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="http://pinchukartcentre.org/files/2020/parcommune.pdf"
        >
          Паркомуна. Місце. Спільнота. Явище. — К. : Publish Pro, 2018. — 208 c.
        </a>
      ),
    },
    {
      text: `Олег Тістол (нар. 1960, смт Врадіївка, Миколаївська обл.) — український художник. Закінчив Львівський державний інститут декоративно-прикладного мистецтва. Разом із Костянтином (Вінні) Реуновим проголосив програму «Вольова грань національного постеклектизму» (1987). Мешкав та працював у сквотах «Фурманний провулок» та «Трьохпрудний провулок» у Москві (1989–1992).Співпрацював з Миколою Маценком у колективі Нацпром (1992-2005). Учасник 22-ї Бієнале у Сан-Пауло (1994). Учасник “Першого Українського проекту” на 49-й Венеційській бієнале сучасного мистецтва (2001). `,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="http://www.korydor.in.ua/ua/glossary/doslidnytska-platforma-transavangard.html"
        >
          Утєвська К. ТРАНСАВАНГАРД [Електронний ресурс] / Ксенія Утєвська //
          Korydor. – 2016.
        </a>
      ),
    },
    {
      text: `Алевтина Кахідзе (нар. 1973, Жданівка Донецької обл.) — українська художниця. У 2002 році стала переможницею конкурсу серед молодих художників і кураторів Центру сучасного мистецтва при НаУКМА, а в 2008 році – лауреатом Премії Малевича. Стипендіатка Академії Яна ван Ейка (Маастріхт, Нідерланди), резидентка Open House Iaspis (Стокгольм), Центру сучасного мистецтва «Уяздовський замок» (Варшава). Алевтина Кахідзе (нар. 1973, Жданівка Донецької обл.) — українська художниця. У 2002 році стала переможницею конкурсу серед молодих художників і кураторів Центру сучасного мистецтва при НаУКМА, а в 2008 році – лауреатом Премії Малевича. Стипендіатка Академії Яна ван Ейка (Маастріхт, Нідерланди), резидентка Open House Iaspis (Стокгольм), Центру сучасного мистецтва «Уяздовський замок» (Варшава).
			Співзасновниця приватної резиденції для іноземних художників у селі Музичі та онлайн видання про мистецтво kram.in.ua.`,
      link: (
        <a target="_blank" tabindex="-1"  href="http://alevtinakakhidze.com/">
          Алевтина Кахідзе
        </a>
      ),
    },
    {
      text: `Влада Ралко (нар. 1969, Київ) — українська художниця. Закінчила Українську академію мистецтв та архітектури, відділення станкового живопису. Членкиня Національної спілки художників України (з 1994). Володарка премії Всеукраїнської трієнале живопису (2001) та стипендії CCN Graz (2007). Лауреатка Премії Women In Arts (2019). 
			Ралко В. «Привид свободи» Своє, моє, чуже, спільне, вільне / Влада Ралко // Чому в українському мистецтві є великі художниці / Влада Ралко. – Київ: Publish Pro, 2019. – С. 78–83. 
			Леся Хоменко (нар. 1980, Київ) — українська художниця. Закінчила Національну академію образотворчого мистецтва та архітектури України. Співзасновниця та учасниця групи Р.Е.П., кураторського об’єднання «Худрада». Номінантка премії PinchukArtCentre в (2009, 2011, 2013 рр.). Номінантка премії ім. Казимира Малевича (2012, 2016 рр.). З 2015 року викладає курс сучасного мистецтва в Kyiv Media Art Academy. 
			Годенко О. П’ять імен українських художниць у сучасному мистецтві початку ХХІ ст.: Ксенія Гнилицька, Анна Звягінцева, Жанна Кадирова, Лада Наконечна, Леся Хоменко / Олена Годенко // Чому в українському мистецтві є великі художниці / Олена Годенко. – Київ: Publish Pro, 2019. – С. 84–103.`,
      link: (
        <a target="_blank" tabindex="-1"  href="http://lesiakhomenko.com">
          lesiakhomenko.com
        </a>
      ),
    },
    {
      text: `Жанна Кадирова (нар. 1980, м. Бровари Київської обл.) — українська художниця, скульпторка. Учасниця групи Р.Е.П. і музичного перформанс-гурту «Penoplast». Володарка Премії імені Казимира Малевича, Премії Сергія Курьохіна, Головної премії PinchukArtCentre (2013). Роботи художниці були представлені на 55-й, 56-й та 58-й Венеційській бієнале, у Palais de Tokyo у Парижі та Московській бієнале (2013), на арт-ярмарці Pulse, Маямі (2019).`,
      link: (
        <a target="_blank" tabindex="-1"  href="http://kadyrova.com">
          kadyrova.com
        </a>
      ),
    },
    {
      text: `Михайло Коптєв (Луганськ) — український художник та треш-дизайнер, засновник та художній керівник квір-театру еротичної провокаційною моди «Орхідея», створеного у 1993 р. в Луганську. Театр проводив гастролі в Іспанії та Австрії. Про Михайла Коптєва і «Орхідею» писали VICE, Dazed & Confused Magazine, Dry Magazine, The Guardian і The Calvert Journal. Через війну переїхав до Київської області.`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://birdinflight.com/ru/portret/20180312-mikhail-koptev-interview.html."
        >
          Цыба А. Михаил Коптев: «Некоторым было стыдно сесть в зал, и они
          смотрели мое шоу, прячась за кулисами» [Електронний ресурс] / Анна
          Цыба // BIRD IN FLIGHT. – 2018.{" "}
        </a>
      ),
    },
    {
      text: `Група Р.Е.П (Революційний Експериментальний Простір) — українська художня група, заснована у 2004 р. під час Помаранчевої революції в Україні. У свої практиці використовують відео, інсталяції та перформанси, досліджують сучасний український соціо-культурний і політичний контексти. З 2006 р. в групу входять шість митців: Леся Хоменко, Нікіта Кадан, Жанна Кадирова, Ксенія Гнилицька, Лада Наконечна та Володимир Кузнєцов. В 2008 р. групою було створено об’єднання «Худрада» — кураторський та активістський колектив, заснований на принципі міждисциплінарної взаємодії. 
			Р.Е.П. Революційний експериментальний простір. — Берлін: The Green Box Kunst Editionen, 2015`,
      link: (
        <a target="_blank" tabindex="-1"  href="http://www.volodymyrkuznetsov.com/">
          Володимир Кузнєцов
        </a>
      ),
    },
    {
      text: `Нікіта Кадан (нар.1982, Київ) — український художник та куратор. Закінчив Національну академію мистецтв та архітектури (2007 р.). Член групи художників Р.Е.П. та кураторського об'єднання Худрада. Володар Першої премії PinchukArtCentre Prize (2011), Спеціальної премії Future Generation Art Prize (2014), Премії Казимира Малевича (2016).`,
      link: (
        <a target="_blank" tabindex="-1"  href="http://nikitakadan.com">
          nikitakadan.com
        </a>
      ),
    },
    {
      text: `Ксенія Гнилицька (нар.1984, Київ) — українська художниця. Навчалась в Національній академії образотворчого мистецтва і архітектури на факультеті живопису (2003 – 2009). Співзасовниця і учасниця груп Р.Е.П. і «Худрада». Номінантка Премії PinchukArtCentre 2020.
			Дарія Кузьмич (нар.1991, Київ) — українська художниця. Закінчила факультет живопису Національної академії образотворчого мистецтва та архітектури. Стипендіатка магістерської програми Берлінського університету мистецтв. `,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://zaborona.com/ru/hudozhnitsa-s-meniayshcheysia-travmoy/"
        >
          Сергацкова Е. Принять свое тело. История художницы, которая 10 лет
          живет с постоянно меняющейся травмой [Електронний ресурс] / Екатерина
          Сергацкова // Заборона. – 2020.{" "}
        </a>
      ),
    },
    {
      text: `Іван Світличний (нар.1988, Харків) — український художник, співзасновник і куратор віртуального експозиційного середовища – Shukhliada. Закінчив Харківську Державну академію дизайну та мистецтв за спеціальністю станковий живопис та монументальна скульптура. Засновник та куратор мистецької організації 01011101, куратор виставок творчого центру ТЕЦ у Харкові. Учасник українського павільйону Венеційської бієнале (2017), чотири рази номінований на премію PinchukArtCentre, лауреат Премії Малевича (2018).`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="http://www.korydor.in.ua/ua/opinions/ivan-svetlichnyj-projects.html"
        >
          Глеба Г. ИВАН СВЕТЛИЧНЫЙ: «Я ТРЕБОВАТЕЛЕН НЕ ТОЛЬКО К КОЛЛЕГАМ, НО И К
          ЗРИТЕЛЮ» [Електронний ресурс] / Галина Глеба // Korydor. – 2018.{" "}
        </a>
      ),
    },
    {
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://supportyourart.com/conversations/svitlychnyj"
        >
          Калита Н. Іван Світличний: «Надеюсь, меня запомнят не за
          аудиоскульптуры» [Електронний ресурс] / Настя Калита // Your Art. –
          2019.{" "}
        </a>
      ),
    },
    {
      text: `Даніїл Ревковський і Андрій Рачинський — творчий дует із Харкова, нар. в 1993 р. і в 1990 р. (відповідно). Закінчили Харківську державну академію дизайну та мистецтв. У 2010 році створили відомий паблік Вконтакті «Пам'ять», з якого розпочалася їх спільна художня практика та дослідження теми колективної пам'яті на пострадянському просторі. Номінанти Премії PinchukArtCentre 2018, володарі Призу громадськості Премії PinchukArtCentre 2020.`,
      link: (
        <a target="_blank" tabindex="-1"  href="https://supportyourart.com/columns/rrmethod">
          Малих К. Клуб Поклонников Пиздеца [Електронний ресурс] / Ксенія Малих
          // Your Art. – 2020.
        </a>
      ),
    },
    {
      text: `Ніколай Карабінович (нар. 1988, Одеса) — український художник, куратор. Живе і працює в Генті (Бельгія) та Києві. Закінчив Одеський національний університет імені І.І. Мечникова, факультет філософії. Був учасником IV Одеської бієнале (2015). Двічі лауреат Першої Спеціальної премії PinchukArtCentre (2018, 2020). З 2019 навчається в Higher Institute for Fine Arts (HISK) в Генті. `,
      link: (
        <a target="_blank" tabindex="-1"  href="https://karabinovych.com">
          karabinovych.com
        </a>
      ),
    },
    {
      text: `Катерина Лісовенко (нар.1989, Київ) — українська художниця. Закінчила Національну Академію Образотворчого мистецтва і Архітектури, факультет монументального живопису. Випускниця курсу "Сучасне мистецтво" в Kyiv Academy of Media Arts. У своїй практиці звертається до теми насильства, домінування, зокрема ідеологічного.`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://artslooker.com/hudozhnitcya-katerina-lisovenko-pro-zhertvi-nasilstvo-ta-mistetcku-osvitu/"
        >
          Шиллер В. Художниця Катерина Лісовенко про жертви, насильство та
          мистецьку освіту [Електронний ресурс] / Валерія Шиллер // ArtsLooker.
          – 2020.{" "}
        </a>
      ),
    },
  ],
  en: [
    {
      text: `Mykhailo Boychuk (1882, Romanivka, Terebovlya County, Kingdom of Galicia and Volodymyria — 1937, Kyiv) is a Ukrainian artist and teacher, founder of the National School of Monumental Painting of Ukraine of the XX century. Representative of the Executed Renaissance. Leader of the Boychukist group. He studied in Lviv, Vienna, Munich, graduated from the Krakow Academy of Arts. In 1907-1910 he attended the Paul Ranson Academy in Paris. In 1909 he founded a workshop of neo-Byzantine art in Paris, which became the beginning of his school. He was one of the founding professors of the Ukrainian State Academy of Arts (1917). Boychuk was persecuted politically: in 1936 he was arrested and shot along with his students in Kyiv. Most of his works, ensembles of monumental painting in particular, were destroyed by the Soviet authorities.`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://en.wikipedia.org/wiki/Mykhailo_Boychuk"
        >
          Mykhailo Boychuk
        </a>
      ),
    },
    {
      text: `Alexandra Exter (1882, Białystok, Poland — 1949, Fontenay-au-Rose, France) is a Ukrainian artist, scenographer, designer, leader of the Ukrainian avant-garde, one of the founders of the Art Deco style. She studied at the Kiev School of Art, and later at the Grand Schomier Academy under Carlo Delval in Paris (1908). She took part in futurist exhibitions and was a member of the art groups "Youth Union", "Jack of Diamonds" (1910). She founded the Kyiv school of cubofuturistic and constructivist scenography. In 1915 she joined K. Malevich's group of avant-garde artists "Supremus". In 1924-1925 she took part in the design of the Soviet pavilion at the XIV Venice Biennale.`,
      link: (
        <a target="_blank" tabindex="-1"  href="http://avantgarde.org.ua/en.php">
          Welcome to Ukrainian Avant-garde!{" "}
        </a>
      ),
    },
    {
      text: `Serhiy Hryhoriev (1910, Luhansk — 1988, Kyiv) is a Ukrainian Soviet painter-socialist, poster artist, graphic artist and teacher. From 1926 he visited VKHUTEMAS in Moscow; in 1928–1932 he studied at the Kyiv Art Institute. Since 1934 he has worked as an Associate Professor of Drawing, Kyiv Art Institute. A participant in the German-Soviet war. Since 1950 he has been the head of the genre painting workshop of the Kyiv Art Institute. People's Artist of the USSR, winner of two Stalin Prizes of the second degree. In 1951–1955 he was the rector of the Kyiv Art Institute.`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="http://theschoolofkyiv.org/participants/991/serhiy-hryhoriev"
        >
          Serhiy Hryhoriev
        </a>
      ),
    },
    {
      text: `Alla Horska (1929, Yalta — 1970, Vasylkiv) — Ukrainian artist of the sixties, dissident, well-known activist of the human rights movement of the 1960s in Ukraine. Together with her husband Viktor Zaretsky and artists G. Sinitsa, G. Marchenko, B. Plaksiy, and G. Zubchenko, they created a number of monumental works in Donetsk, Kyiv, and Krasnodon. For participating in the protests in 1965-1968, Horska was expelled from the Union of Artists and persecuted by the Soviet security services. She died tragically on November 28, 1970 under unknown circumstances in the town of Vasylkiv.
			Balashova, Olha et al. The Art Of Ukrainian Sixties. Osnovy Publishing House, 2020.`,
      link: (
        <a target="_blank" tabindex="-1"  href="http://archive-uu.com/en/profiles/horska-alla">
          Alla Horska
        </a>
      ),
    },
    {
      text: `Fedir Tetyanych or Fripulya (1942, Knyazhychi village, Kyiv region — 2007, Kyiv) — Ukrainian artist, monumentalist, painter, graphic artist, sculptor, performer. He was one of the pioneers of performance and happenings in the Soviet Union, a prominent representative of the underground. He widely used as a material for industrial work those consumer wastes that were part of his philosophical and aesthetic system of improving space through the reuse of second-hand things.`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="http://archive-uu.com/en/profiles/tetyanych-fedir"
        >
          Fedir Tetyanych
        </a>
      ),
    },
    {
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://pinchukartcentre.org/en/exhibitions/doslidnitska-platforma_-kanon-fripulya"
        >
          Profile on Research Platform online tool of PinchukArtCentre
        </a>
      ),
    },
    {
      text: `Leonid Voitsekhov (1955, Odesa — 2018, Odesa) is a Ukrainian conceptual artist, graphic artist, and performer. Leader of the creative group "Apt-Art" (since 1982). He lived and worked in the squats "Furmanny Lane" and "Tryokhprudny Lane" in Moscow.
			Arsen Savadov (born 1962, Kyiv) is a Ukrainian artist. In 1988 he graduated from the Kyiv State Art Institute, Faculty of Monumental Painting. Participant in the "First Ukrainian Project" at the 49th Venice Biennale of Contemporary Art (2001). In 1982-1996 he worked in collaboration with Georgy Senchenko. At the exhibition "Youth of the Country" (Moscow, 1987) he showed the work "Sadness of Cleopatra" created in collaboration with Georgy Senchenko, which became a harbinger of the Ukrainian New Wave. He was a member of the Paris Commune.`,
      link: (
        <a target="_blank" tabindex="-1"  href="http://savadov.com">
          savadov.com{" "}
        </a>
      ),
    },
    {
      text: `Dmytro Kavsan (born 1964, Kyiv) is a Ukrainian artist. He graduated from the studio of monumental painting of the Kyiv State Art Institute in 1990. He was a member of the Paris Commune. 1992-1994 - was a fellow of IFA (Germany) and KulturKontakt (Austria), a participant in the International Festival of Contemporary Art in Avignon (1991).`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="http://pinchukartcentre.org/files/2020/parcommune_en.pdf"
        >
          The ParCommune. Place. Community. Phenomenon. Kyiv: Publish Pro, 2019.
          208 p
        </a>
      ),
    },
    {
      text: `Oleg Tistol (born 1960, Vradiyivka village, Mykolaiv region) is a Ukrainian artist. He graduated from the Lviv National Academy of Arts. Together with Kostyantyn (Winnie) Reunov proclaimed the program "Volitional facet of national post-eclecticism" (1987). Tistol lived and worked in the squats "Furmanny Lane" and "Tryokhprudny Lane" in Moscow (1989-1992). He collaborated with Mykola Matsenko in the Natsprom (1992-2005). Participant of the 22nd Biennale in Sao Paulo (1994). Participant in the "First Ukrainian Project" at the 49th Venice Biennale of Contemporary Art (2001).`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://abramovych.art/eng/artists/oleg_tistol"
        >
          Oleg Tistol
        </a>
      ),
    },
    {
      text: `Alevtina Kakhidze (born 1973, Zhdanivka, Donetsk Region) is a Ukrainian artist. In 2002 became a winner of the competition for young artists and curators at Centre for Contemporary Art at NaUKMA. In 2008 received the Kazimir Malevich Prize. Scholarship holder at the Jan van Eyck Academie (Maastricht, Netherlands), participated at Iaspis Open House (Stockholm, Sweden) artist residence and Centre for contemporary art "Ujazdowski Castle" (Warsaw, Poland). Co-founder of a private residence for foreign artists in the Muzychi village and the on-line art publication project kram.in.ua.`,
      link: (
        <a target="_blank" tabindex="-1"  href="http://alevtinakakhidze.com/">
          Alevtina Kakhidze
        </a>
      ),
    },
    {
      text: `Vlada Ralko (born 1969, Kyiv) is a Ukrainian artist. She graduated from the Ukrainian Academy of Arts and Architecture, department of Easel Painting. Member of the National Union of Artists of Ukraine (since 1994). Winner of the Ukrainian Triennial of Painting (2001) and the CCN Graz Scholarship (2007). Winner of the Women In Arts Award (2019).`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="http://pinchukartcentre.org/files/2020/womenartists_en.pdf"
        >
          Ralko, Vlada. «The Phantom of Liberty». Why There Are Great Women
          Artists in Ukrainian Art. Kyiv: Publish Pro, 2020. – P. 78–83.{" "}
        </a>
      ),
    },
    {
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://artslooker.com/en/vlada-ralko-it-is-important-for-me-to-turn-the-context-into-a-background/"
        >
          Vlada Ralko: ‘It Is Important for Me to Turn the Context into a
          Background’». ArtsLooker, 17, March 2017
        </a>
      ),
    },
    {
      text: `Lesia Khomenko (born 1980, Kyiv) is a Ukrainian artist. She graduated from the National Academy of Fine Arts and Architecture. Co-founder and member of the group R.E.P., curatorial association "Khudrada". Nominee of the PinchukArtCentre Prize (2009, 2011, 2013). Nominee of the Kazimir Malevich Prize (2012, 2016). Since 2015 Khomenko has been teaching a course of contemporary art at the Kyiv Academy of Media Arts.`,
      link: (
        <a target="_blank" tabindex="-1"  href="https://www.lesiakhomenko.com/">
          Lesia Khomenko
        </a>
      ),
    },
    {
      text: `Zhanna Kadyrova (born 1980, Brovary, Kyiv Region) is a Ukrainian artist and sculptress. Member of the group R.E.P. and the musical performance group Penoplast. Winner of the Kazimir Malevich Prize, the Sergey Kuryokhin Prize, the PinchukArtCentre Prize (2013). The artist's works were presented at the 55th, 56th and 58th Venice Biennale, at the Palais de Tokyo in Paris and the Moscow Biennale (2013), at the Pulse Art Fair in Miami (2019).`,
      link: (
        <a target="_blank" tabindex="-1"  href="https://www.kadyrova.com/">
          Zhanna Kadyrova
        </a>
      ),
    },
    {
      text: `Mykhailo Koptev (Luhansk) is a Ukrainian artist and thrash designer, founder and artistic director of the queer theater of erotic provocative fashion "Orchid", created in 1993 in Luhansk. The theater has toured in Spain and Austria. VICE, Dazed & Confused Magazine, Dry Magazine, The Guardian and The Calvert Journal wrote about Mykhailo Koptev. Because of the war he moved to the Kyiv region.`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://www.dazeddigital.com/fashion/article/29243/1/the-fashion-madman-you-ve-never-heard-of."
        >
          Zabolotnyi, Symon. «The Fashion Madman You’ve Never Heard of». Dazed,
          18, January 2016
        </a>
      ),
    },
    {
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="http://www.theguardian.com/world/2015/nov/30/luhansk-gay-provocateur-ukraine-mikhail-koptev."
        >
          «‘I Want to Live in Style’: The Gay Provocateur Defying Rebels in
          Eastern Ukraine». The Guardian, 30, November 2015
        </a>
      ),
    },
    {
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://www.dazeddigital.com/fashion/article/29243/1/the-fashion-madman-you-ve-never-heard-of"
        >
          more ...
        </a>
      ),
    },
    {
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="http://www.theguardian.com/world/2015/nov/30/luhansk-gay-provocateur-ukraine-mikhail-koptev/"
        >
          more ...
        </a>
      ),
    },
    {
      text: `R.E.P. Group (Revolutionary Experimental Space) is a Ukrainian art group founded in 2004 during the Orange Revolution in Ukraine. Since 2006, the group includes six artists: Lesia Khomenko, Nikita Kadan, Zhanna Kadyrova, Ksenia Hnylytska, Lada Nakonechna and Volodymyr Kuznetsov. In 2008, the group formed the "Khudrada" Association, a curatorial and activist group based on interdisciplinary cooperation.
			Artel, Rael, et al. R.E.P. Revolutionary experimental space = Р.Е.П. Революційний експериментальний простір. The Green Box, Kunst Editionen, 2015.`,
      link: (
        <a target="_blank" tabindex="-1"  href="http://www.volodymyrkuznetsov.com/">
          Volodymyr Kuznetsov
        </a>
      ),
    },
    {
      text: `Nikita Kadan (born 1982, Kyiv) is a Ukrainian artist and curator. He graduated from National Academy of Fine Art (Kyiv) where he studied on department of monumental painting under professor Mykola Storozhenko. from the National Academy of Arts and Architecture (2007). Member of the art group R.E.P. and the curatorial association "Hudrada". Winner of the First PinchukArtCentre Prize (2011), the Special Future Generation Art Prize (2014), Kazimir Malevich Prize (2016). `,
      link: (
        <a target="_blank" tabindex="-1"  href="http://nikitakadan.com/">
          Nikita Kadan
        </a>
      ),
    },
    {
      text: `Ksenia Hnylytska (born 1984, Kyiv) is a Ukrainian artist. She graduated of the National Academy of Fine Arts and Architecture, the faculty of Painting. Co-founder and member of the groups R.E.P. and "Hudrada". Nominee of the PinchukArtCentre Prize 2020.`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="http://pinchukartcentre.org/files/2020/womenartists_en.pdf"
        >
          Hodenko, Оlena. «Five Female Ukrainian Artists in the Contemporary Art
          of the 21st Century: Kseniia Hnylytska, Anna Zvyagintseva, Zhanna
          Kadyrova, Lada Nakonechna, Lesia Khomenko». Why There Are Great Women
          Artists in Ukrainian Art. Kyiv: Publish Pro, 2020. – P. 84–103.{" "}
        </a>
      ),
    },
    {
      text: `Daria Kuzmych (born 1991, Kyiv) is a Ukrainian artist. She graduated from the Faculty of Painting of the National Academy of Fine Arts and Architecture. Fellow of the master's program at the Berlin University of the Arts.`,
      link: (
        <a target="_blank" tabindex="-1"  href="https://dariiakuzmych.com/">
          Daria Kuzmych
        </a>
      ),
    },
    {
      text: `Ivan Svitlychny (born 1988, Kharkiv) is a Ukrainian artist, co-founder and curator of a virtual exposition environment — Shukhliada. He graduated from the Kharkiv State Academy of Design and Fine Arts, the faculty of easel painting and monumental sculpture. Founder and curator of the art organization 01011101, curator of the creative center of TETS (ТЕЦ) in Kharkiv. Participant of the Ukrainian pavilion of the Venice Biennale (2017), four times nominee of the PinchukArtCentre Prize, winner of the Malevich Prize (2018).`,
      link: (
        <a target="_blank" tabindex="-1"  href="">
          Ivan Svitlychnyi
        </a>
      ),
    },
    {
      text: `Daniil Revkovskiy and Andriy Rachinskiy are an artistic duo from Kharkiv, b. in 1993 and in 1990 (respectively). They graduated from the Kharkiv State Academy of Design and Fine Arts. In 2010, they created the well-known blog "Memory", which began their joint artistic practice and research on the topic of collective memory in the post-Soviet space. Nominees for the PinchukArtCentre Prize 2018, winners of the PinchukArtCentre Prize 2020 Public Award. `,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://www.e-flux.com/video/337224/andriy-rachinskiy-nbsp-and-nbsp-daniil-revkovskiy-nbsp-labor-safety-in-the-region-of-dnipropetrovsk/"
        >
          Daniil Revkovskiy & Andriy Rachinskiy - e-flux.com{" "}
        </a>
      ),
    },
    {
      text: `Nikolay Karabinovych (born 1988, Odesa) is a Ukrainian artist and curator. Lives and works in Ghent (Belgium) and Kyiv. He graduated from Odesa Mechnikov National University, Faculty of Philosophy. Karabinovych was a participant of the IV Odessa Biennale (2015). Twice winner of the First Special Award of PinchukArtCentre Prize (2018, 2020). Since 2019 he has been studying at the Higher Institute for Fine Arts (HISK) in Ghent.`,
      link: (
        <a target="_blank" tabindex="-1"  href="http://karabinovych.com/">
          Nikolay Karabinovych{" "}
        </a>
      ),
    },
    {
      text: `Kateryna Lisovenko (born 1989, Kyiv) is a Ukrainian artist. She graduated from the National Academy of Fine Arts and Architecture, Faculty of Monumental Painting; the course "Contemporary Art" at the Kyiv Academy of Media Arts. In her practice she addresses the topic of violence and ideological domination.`,
      link: (
        <a
          target="_blank" tabindex="-1" 
          href="https://artslooker.com/en/artist-kateryna-lisovenko-on-victims-violence-and-arts-education/"
        >
          «Artist Kateryna Lisovenko on Victims, Violence and Art Education».
          ArtsLooker, 07, August 2020
        </a>
      ),
    },
  ],
};

export default a;
