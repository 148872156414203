import React from 'react';

import l_bolon_rrk from './img/1_l_bolon_1.51_90.93.png';
import puff_rrk from './img/2_puff_1.25_87.31.png';
import kolosa_rrk from './img/3_kolosa_71.88_84.35.png';
import r_detal_rrk from './img/4_r_detal_83.49_94.54.png';
import r_telo_rrk from './img/5_r_telo_51.04_89.72.png';
import r_detal2_rrk from './img/6_r_detal2_43.13_95.19.png';
import l_ekran_rrk from './img/7_l_ekran_24.58_91.02.png';
import r_stul_rrk from './img/8_r_stul_62.29_85.28.png';
import r_tram_rrk from './img/9_r_tram_88.39_55.09.png';
import r_otboynik_rrk from './img/10_r_otboynik_66.56_75.65.png';
import r_stena_rrk from './img/11_r_stena_84.11_0.png';
import pol_rrk from './img/12_pol_0_89.54.png';
import l_stena_rrk from './img/13_l_stena_0_0.png';
import potolok_rrk from './img/14_potolok_8.33_0.png';

export default [
    <div className="animated-item l_bolon_rrk"><img src={l_bolon_rrk} alt="l_bolon_rrk"/></div>,
    <div className="animated-item puff_rrk"><img src={puff_rrk} alt="puff_rrk"/></div>,
    <div className="animated-item kolosa_rrk"><img src={kolosa_rrk} alt="kolosa_rrk"/></div>,
    <div className="animated-item r_detal_rrk"><img src={r_detal_rrk} alt="r_detal_rrk"/></div>,
    <div className="animated-item r_telo_rrk"><img src={r_telo_rrk} alt="r_telo_rrk"/></div>,
    <div className="animated-item r_detal2_rrk"><img src={r_detal2_rrk} alt="r_detal2_rrk"/></div>,
    <div className="animated-item l_ekran_rrk"><img src={l_ekran_rrk} alt="l_ekran_rrk"/></div>,
    <div className="animated-item r_stul_rrk"><img src={r_stul_rrk} alt="r_stul_rrk"/></div>,
    <div className="animated-item r_tram_rrk"><img src={r_tram_rrk} alt="r_tram_rrk"/></div>,
    <div className="animated-item r_otboynik_rrk"><img src={r_otboynik_rrk} alt="r_otboynik_rrk"/></div>,
    <div className="animated-item r_stena_rrk"><img src={r_stena_rrk} alt="r_stena_rrk"/></div>,
    <div className="animated-item pol_rrk"><img src={pol_rrk} alt="pol_rrk"/></div>,
    <div className="animated-item l_stena_rrk"><img src={l_stena_rrk} alt="l_stena_rrk"/></div>,
    <div className="animated-item potolok_rrk"><img src={potolok_rrk} alt="potolok_rrk"/></div>,
    <div className="animated-item bg_rrk"></div>, 
]