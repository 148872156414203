import React from "react";
import chooseLanguageImg from "./img/screens/choose-lang.png";

const Languages = ({ chooseLanguage }) => {
  return (
    <div className="languageContainer">
      <div className="languages">
        <div onClick={() => chooseLanguage("UA")}>UA</div>
        <div onClick={() => chooseLanguage("EN")}>EN</div>
      </div>
      <img className="chooseLanguage" src={chooseLanguageImg} />
    </div>
  );
};

export default Languages;
