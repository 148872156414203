import React from 'react';

import r_Layer1_fin from './img/1_r_Layer1_70.47_1.2.png';
import r_Layer2_fin from './img/2_r_Layer2_80.89_38.33.png';
import r_Layer3_fin from './img/3_r_Layer3_78.88_0.png';
import r_Layer4_fin from './img/4_r_Layer4_90.68_0.png';
import r_Layer5_fin from './img/5_r_Layer5_72.66_36.94.png';
import r_Layer6_fin from './img/6_r_Layer6_87.24_32.22.png';
import r_Layer7_fin from './img/7_r_Layer7_84.38_80.28.png';
import r_Layer8_fin from './img/8_r_Layer8_67.97_3.06.png';
import r_derevy_fin from './img/9_r_derevy_79.32_73.43.png';
import l_Layer1_fin from './img/10_l_Layer1_0_0.png';
import l_Layer2_fin from './img/11_l_Layer2_2.34_38.33.png';
import Layer3_fin from './img/12_l_Layer3_0_0.png';
import Layer4_fin from './img/13_l_Layer4_0_77.87.png';
import l_Layer5_fin from './img/14_l_Layer5_1.2_5.37.png';
import l_Layer6_fin from './img/15_l_Layer6_6.67_1.2.png';
import l_Layer7_fin from './img/16_l_Layer7_9.9_36.94.png';
import l_derevy_fin from './img/17_l_derevy_5.78_73.43.png';
import zemly_fin from './img/18_zemly_0_85.37.png';


export default [
    <div className="animated-item r_Layer1_fin"><img src={r_Layer1_fin} alt="r_Layer1_fin"/></div>,
    <div className="animated-item r_Layer2_fin"><img src={r_Layer2_fin} alt="r_Layer2_fin"/></div>,
    <div className="animated-item r_Layer3_fin"><img src={r_Layer3_fin} alt="r_Layer3_fin"/></div>,
    <div className="animated-item r_Layer4_fin"><img src={r_Layer4_fin} alt="r_Layer4_fin"/></div>,
    <div className="animated-item r_Layer5_fin"><img src={r_Layer5_fin} alt="r_Layer5_fin"/></div>,
    <div className="animated-item r_Layer6_fin"><img src={r_Layer6_fin} alt="r_Layer6_fin"/></div>,
    <div className="animated-item r_Layer7_fin"><img src={r_Layer7_fin} alt="r_Layer7_fin"/></div>,
    <div className="animated-item r_Layer8_fin"><img src={r_Layer8_fin} alt="r_Layer8_fin"/></div>,
    <div className="animated-item r_derevy_fin"><img src={r_derevy_fin} alt="r_derevy_fin"/></div>,
    <div className="animated-item l_Layer1_fin"><img src={l_Layer1_fin} alt="l_Layer1_fin"/></div>,
    <div className="animated-item l_Layer2_fin"><img src={l_Layer2_fin} alt="l_Layer2_fin"/></div>,
    <div className="animated-item Layer3_fin"><img src={Layer3_fin} alt="Layer3_fin"/></div>,
    <div className="animated-item Layer4_fin"><img src={Layer4_fin} alt="Layer4_fin"/></div>,
    <div className="animated-item l_Layer5_fin"><img src={l_Layer5_fin} alt="l_Layer5_fin"/></div>,
    <div className="animated-item l_Layer6_fin"><img src={l_Layer6_fin} alt="l_Layer6_fin"/></div>,
    <div className="animated-item l_Layer7_fin"><img src={l_Layer7_fin} alt="l_Layer7_fin"/></div>,
    <div className="animated-item l_derevy_fin"><img src={l_derevy_fin} alt="l_derevy_fin"/></div>,
    <div className="animated-item zemly_fin"><img src={zemly_fin} alt="zemly_fin"/></div>,
    <div className="animated-item bg_fin"></div>, 
]